<div class="p-9">
  <p class="text-4xl">Terms of Buying </p>
  <p>Cars2click</p>
  <br><br>
  <h1 class="text-2xl">1. General Part</h1>
  <br><br>
  <p>
    Read these Terms and Conditions carefully as they contain important information about our relationship with
    you.<br><br>
    This Website (https://shop.cars2click.com) is owned and controlled by Cars2click AB, with registered office
    at<br><br>
    Barnhusgatan 8, 111 23, Stockholm, Sweden, duly registered at Swedish Companies Registration Office under the number
    559192-9053, with a share capital of 50.000 SEK, hereinafter referred to as “Cars2click”.<br><br>
    Through the Website, any professional (hereinafter referred to as “User”) may buy cars with Cars2click (together
    referred to as “Parties”).<br><br>
    The Parties agree that purchases made through this Website shall be governed exclusively by these Terms of Use, as
    well as the Terms and Conditions.<br><br>
    By using our Website, the User expressly accepts these Terms of Use and must therefore read them carefully. If the
    User does not accept the Terms of Use, the User should leave the Website.
  </p>
  <br><br>
  <h1 class="text-2xl"> 2. Terms of use</h1>
  <br><br>
  <p>These Terms apply to your online order. These Terms and Conditions ("Terms") will apply when you ("you" or
    "Customer")
    make an online order and reservation (together an "Order") for a vehicle advertised for sale ("Vehicle", “Car”) on
    Cars2click Online Shop at www.shop.cars2click.com or any other Cars2click platform (including mobile and tablet)
    ("Platform").<br><br>
    Please read these Terms carefully before you make an Order on Platform. These Terms form the contract between you
    and
    Cars2click in respect of your Order and your use of the Services and will be binding on all users of the Services.
    We
    suggest that you also keep a copy of these Terms for future reference.</p>
  <br><br>
  <h1 class="text-2xl"> 2.1. Use of Website</h1>
  <br><br>
  <p>The information provided on this website, or which can be obtained from it, although accurate, does not dispense
    with
    its confirmation, nor can it be considered binding, which is why it cannot be used as a substitute for any form of
    advice. Decisions taken based on this information are your sole responsibility.<br><br>
    Although Cars2click works to provide accurate, complete and up to date information obtained from sources considered
    reliable, Cars2click does not guarantee, either expressly or implicitly, that the information provided on this
    website
    or that may be obtained through it is accurate and complete and/or up to date.<br><br>
    Cars2click controls and maintains this website for several European countries , and therefore does not guarantee
    that
    the information contained in it or that may be obtained from it is suitable for use in other locations or is
    available
    there. If you access this website, you are responsible for complying with the applicable legislation in your
    company’s
    location.<br><br>
    Cars2click does not warrant that this website will operate error-free and/or without interruption.<br><br>
    <span class="font-bold">These Terms may change from time to time</span> so make sure you check these Terms each time
    you use the Services. We
    reserve
    all rights to change any or all these Terms at any time and we will post the updated Terms of Use on the Website.
    All
    changes are effective immediately and your continued use of the Website, Services or Products after the posting of
    any
    amended Terms shall constitute your agreement to be bound by any such changes
  </p>
  <br><br>
  <h1 class="text-2xl"> 2.2. Reservation and Orders</h1>
  <br><br>
  <p>
    In these Terms, the following terms will have the following meanings unless the context otherwise requires:
  </p>
  <br><br>

  <table class="text-xs">
    <tr>
      <th>Term</th>
      <th>Meaning</th>
    </tr>
    <tr>
      <th>Reservation</th>
      <th class="font-normal">Reservation means the confirmed reservation of the Vehicle, once you have paid your
        Reservation Payment,
        in accordance with these Terms.</th>
    </tr>
    <tr>
      <th>Reservation Payment</th>
      <th class="font-normal">Reservation Payment means the amount to be paid by you for the Reservation.</th>
    </tr>
    <tr>
      <th>Vehicle</th>
      <th class="font-normal"> Vehicle means the vehicle, which you are interested in purchasing from the Dealer in
        connection with a
        Reservation.</th>
    </tr>
    <tr>
      <th>Order</th>
      <th class="font-normal">A purchase Order is a commercial document issued by a buyer to a seller, indicating types,
        quantities, and
        agreed prices for products or services required. It is used to control the purchasing of products and services
        from
        external suppliers. An Order is a legally binding document that confirms that payment will be made for goods at
        a
        later date. It should contain the amount of money due to be paid to the seller, the method of payment, and a
        concrete date for the agreed-upon payment.</th>
    </tr>
    <tr>
      <th>CMR</th>
      <th class="font-normal">CMR is a document that contains the rights and obligations of parties involved in European
        road transport.
        These
        are the shipper, the carrier and the consignee. The CMR document is referred to in the event of any discrepancy
        at
        the time of collection or delivery.</th>
    </tr>
  </table>

  <br><br>
  <h1 class="text-2xl">2.2.1. Order submission</h1>
  <br><br>
  Once you have submitted your Order, you will receive an Order confirmation email from us confirming the reservation of
  the Vehicle(s). The Vehicle is not reserved until you have paid the Reservation Payment and completed your Order and
  have received the Order confirmation email . Once you have completed and submitted your Order, you are not able to go
  back and amend your Order via your account on the Platform . If you wish to make changes to your Order once it’s been
  submitted, you should contact the sales@cars2click.com.
  <br><br>
  Cars2click partners with Scrive AB (“Scrive”) to send and certify the Order Confirmations. You will receive an email
  from Scrive requesting you to confirm you placed an Order and you agree with the Terms of Buying.
  <br><br>
  After the confirmation via Scrive, the invoices will be issued and sent via email to the email address chosen by the
  User.
  <br><br>
  The Reservation Payment is a sum to be paid for ordering the Vehicle(s) (for a maximum of 21 days) only. It is not a
  Part payment or deposit towards the price of the Vehicle, and it does not guarantee that the Vehicle will be sold to
  you or that the Vehicle will be available for you to purchase.
  <br><br>
  <h1 class="text-2xl">2.2.2. Pre-Sales</h1>
  <br><br>
  All Vehicles sold as pre-sales have a mandatory reservation fee of 10% Ex-VAT of the amount of each car, due
  immediately.
  <br><br>
  When the Car is available the customer is notified, and the outstanding balance should be paid in 3 working days. If
  the invoice is not paid in time Cars2click reserves the right to cancel the deal and charge the cancelation fee.
  <br><br>
  <h1 class="text-2xl">2.2.3. Estimated Damages</h1>
  <br><br>
  The damages presented in Cars2click Order Confirmation are estimations provided by a third party and may vary
  depending on the source of estimation. These estimations are for informational purposes only and do not represent
  discounts. Any applicable discounts have been previously discussed with the Key Account Manager assigned to your
  account. Please note that the actual number of damages may differ from the estimated amount provided.
  <br><br>
  <h1 class="text-2xl">2.3. Transport</h1>
  <br><br>
  Cars2click offers the transport service to all the Vehicles presented on Cars2click Online Shop. The transportation
  service is provided by Cars2transport Europe AB (“Cars2transport”) and is subject to Cars2transport’s Terms and
  Conditions.
  <br><br>
  Once an Order is place, Cars2transport starts planning the transportation of the Vehicle(s). If the outstanding
  payment is not received within the next 2 days, the transport booking as cancelled due to non-payment. In such a case,
  we will be compelled to charge you for the transport cancellation, as outlined in our Terms and Conditions.
  <br><br>
  If the payment is not made promptly, you will not be eligible to claim any compensation for the transport cancellation
  or delay.
  <br><br>
  <h1 class="text-2xl">2.4. Documents</h1>
  <br><br>
  The documents of the Vehicle(s) bought on shop.cars2click.com are made available online and shipped after the
  reception of the original CMR. The delivery time depends on the country of origin and delivery of the Vehicle(s). It
  may not be sent at the same time as the cars.
  <br><br>
  Cars2click relies on DHL International GmbH and Deutsche Post AG (“DHL Express”) to ship the documents of the
  Vehicle(s) to you. This service is subject to DHL Express’ Terms and Conditions and the delivery is managed by it.
  <br><br>
  If there is a need to change the delivery address, the User must contact DHL Express directly, presenting the DHL
  tracking number provided by Cars2click and available in the Order details at shop.cars2click.com.
  <br><br>
  <h1 class="text-2xl">2.5. Claims Policy</h1>
  <br><br>
  Claims for lost documents longer than 2 months after delivery will not be accepted.
  <br><br>
  Damages must be noted on CMR at delivery to be accepted.
  <br><br>
  Photo documentation of damages, not presented in the offer, including cost estimation per damage must be emailed to
  sales@cars2click.com latest 24h after delivery to be accepted."
  <br><br>
  <h1 class="text-2xl">2.5.1. Vehicle “Showroom ready”</h1>
  <br><br>
  Show room ready cars are used cars prepared for sale, in the normal condition for age and mileage so small damages are
  expected.
  <br><br>
  <h1 class="text-2xl">2.5.2. Vehicles “not-Showroom ready”</h1>
  <br><br>
  Cars not-show room ready are used cars with damages reports and the customer confirm with the signature of this order
  to have received a report.
  <br><br>
  <h1 class="text-2xl">2.6. Cancellation and Refunds</h1>
  <br><br>
  Orders cancelled after approval will be subject of a cancellation fee of 10% Ex-VAT of the amount of each vehicle.
  <br><br>
  All Buyers have the right to cancel an Order if the delivery is delayed more than 3 months (used cars) and 6 months
  (new cars) beyond the estimated delivery date.
  <br><br>
  In case of cancellation or delay by Cars2click the buyer cannot claim damages or ask Cars2click to cover any costs or
  lost profits.
  <br><br>
  Refunds will be processed by Cars2click Operational Services only and will be paid to the same bank account that the
  Reservation Payment was paid from and may take up to 3 to 5 days to be processed. The exact timing of your refund
  payment will depend on your bank’s lead times.
  <br><br>
  <h1 class="text-2xl">3. Data Protection and Confidentiality</h1>
  <br><br>
  Cars2click and the Client will process the Personal Data of the parties involved in the contractual relationship as
  Data Controllers, for the purpose of managing the rights and obligations arising from the commercial relationship. In
  this case, each Party acts as Data Controller based on its legal obligations and legitimate interests, to the extent
  strictly necessary for the contacts and communications for the fulfilment of its obligations, in particular to send
  Order Confirmations and related communications.
  <br><br>
  Cars2click and the Client ensure the compliance with the applicable Data Protection Legislation, namely with the
  Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016.
  For more information on how Cars2click processes personal data, please consult its Privacy Policy available on its
  website.

</div>
