import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import moment from "moment";
import { BehaviorSubject, interval } from "rxjs";
import { environment } from "src/environments/environment";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private jwtHelperService = new JwtHelperService();

  isLoggedInRedirect = new BehaviorSubject<boolean>(false);
  isLoggedInRedirect$ = this.isLoggedInRedirect.asObservable();

  token: string | undefined;

  constructor(private router: Router,
    private msalService: MsalService) {

    const timer = interval(1000).subscribe(() => {
      let token = localStorage.getItem('msalToken');

      if (token) {
        let expT = moment(this.jwtHelperService.getTokenExpirationDate(token));
        let date = moment();

        if (moment.duration(expT.diff(date)).asMinutes() < 5) {
          this.msalService.instance.acquireTokenSilent({ scopes: [environment.MSAL_SCOPES] }).then((resp) => {
            localStorage.setItem("msalToken", resp.idToken);
          });
        }
      }
    });
  }

  public logout(redirect?: string) {
    localStorage.removeItem('msalToken');

    this.msalService.logoutPopup();

    this.token = undefined;

    if (redirect) localStorage.setItem('redirectURL', redirect);

    this.router.navigate(['']);
  }
}
