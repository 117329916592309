<div class="bg-blue-500">
  <div class="container">
    <div class="grid grid-cols-1 lg:grid-cols-3">
      <!-- logo and social -->
      <div class="lg:mt-20">
        <!-- logo -->
        <div class="py-12 lg:pb-12 lg:pt-0">
          <img src="./assets/logos/c2c-short-blue-logo.svg" alt="">
        </div>
        <!-- social -->
        <div class="flex">
          <img src="./assets/logos/facebook.svg" alt="">
          <img class="mx-5" src="./assets/logos/twitter.svg" alt="">
          <img src="./assets/logos/instagram.svg" alt="">
          <img class="ml-5" src="./assets/logos/internet.svg" alt="">
        </div>
      </div>

      <div class="mt-9 lg:mt-20">
        <div class="font-medium lg:text-lg">
          <span>Cars2view a solution from Cars2click.</span>
        </div>

        <div class="font-light mt-4 mb-6 lg:text-lg">
          <span>Cars2click AB
            Barnhusgatan 4 <br>111 23 Stockholm, Sweden</span>
        </div>

        <div class="font-light text-blue-200 lg:text-lg">
          <span> info@cars2click.com
          </span>
        </div>
      </div>

      <div class="mt-16 lg:mt-20">
        <div class="font-medium lg:text-lg">
          <span>Cars2click international contact</span>
        </div>

        <div class="font-light mt-4 mb-6 lg:text-lg">
          <span>Brian Madsen<br>
            +46 705595444
            </span>
        </div>

        <div class="font-light text-blue-200 lg:text-lg">
          <span> info@cars2click.com
          </span>
        </div>
      </div>

    </div>

    <div class="text-xs mt-12 pb-12 lg:mt-7">
      <span>© 2022 CARS2CLICK. All rights reserved</span>
    </div>
  </div>
</div>
