<div class="max-h-[80vh] p-6 overflow-y-scroll flex flex-col gap-9">
  <div class="flex justify-between items-center">
    <p class="text-2xl">Save search</p>
    <p id="close-modal-btn" data-cy="close-modal-btn" class="text-sm cursor-pointer" (click)="cancel()">Close</p>
  </div>

  <div class="flex justify-between justify-items-center">
    <app-input id="search-name" placeholder="Insert name" label="Name" [control]="nameControl"></app-input>
    <app-blue-btn id="save-search" (clickEmitter)="saveSearch()">SAVE</app-blue-btn>
  </div>

  <div>
    <p class="mb-6">Filters:</p>

    <div *ngFor="let group of groupedFilters | keyvalue">
      <p>{{group.key}}: {{getValues(group.value)}}</p>
    </div>
  </div>
</div>
