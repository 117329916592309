import { Component, Input, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-dropdown-panel',
  templateUrl: './app-dropdown-panel.component.html',
  styleUrls: ['./app-dropdown-panel.component.scss']
})
export class AppDropdownPanelComponent implements OnInit {
  @Input() expandedPanel: boolean = false;
  @Input() panelId: string = '';

  public panel: HTMLElement | undefined;

  @Input() checkResize = false;
  subscription: Subscription | undefined;

  constructor() { }

  ngOnInit() {
    if (this.checkResize) {
      const source = interval(500);
      this.subscription = source.subscribe(() => this.resizePanel());
    }
  }

  ngAfterViewInit() {
    this.panel = document.getElementById(this.panelId)!;

    if (this.expandedPanel) this.panel!.style.maxHeight = this.expandedPanel ? `${this.panel!.scrollHeight}px` : '0';
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }

  togglePanel() {
    this.panel = document.getElementById(this.panelId)!;

    this.expandedPanel = !this.expandedPanel;

    this.panel!.style.maxHeight = this.expandedPanel ? `${this.panel!.scrollHeight}px` : '0';
  }

  resizePanel() {
    if (this.expandedPanel) {
      this.panel!.style.maxHeight = `${this.panel!.scrollHeight}px`;
    } else {
      this.panel!.style.maxHeight = `0`;
    }
  }
}
