<div class="max-h-[80vh] p-6 overflow-y-scroll flex flex-col gap-9" *ngIf="car && !(loading | async);else placeholder">
  <div>
    <p class="font-bold text-2xl">{{car.make}} {{car.model}}</p>
  </div>

  <div>
    <app-image-slider [images]="images"></app-image-slider>
  </div>

  <div>
    <p class="font-bold mb-6">Pricing</p>

    <p>Invoiced price <span class="text-blue-ctaBlue">€{{car.sellingPrice | number}}</span>
      <span class="text-white-greyText"> {{car.vatStatus ? vatStatus.ExVAT : vatStatus.IncludedVAT}}</span>
    </p>
  </div>

  <div>
    <p class="font-bold mb-6">Documents</p>

    <div class="flex flex-col gap-1">
      <div class="flex gap-5 text-sm p-3 bg-white-bgGrey" *ngFor="let doc of car.documents">
        <p>{{doc.name}}</p>

        <a (click)="downloadFile(doc.id)" class="underline text-blue-700 cursor-pointer">see document</a>
      </div>
    </div>
  </div>

  <div>
    <p class="font-bold mb-6">Technical data</p>

    <div class="grid grid-cols-1 sm:grid-cols-2 gap-9 text-sm mb-6">
      <div class="flex flex-col gap-4 sm:pr-9 sm:border-r border-white-lines">
        <div class="flex justify-between">
          <p class="text-white-greyText">Make</p>
          <p>{{car.make}}</p>
        </div>
        <div class="flex justify-between">
          <p class="text-white-greyText">Model</p>
          <p>{{car.model}}</p>
        </div>
        <div class="flex justify-between">
          <p class="text-white-greyText">Variant</p>
          <p>{{car.variant}}</p>
        </div>
        <div class="flex justify-between" *ngIf="car.trim">
          <p class="text-white-greyText">Trim</p>
          <p>{{car.trim}}</p>
        </div>
        <div class="flex justify-between">
          <p class="text-white-greyText">Model year</p>
          <p>{{car.manufactureYear}}</p>
        </div>
        <div class="flex justify-between">
          <p class="text-white-greyText">Location</p>
          <p>{{car.locationName}}</p>
        </div>
        <div class="flex justify-between">
          <p class="text-white-greyText">Damages</p>
          <p>€{{car.damages | number}}</p>
        </div>
        <div class="flex justify-between">
          <p class="text-white-greyText">VIN</p>
          <p>{{car.vin}}</p>
        </div>
      </div>

      <div class="flex flex-col gap-4">
        <div class="flex justify-between">
          <p class="text-white-greyText">Engine power</p>
          <p>{{car.enginePower | number}}</p>
        </div>
        <div class="flex justify-between" *ngIf="car.engineVolume">
          <p class="text-white-greyText">Engine volume</p>
          <p>{{car.engineVolume | number}}</p>
        </div>
        <div class="flex justify-between">
          <p class="text-white-greyText">Fuel type</p>
          <p>{{car.fuelType}}</p>
        </div>
        <div class="flex justify-between">
          <p class="text-white-greyText">Gearbox</p>
          <p>{{car.gearbox}}</p>
        </div>
        <div class="flex justify-between">
          <p class="text-white-greyText">Mileage</p>
          <p>{{car.mileage | number}}</p>
        </div>
        <div class="flex justify-between">
          <p class="text-white-greyText">First registration</p>
          <p>{{car.firstReg}}</p>
        </div>
        <div class="flex justify-between">
          <p class="text-white-greyText">Registration Number</p>
          <p>{{car.regNo}}</p>
        </div>
      </div>
    </div>

    <div class="flex flex-wrap gap-3 text-sm">
      <div class="flex items-center gap-3 bg-white-bgGrey p-3">
        <p class="text-white-greyText">Color</p>
        <p>{{car.color}}</p>
      </div>

      <div class="flex items-center gap-3 bg-white-bgGrey p-3">
        <p class="text-white-greyText">Body type</p>
        <p>{{car.bodyType}}</p>
      </div>

      <div class="flex items-center gap-3 bg-white-bgGrey p-3">
        <p class="text-white-greyText">Driving wheels</p>
        <p>{{car.drivingWheels}}</p>
      </div>

      <div class="flex items-center gap-3 bg-white-bgGrey p-3">
        <p class="text-white-greyText">CO2 NEDC</p>
        <p>{{car.co2NEDC}} g/km</p>
      </div>

      <div class="flex items-center gap-3 bg-white-bgGrey p-3">
        <p class="text-white-greyText">CO2 WLTP</p>
        <p>{{car.co2WLTP}} g/km</p>
      </div>
    </div>
  </div>

  <div>
    <p class="mb-6 font-bold">Equipment</p>

    <div class="grid grid-cols-1 sm:grid-cols-2 gap-y-3">
      <div class="col-span-1 flex gap-3 cursor-default" *ngFor="let eq of equipments">
        <fa-icon class="text-blue-ctaBlue" [icon]="['fas','circle-check']"></fa-icon>
        <p>{{eq}}</p>
      </div>
    </div>
  </div>
</div>

<ng-template #placeholder>
  <app-main-content-layout>
    <div class="flex flex-col gap-6">
      <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(4)"></div>
    </div>
  </app-main-content-layout>

  <app-main-content-layout>
    <div class="flex flex-col gap-6">
      <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(4)"></div>
    </div>
  </app-main-content-layout>
</ng-template>
