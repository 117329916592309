<div class="bg-black text-white max-h-[80vh] p-6 overflow-y-scroll">
  <div class="flex justify-between items-center mb-6">
    <p class="text-2xl">All filters</p>
    <p id="close-modal-btn" data-cy="close-modal-btn" class="text-sm cursor-pointer" (click)="cancel()">Close</p>
  </div>

  <div *ngFor="let group of groupedFilters | keyvalue">
    <div>
      <p>{{group.key}}</p>
    </div>

    <div class="flex flex-wrap gap-3 my-6 text-sm">
      <div class="p-3 flex gap-3 items-center bg-black-darkChip rounded" *ngFor="let filter of group.value">
        <p>{{filter.value}}</p>
        <fa-icon [id]="'remove'+ filter.value +'-modal-btn'" [attr.data-cy]="'remove'+ filter.value +'-modal-btn'"
          [icon]="['far','xmark']" class="cursor-pointer"
          (click)="removeFilter(filter.value, filter.groupValue, group.key)"></fa-icon>
      </div>
    </div>
  </div>


  <div class="flex flex-col sm:flex-row gap-6">
    <app-blue-btn id="search-modal" (clickEmitter)="cancel()">SEARCH</app-blue-btn>
    <app-black-btn id="clear-filters-modal" (clickEmitter)="clearAllFilters()">Clear filters</app-black-btn>
  </div>
</div>
