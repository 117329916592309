import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { AfterSearchComponent } from './after-search.component';
import { SaveSearchModalComponent } from './save-search-modal/save-search-modal.component';
import {  InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    InfiniteScrollModule
  ],
  declarations: [
    AfterSearchComponent,
    SaveSearchModalComponent
  ]
})

export class AfterSearchModule { }
