<app-my-profile-page-layout [profilePage]="'favorites'">
  <!-- <div class="flex flex-wrap gap-6">
    <app-dropdown [variant]="2" class="w-full sm:w-auto sm:min-w-[12rem]" placeholder="Select offer type"
      label="Offer type"></app-dropdown>
    <app-dropdown [variant]="2" class="w-full sm:w-auto sm:min-w-[12rem]" placeholder="Select from"
      label="From"></app-dropdown>
    <app-dropdown [variant]="2" class="w-full sm:w-auto sm:min-w-[12rem]" placeholder="Select to"
      label="To"></app-dropdown>
  </div> -->

  <div class="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6"
    *ngIf="!(loading | async);else placeholder">
    <div *ngFor="let car of cars">
      <div class="flex w-full h-60 xl:h-[calc(100vw*0.1)] mr-9 relative">
        <img class="w-full object-cover rounded cursor-pointer" (click)="goToCar(car.carMainInfoId!)"
          [src]="(car.profilePhoto && car.profilePhoto.length > 0 )? car.profilePhoto : carImg" alt="">
        <fa-icon
          class="absolute fa-icon-div top-1 right-1 text-blue-headerBg transition duration-150 hover:scale-105 ease-in-out"
          [icon]="['fas','trash-can']" (click)="removeFromFav(car.carMainInfoId!)"></fa-icon>
      </div>

      <div class="p-6 bg-white flex flex-col gap-6">
        <div>
          <p class="font-bold mb-3 cursor-pointer breakWords" (click)="goToCar(car.carMainInfoId!)">{{car.make}}
            {{car.model}},
            {{car.variant}}</p>

          <p *ngIf="car.vin" class="text-xs text-white-700 mb-3">VIN: {{car.vin}}</p>

          <div class="flex flex-wrap gap-3 text-blue-darkMauve">
            <div class="flex gap-3 text-xs py-1 px-2 bg-white-bgGrey">
              <p>{{car.mileage | number}} KM</p>
            </div>

            <div class="flex gap-3 text-xs py-1 px-2 bg-white-bgGrey">
              <p>{{car.manufactureYear}}</p>
            </div>

            <div class="flex gap-3 text-xs py-1 px-2 bg-white-bgGrey">
              <p>{{car.fuelType}}</p>
            </div>

            <div class="flex gap-3 text-xs py-1 px-2 bg-white-bgGrey">
              <p>{{car.gearbox}}</p>
            </div>
          </div>
        </div>

        <div class="flex items-center gap-3">
          <p class="font-bold text-blue-ctaBlue">€{{car.carPricing.shopPrice| number}}</p>
          <p class="text-sm text-white-greyText">{{car.vatStatus ? vatStatus.ExVAT : vatStatus.IncludedVAT}}</p>
        </div>

        <!-- <p class="font-bold">Business Case</p>

        <div class="text-sm flex flex-col gap-3">
          <div class="flex justify-between">
            <p class="text-white-greyText">Similar cars sold </p>
            <p>100 </p>
          </div>
          <div class="flex justify-between">
            <p class="text-white-greyText">Similar cars sold </p>
            <p>100 </p>
          </div>
          <div class="flex justify-between">
            <p class="text-white-greyText">Similar cars for sale </p>
            <p>100 </p>
          </div>
          <div class="flex justify-between">
            <p class="text-white-greyText">Sales factor </p>
            <p>30 </p>
          </div>
          <div class="flex justify-between">
            <p class="text-white-greyText">Retail ex VAT in Germany </p>
            <p>€24.000</p>
          </div>
          <div class="flex justify-between">
            <p class="text-white-greyText">B2B Delivered </p>
            <p>€24.000</p>
          </div>
          <div class="text-green flex justify-between">
            <p>Profit </p>
            <p>€1.000</p>
          </div>
        </div> -->

        <app-blue-btn (clickEmitter)="addToCart(car)">Add to cart</app-blue-btn>
      </div>
    </div>

  </div>
</app-my-profile-page-layout>

<ng-template #placeholder>
  <div class="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
    <div class="col-span-1 rounded" *ngFor="let i of [].constructor(5)">
      <div class="flex justify-center">
        <img class="rounded object-contain opacity-30" src="assets/photos/car-placeholder.png" alt="">
      </div>

      <div class="flex flex-col gap-6 p-6">
        <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(4)"></div>
      </div>
    </div>
  </div>
</ng-template>
