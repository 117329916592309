import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, firstValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
import { CounterOfferBody, CreateOfferBody, Offer } from "../models/offer.model";
import { OfferClientStatuses } from "src/app/features/my-offers/my-offers.component";

@Injectable({
  providedIn: 'root'
})

export class OffersService {
  loadingOffers = new BehaviorSubject<boolean>(true);
  offers: Offer[] = [];

  private offerSubject = new Subject<Offer[]>();
  offerSubject$ = this.offerSubject.asObservable();

  constructor(private http: HttpClient) {
  }

  public createOffer(createOfferBody: CreateOfferBody): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}create-offer`, createOfferBody);
  }

  public acceptOffer(offerId: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}accept-offer`, { offerId: offerId });
  }

  public declineOffer(offerId: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}decline-offer`, { offerId: offerId });
  }

  public makeCounterOffer(body: CounterOfferBody): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}counter-offer`, body);
  }

  public getOffers(): Observable<Offer[]> {
    return this.http.get<Offer[]>(`${environment.backendApiUrl}get-offers`);
  }

  public getReadyToOrderOffers(pageNo: number, itemsPerPage: number): Observable<{ nrOfItems: number, offers: Offer[] }> {
    return this.http.post<{ nrOfItems: number, offers: Offer[] }>(`${environment.backendApiUrl}client-ready-to-order-offers`, { page: pageNo, itemsPerPage: itemsPerPage });
  }

  public getPendingOffers(pageNo: number, itemsPerPage: number): Observable<{ nrOfItems: number, offers: Offer[] }> {
    return this.http.post<{ nrOfItems: number, offers: Offer[] }>(`${environment.backendApiUrl}client-my-pending-offers`, { page: pageNo, itemsPerPage: itemsPerPage });
  }

  public getC2CProposedOffers(pageNo: number, itemsPerPage: number): Observable<{ nrOfItems: number, offers: Offer[] }> {
    return this.http.post<{ nrOfItems: number, offers: Offer[] }>(`${environment.backendApiUrl}client-c2c-proposed-offers`, { page: pageNo, itemsPerPage: itemsPerPage });
  }

  public getRejectedOffers(pageNo: number, itemsPerPage: number): Observable<{ nrOfItems: number, offers: Offer[] }> {
    return this.http.post<{ nrOfItems: number, offers: Offer[] }>(`${environment.backendApiUrl}client-rejected-or-expired-offers`, { page: pageNo, itemsPerPage: itemsPerPage });
  }

  public getFinishedOffers(pageNo: number, itemsPerPage: number): Observable<{ nrOfItems: number, offers: Offer[] }> {
    return this.http.post<{ nrOfItems: number, offers: Offer[] }>(`${environment.backendApiUrl}client-finished-offers`, { page: pageNo, itemsPerPage: itemsPerPage });
  }

  public getExportOffersCarsInfo(): Observable<Offer[]> {
    return this.http.get<Offer[]>(`${environment.backendApiUrl}client-ready-to-order-offers-export`);
  }

  public loadOffers() {
    this.loadingOffers.next(true);

    this.getOffers().subscribe(resp => {
      this.loadingOffers.next(false);

      this.offerSubject.next(resp.map(o => ({
        ...o,
        latestOffer: o.proposedPrices[o.proposedPrices.length - 1].value,
        offerStatus: this.getOfferStatus(o)
      })));
    });
  }

  private getOfferStatus(o: Offer) {
    if (o.offerStatus === OfferClientStatuses.Pending && o.offerType === 'C2C' && o.proposedPrices.length === 1) {
      return OfferClientStatuses.C2CNewOffer;
    }
    if (o.offerStatus === OfferClientStatuses.Pending && o.proposedPrices[o.proposedPrices.length - 1].offeredBy === 'C2C') {
      return OfferClientStatuses.CounterOffer;
    } else {
      return o.offerStatus;
    }
  }
}
