<div class="rounded overflow-hidden bg-white-bgGrey">
  <div class="h-52 w-full flex relative">
    <img src="{{imgUrl}}" alt="" class="w-full object-cover cursor-pointer" (click)="goToCar()">

    <div class="absolute top-4 right-4">
      <fa-icon class="px-3 py-2 bg-white-bgGrey text-white-disabled rounded h-fit cursor-pointer" (click)="addToFav()"
        [icon]="['fas','heart']"></fa-icon>
    </div>

  </div>
  <div class="p-3 lg:p-6 flex flex-col gap-3">
    <p class="font-bold cursor-pointer" [id]="'go-to-car-'+car!.carMainInfoId +'-suggestion-card-btn'" data-cy="fav-btn"
      (click)="goToCar()">{{car?.make}} {{car?.model}},
      {{car?.variant}}</p>
    <p class="text-blue-ctaBlue font-bold">€{{car?.sellingPrice | number}} <span></span></p>

    <div class="flex flex-wrap gap-2 text-xs text-blue-darkMauve">
      <p class="py-1 px-3 bg-white">{{car?.mileage | number}} KM</p>
      <p class="py-1 px-3 bg-white">{{car?.manufactureYear}}</p>
      <p class="py-1 px-3 bg-white">{{car?.fuelType}}</p>
      <p class="py-1 px-3 bg-white">{{car?.gearbox}}</p>
    </div>
  </div>
</div>
