
<div class="container grid grid-cols-1 gap-12 lg:grid-cols-3 mb-12 lg:my-20 md:min-h-[65vh]">
  <div class="col-span-1 lg:col-span-2">
    <div class="py-12 border-b-2 border-white-600 md:flex md:gap-6">
      <div class="text-7xl text-blue-400">
        <fa-icon [icon]="['fal','circle-check']"></fa-icon>
      </div>
      <div class="mt-7 md:mt-0">
        <p class="font-medium text-xl"> Order 131217312</p>
        <p class="font-bold text-4xl uppercase">Thank you, Kenny!</p>
      </div>
    </div>
    <div class="text-xl font-bold py-12 border-b-2 border-white-600">
      <p class="text-2xl text-blue-400">Maria validated your order!</p>
      <p class="my-4">You will receive a notifcation to aprove the order in the system. </p>
      <p class="mb-7 font-medium">During the next 30 minutes, the cars are reserved to you. Please validate the order in
        this
        time frame to keep the priority.</p>
      <div class="md:w-2/5">
        <primary-btn btnText="ORDER DASHBOARD"></primary-btn>
      </div>
    </div>
    <div class="font-medium mt-3">
      <p>System will pre validate the order and make VIN check for equipment. Issue another allert to user</p>
    </div>
  </div>

  <app-shoping-cart-card class="col-span-1"></app-shoping-cart-card>
</div>

<app-footer></app-footer>
