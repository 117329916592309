import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { CartCar } from "../models/car.model";
import { ShopService } from "./shop.service";
import { BuyList } from "../models/order.model";

@Injectable({
  providedIn: 'root'
})

export class CartService {
  loadingCart = new BehaviorSubject<boolean>(true);

  private cartCarsSubject = new Subject<CartCar[]>();
  cartCarsSubject$ = this.cartCarsSubject.asObservable();

  private buylistSubject = new Subject<BuyList>();
  buylistSubject$ = this.buylistSubject.asObservable();

  constructor(private shopService: ShopService) {
  }

  loadCart() {
    this.shopService.getUserBuyingList().subscribe(resp => {
      this.loadingCart.next(false);

      if (resp.id) {
        this.buylistSubject.next(resp);
        this.cartCarsSubject.next(resp.cars);
      }
    });
  }

  emitCartCars() {
    this.loadingCart.next(true);

    this.shopService.getUserBuyingList().subscribe(resp => {
      this.loadingCart.next(false);

      if(resp.id){
        this.cartCarsSubject.next(resp.cars);
      }
    })
  }

  emitCartCarsValue(cars: CartCar[]) {
    this.cartCarsSubject.next(cars);
  }
}
