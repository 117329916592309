<div class="dropdown relative">
  <p class="absolute z-10 text-white-disabled text-xs -top-5" *ngIf="label">{{label}}</p>
  <input [id]="id + '-input'" [attr.data-cy]="id + '-input'" *ngIf="!numberInput;else numberI"
    class="bg-white-bgGrey hover:bg-white-hoverGrey text-sm px-4 py-3 rounded w-full outline-none"
    [ngClass]="{'disabled':control.disabled}" [placeholder]="placeholder" [formControl]="control">

  <ng-template #numberI>
    <input [id]="id + '-input'" [attr.data-cy]="id + '-input'" type="number"
      class="bg-white-bgGrey hover:bg-white-hoverGrey text-sm px-4 py-3 rounded w-full outline-none"
      [ngClass]="{'disabled':control.disabled}" [placeholder]="placeholder" [formControl]="control">
  </ng-template>
</div>
