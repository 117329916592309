<div class="bg-black py-6 px-9 flex flex-col xl:flex-row gap-4 justify-between text-white"
  *ngIf="!isMobile;else mobile">
  <div class="flex flex-col md:flex-row gap-4 sm:gap-9">
    <div class="flex md:flex-col xl:flex-row items-center gap-3">
      <fa-icon class="w-10 h-10 flex items-center justify-center bg-black-darkChip rounded"
        [icon]="['fas','stopwatch']"></fa-icon>
      <p class="font-bold">35</p>
      <p>Invoices overdue</p>
    </div>

    <div class="flex md:flex-col xl:flex-row items-center gap-3">
      <fa-icon class="w-10 h-10 flex items-center justify-center bg-black-darkChip rounded"
        [icon]="['fas','location-xmark']"></fa-icon>
      <p class="font-bold">35</p>
      <p>Cars not delivered</p>
    </div>

    <div class="flex md:flex-col xl:flex-row items-center gap-3">
      <fa-icon class="w-10 h-10 flex items-center justify-center bg-black-darkChip rounded"
        [icon]="['fas','stopwatch']"></fa-icon>
      <p class="font-bold">35</p>
      <p>Cars paid & not delivered</p>
    </div>

    <div class="flex md:flex-col xl:flex-row items-center gap-3">
      <fa-icon class="w-10 h-10 flex items-center justify-center bg-black-darkChip rounded"
        [icon]="['fas','file-lines']"></fa-icon>
      <p class="font-bold">35</p>
      <p>Placed offers</p>
    </div>
  </div>

  <div class="text-white-greyText text-sm flex items-center">
    <p>Ongoing processes panel</p>
  </div>
</div>


<ng-template #mobile>
  <div class="bg-black py-6 px-9 flex flex-col xl:flex-row gap-4 justify-between text-white">
    <div class="text-white-greyText text-sm flex items-center justify-between" (click)="ongoingPanel.togglePanel()">
      <p>Ongoing processes panel</p>

      <fa-icon [icon]="['far','chevron-down']" *ngIf="!ongoingPanel.expandedPanel;else chevronUp"></fa-icon>
    </div>

    <ng-template #chevronUp>
      <fa-icon [icon]="['far','chevron-up']"></fa-icon>
    </ng-template>

    <app-dropdown-panel #ongoingPanel panelId="ongoingProcPanel">
      <div class="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-4 text-sm">
        <div class="flex items-center gap-3">
          <fa-icon class="w-10 h-10 flex items-center justify-center bg-black-darkChip rounded"
            [icon]="['fas','stopwatch']"></fa-icon>
            <div class="flex gap-3 xs:flex-col sm:flex-row">
            <p class="font-bold">35</p>
            <p>Invoices overdue</p>
          </div>
        </div>

        <div class="flex items-center gap-3">
          <fa-icon class="w-10 h-10 flex items-center justify-center bg-black-darkChip rounded"
            [icon]="['fas','location-xmark']"></fa-icon>
          <div class="flex gap-3 xs:flex-col sm:flex-row">
            <p class="font-bold">35</p>
            <p>Cars not delivered</p>
          </div>
        </div>

        <div class="flex items-center gap-3">
          <fa-icon class="w-10 h-10 flex items-center justify-center bg-black-darkChip rounded"
            [icon]="['fas','stopwatch']"></fa-icon>
          <div class="flex gap-3 xs:flex-col sm:flex-row">
            <p class="font-bold">35</p>
            <p>Cars paid & not delivered</p>
          </div>
        </div>

        <div class="flex items-center gap-3">
          <fa-icon class="w-10 h-10 flex items-center justify-center bg-black-darkChip rounded"
            [icon]="['fas','file-lines']"></fa-icon>
          <div class="flex gap-3 xs:flex-col sm:flex-row">
            <p class="font-bold">35</p>
            <p>Placed offers</p>
          </div>
        </div>
      </div>
    </app-dropdown-panel>

  </div>
</ng-template>
