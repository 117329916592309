import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'primary-btn',
  templateUrl: './primary-btn.component.html',
  styleUrls: ['./primary-btn.component.scss']
})
export class PrimaryBtnComponent implements OnInit {
  @Input() btnText: string | undefined;

  @Output() clickEmitter: EventEmitter<MouseEvent> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  emitClick(event: MouseEvent) {
    this.clickEmitter.emit(event);
  }
}
