import { Injectable } from "@angular/core";
import { CarDetails } from "../models/car.model";
import { TransportMatrix } from "../models/utils.model";
import moment from "moment";

@Injectable({
  providedIn: 'root'
})

export class UtilsService {
  constructor() {
  }

  replaceAll(str: string, find: string, replace: string) {
    function escapeRegExp(string: string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
  }

  putTransportFee(cars: CarDetails[], storeLocation: string, transportMatrix: TransportMatrix[]): CarDetails[] {
    return cars.map(c => ({
      ...c,
      sellingPrice: c.fromOffer ? c.sellingPrice : c.sellingPrice + this.getTransportFee(c.locationId ? c.locationId : c.location, storeLocation, transportMatrix)
    }))
  }

  private getTransportFee(carLocation: string, storeLocation: string, transportMatrix: TransportMatrix[]) {
    let tm = transportMatrix.find(t => t.countryFrom === carLocation && this.replaceAll(t.countryTo, '-', '') === storeLocation);

    return tm ? tm.value : 0;
  }

  public utcDateToLocal(date: string) {
    var offset = moment(date).utcOffset();

    return moment.utc(date).utcOffset(offset).format('yyyy-MM-DD HH:mm:ss');
  }

  addEquipmentToArray(arrayOfEquipments: any[], equipments: string[]) {
    let eq = arrayOfEquipments.map(eq => Object.getOwnPropertyNames(eq)).flat(1);

    eq.forEach(e => {
      equipments.push(this.splitWordByCamelcase(e));
    });
  }

  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  splitWordByCamelcase(string: string): string {
    string = this.capitalizeFirstLetter(string);

    return string.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  }

  splitWordByUnderline(string: string): string {
    string = this.capitalizeFirstLetter(string);

    return string.replaceAll('_', ' ');
  }
}

