import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CoreService } from 'src/app/core/services/core.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';

@Component({
  selector: 'app-my-account-page',
  templateUrl: './my-account-page.component.html',
  styleUrls: ['./my-account-page.component.scss']
})
export class MyAccountPageComponent implements OnInit {
  userInfo = this.coreService.userInfo!;

  storesDropdown: DropdownOption[] = this.userInfo.buyerStores.map(s => ({ value: s.id, viewValue: s.store }));
  storeControl = new FormControl(this.storesDropdown[0].value);
  storeSelected = this.userInfo.buyerStores[0];

  constructor(private coreService: CoreService) { }

  ngOnInit() {
  }

  changeStore(storeId: string) {
    this.storeSelected = this.userInfo.buyerStores.find(s => s.id === storeId)!;
  }
}
