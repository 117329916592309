import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CarDetails } from 'src/app/core/models/car.model';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

interface CreateOfferModalData {
  car: CarDetails
}

@Component({
  selector: 'app-create-offer-modal',
  templateUrl: './create-offer-modal.component.html',
  styleUrls: ['./create-offer-modal.component.scss']
})
export class CreateOfferModalComponent implements OnInit {
  car = this.data.car;

  valueControl = new FormControl(null, Validators.required);
  dateControl = new FormControl<any>(null, Validators.required);

  today = new Date();

  constructor(public dialogRef: MatDialogRef<CreateOfferModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateOfferModalData,
    private snackbar: SnackbarService) { }

  ngOnInit(): void {
  }

  makeOffer() {
    if (this.valueControl.invalid) {
      this.snackbar.negativeSentiment('No value inserted!');
      return;
    }

    if (this.dateControl.invalid) {
      this.snackbar.negativeSentiment('No expiration date selected!');
      return;
    }

    let date = this.dateControl.value;

    this.dialogRef.close({ value: this.valueControl.value, expDate: (typeof (date) === 'string' ? date : date.format("yyyy-MM-DD")) });
  }

  close() {
    this.dialogRef.close();
  }
}
