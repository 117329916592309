<div (click)="c2vMarketPanel.togglePanel()"
  class="bg-black rounded py-6 px-9 flex justify-between gap-3 mb-6 cursor-pointer">
  <p class="text-white">Cars2view market data</p>

  <div class="flex gap-2 sm:gap-6 items-center">
    <p class="text-white-greyText text-sm">Based on your search data</p>

    <fa-icon class="text-white" [icon]="['far','chevron-down']"
      *ngIf="!c2vMarketPanel.expandedPanel; else chevronUp"></fa-icon>
  </div>

  <ng-template #chevronUp>
    <fa-icon class="text-white" [icon]="['far','chevron-up']"></fa-icon>
  </ng-template>
</div>

<!-- dropdown panel with c2v info -->
<app-dropdown-panel #c2vMarketPanel panelId="c2vMarketData">
  <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
    <div class="col-span-1 flex flex-col gap-4 justify-between rounded bg-white py-9 text-center">
      <div>
        <p>Top 5 Selling Makes</p>
      </div>

      <app-pie-chart [chartId]="'sellingCars'" [data]="gearBoxPieChartData"></app-pie-chart>

      <app-white-btn class="px-9" [variant]="4">See more</app-white-btn>
    </div>

    <div class="col-span-1 flex flex-col justify-between rounded bg-white py-9 text-center">
      <div>
        <p>Cars Online</p>
      </div>

      <div class="text-green">
        <fa-icon [icon]="['fas','circle-arrow-up']"></fa-icon>
        <p class="font-bold text-3xl">12.908</p>
      </div>

      <app-white-btn class="px-9" [variant]="4">See more</app-white-btn>
    </div>

    <div class="col-span-1 flex flex-col gap-4 justify-between rounded bg-white py-9 text-center">
      <div>
        <p>Top 5 Selling Makes</p>
      </div>

      <app-pie-chart [chartId]="'gearbox'" [data]="gearBoxPieChartData"></app-pie-chart>

      <app-white-btn class="px-9" [variant]="4">See more</app-white-btn>
    </div>

    <div class="col-span-1 md:col-span-3 rounded bg-white py-12 px-9 text-center mb-9">
      <div>
        <p>Body Types</p>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-6 pt-6">
        <div class="bg-blue-cardBg rounded col-span-1 flex flex-col gap-3 p-6" *ngFor="let body of bodytypesInfo">
          <div class="flex gap-6 items-center text-blue-blueTeal">
            <p class="text-xl font-bold">{{body.percentage}}%</p>
            <p class="text-sm">{{body.name}}</p>
          </div>
          <div class="w-1/2 relative">
            <div class="bg-white-lines h-[2px] w-full"></div>
            <div [id]="body.id" class="bg-blue-blueTeal h-[2px] absolute top-0"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-dropdown-panel>
