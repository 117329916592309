<div class="container flex justify-center">
  <div class="flex flex-col gap-6 text-center justify-center h-[100vh]">
    <div class="self-center">
      <img src="assets/icons/traffic-cone.svg" alt="">
    </div>

    <p class="text-2xl">Restrictive page.</p>

    <p class="w-1/2 self-center text-sm text-white-greyText">You may be a buyer to access this page. Go back to the app or to our <span class="text-blue-400 underline cursor-pointer">Help Centre</span>. </p>

    <app-blue-btn class="w-fit self-center" componentId="logout-btn" (clickEmitter)="logout()">Go back</app-blue-btn>
  </div>

</div>
