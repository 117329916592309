import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-car-card-placeholder',
  template: `
  <div class="flex flex-col gap-9 items-center bg-white rounded p-3">
      <div class="col-span-1">
        <img class="rounded object-contain opacity-30" src="assets/photos/car-placeholder.png" alt="">
      </div>
      <div class="w-full flex flex-col gap-6">
        <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(4)"></div>
      </div>
    </div>
  `,
})

export class CarCardPlaceholderComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
