import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, skip, Subscription } from 'rxjs';
import { Offer } from 'src/app/core/models/offer.model';
import { CoreService } from 'src/app/core/services/core.service';
import { OffersService } from 'src/app/core/services/offers.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { TabOption } from 'src/app/shared/app-tabs/app-tabs.component';
import { carImgPlaceholder } from 'src/app/shared/car-shop-card/car-shop-card.component';
import { CounterOfferModalComponent } from './counter-offer-modal/counter-offer-modal.component';
import { ShopService } from 'src/app/core/services/shop.service';
import { CartService } from 'src/app/core/services/cart.service';
import { PageEvent } from '@angular/material/paginator';
import mapping from 'src/assets/json-files/offers-cars-excel-mapping.json';
import * as XLSX from 'xlsx';
import { CarDetails } from 'src/app/core/models/car.model';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ViewCarDetailsModalComponent } from 'src/app/shared/view-car-details-modal/view-car-details-modal.component';

export enum OfferClientStatuses {
  Accepted = 'Accepted',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Expired = 'Expired',
  CounterOffer = 'Counter Offer',
  C2CNewOffer = 'C2C new offer'
}


interface AllOffers {
  acceptedOffers: {
    offers: Offer[],
    page: number,
    nrOfItems: number,
    refreshOffers: boolean
  },
  pendingOffers: {
    offers: Offer[],
    page: number,
    nrOfItems: number,
    refreshOffers: boolean
  },
  rejectedOffers: {
    offers: Offer[],
    page: number,
    nrOfItems: number,
    refreshOffers: boolean
  },
  finishedOffers: {
    offers: Offer[],
    page: number,
    nrOfItems: number,
    refreshOffers: boolean
  },
  proposedOffers: {
    offers: Offer[],
    page: number,
    nrOfItems: number,
    refreshOffers: boolean
  },
}

@Component({
  selector: 'app-my-offers',
  templateUrl: './my-offers.component.html',
  styleUrls: ['./my-offers.component.scss']
})

export class MyOffersComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);

  carImg: string = carImgPlaceholder;

  allOffers: AllOffers = {
    acceptedOffers: { offers: [], page: 0, nrOfItems: 0, refreshOffers: false },
    pendingOffers: { offers: [], page: 0, nrOfItems: 0, refreshOffers: false },
    finishedOffers: { offers: [], page: 0, nrOfItems: 0, refreshOffers: false },
    proposedOffers: { offers: [], page: 0, nrOfItems: 0, refreshOffers: false },
    rejectedOffers: { offers: [], page: 0, nrOfItems: 0, refreshOffers: false }
  };

  offers: Offer[] = [];

  userInfo = this.coreService.userInfo!;

  tabs: TabOption[] = [
    {
      value: 'proposedOffers',
      viewValue: 'New offers from Cars2click'
    },
    {
      value: 'acceptedOffers',
      viewValue: 'Selected cars to analyse'
    },
    {
      value: 'pendingOffers',
      viewValue: 'My ongoing offers'
    },
    {
      value: 'finishedOffers',
      viewValue: 'Finalized offers'
    },
    {
      value: 'rejectedOffers',
      viewValue: 'Rejected/Expired offers'
    },
  ];

  tabSelected = this.tabs[0].value;

  statuses = OfferClientStatuses;

  subscriptions = new Subscription();

  skipOfferReload = false;

  pageNo = 0;
  noOfOffers = 0;
  itemsPerPage = 10;

  constructor(private coreService: CoreService,
    private router: Router,
    private offersService: OffersService,
    private snackbar: SnackbarService,
    private dialog: MatDialog,
    private cartService: CartService,
    private shopService: ShopService,
    private utilsService: UtilsService) { }

  ngOnInit() {
    this.loadOffers(this.tabSelected);

    this.subscriptions.add(this.cartService.cartCarsSubject$.pipe(skip(1)).subscribe(resp => {
      if (!this.skipOfferReload) {
        this.skipOfferReload = false;

        this.allOffers.acceptedOffers.refreshOffers = true;

        this.loadOffers(this.tabSelected);

        this.offersService.loadOffers();
      } else {
        this.skipOfferReload = false;
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  loadOffers(tab: string, changePage?: boolean) {
    this.loading.next(true);

    let offersObject = this.allOffers[tab as keyof typeof this.allOffers];

    if (changePage) {
      offersObject.page = this.pageNo;
    } else {
      this.pageNo = offersObject.page;
    }

    if (offersObject.offers.length > 0 && !offersObject.refreshOffers) {
      this.offers = offersObject.offers;
      this.noOfOffers = offersObject.nrOfItems;

      this.loading.next(false);
    } else {
      let offersRequest = this.getOfferRequest(tab);

      offersRequest.subscribe(resp => {
        offersObject.offers = resp.offers.map(o => ({
          ...o,
          latestOffer: o.proposedPrices[o.proposedPrices.length - 1].value
        }));

        offersObject.nrOfItems = resp.nrOfItems;

        this.noOfOffers = resp.nrOfItems;

        this.offers = offersObject.offers;

        offersObject.refreshOffers = false;

        this.loading.next(false);
      })
    }

  }

  acceptOffer(offer: Offer) {
    if (this.coreService.showProgressBar.value) return;

    this.coreService.showProgressBar.next(true);

    this.offersService.acceptOffer(offer.offerId).subscribe({
      next: resp => {
        this.allOffers.acceptedOffers.refreshOffers = true;

        this.allOffers[this.tabSelected as keyof typeof this.allOffers].refreshOffers = true;

        this.coreService.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Offer accepted');

        this.loadOffers(this.tabSelected);
      },
      error: err => {
        this.coreService.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    });
  }

  declineOffer(offer: Offer) {
    if (this.coreService.showProgressBar.value) return;

    this.coreService.showProgressBar.next(true);

    this.offersService.declineOffer(offer.offerId).subscribe({
      next: resp => {
        this.allOffers.rejectedOffers.refreshOffers = true;

        this.allOffers[this.tabSelected as keyof typeof this.allOffers].refreshOffers = true;

        this.coreService.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Offer declined');

        this.loadOffers(this.tabSelected);
      },
      error: err => {
        this.coreService.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    });
  }

  openCounterOfferModal(offer: Offer) {
    if (this.coreService.showProgressBar.value) return;

    const dialogRef = this.dialog.open(
      CounterOfferModalComponent, {
      width: '600px',
      maxWidth: '90vw',
      autoFocus: false,
      data: {
        offer: offer
      }
    });

    dialogRef.afterClosed().subscribe(counter => {
      if (counter) {
        this.coreService.showProgressBar.next(true);

        let body = {
          offerId: offer.offerId,
          value: counter.value,
          expiresOn: counter.expiresOn
        }

        this.offersService.makeCounterOffer(body).subscribe({
          next: resp => {
            this.coreService.showProgressBar.next(false);

            if (this.tabSelected != 'pendingOffers') {
              this.allOffers[this.tabSelected as keyof typeof this.allOffers].refreshOffers = true;

              this.allOffers.pendingOffers.refreshOffers = true;

              this.loadOffers(this.tabSelected);

            } else {
              let offerIndex = this.offers.findIndex(o => o.offerId === offer.offerId);

              offer.proposedPrices.push({
                username: this.userInfo!.user.email,
                offeredBy: 'CLIENT',
                value: counter.value,
                addedOn: new Date()
              });

              this.offers[offerIndex] = {
                ...offer,
                latestOffer: counter.value,
                clientConfirmation: this.statuses.Accepted,
                c2cConfirmation: this.statuses.Pending,
                offerStatus: this.statuses.Pending
              }
            }

            this.snackbar.positiveSentiment('Counter offer created');
          },
          error: err => {
            this.coreService.showProgressBar.next(false);

            this.snackbar.negativeSentiment(err.error);
          }
        })
      }
    });
  }

  goToCar(offer: Offer) {
    this.router.navigate([`car/${offer.carDetails.carMainInfoId}`]);
  }

  changeTab(tab: string) {
    if (this.coreService.showProgressBar.value) return;

    this.tabSelected = tab;

    this.loadOffers(this.tabSelected);
  }

  getOfferRequest(tab: string) {
    let offerRequest = new Observable<{ nrOfItems: number, offers: Offer[] }>();

    switch (tab) {
      case 'pendingOffers':
        offerRequest = this.offersService.getPendingOffers(this.pageNo, this.itemsPerPage);
        break;
      case 'proposedOffers':
        offerRequest = this.offersService.getC2CProposedOffers(this.pageNo, this.itemsPerPage);
        break;
      case 'acceptedOffers':
        offerRequest = this.offersService.getReadyToOrderOffers(this.pageNo, this.itemsPerPage);
        break;
      case 'finishedOffers':
        offerRequest = this.offersService.getFinishedOffers(this.pageNo, this.itemsPerPage);
        break;
      case 'rejectedOffers':
        offerRequest = this.offersService.getRejectedOffers(this.pageNo, this.itemsPerPage);
        break;
      default:
        break;
    }

    return offerRequest;
  }

  addToCart(offer: Offer) {
    this.shopService.addCarToCart(offer.carDetails.carMainInfoId).subscribe({
      next: resp => {
        this.skipOfferReload = true;

        this.allOffers.acceptedOffers.refreshOffers = true;

        this.cartService.emitCartCars();

        this.offersService.loadOffers();

        this.loadOffers(this.tabSelected);

        this.snackbar.positiveSentiment('Car added to cart');
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);
      }
    });
  }

  changePage(pageEvent: PageEvent) {
    this.pageNo = pageEvent.pageIndex;


    this.allOffers[this.tabSelected as keyof typeof this.allOffers].refreshOffers = true;

    this.loadOffers(this.tabSelected, true);
  }

  getOffersCarsInfoExport() {
    this.coreService.showProgressBar.next(true);

    this.offersService.getExportOffersCarsInfo().subscribe(resp => {
      let wsHeaders = mapping.map(t => t.excelValue);

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(ws, [wsHeaders]);

      let cars = resp.map(o => ({
        regNo: o.carDetails.regNo,
        vin: o.carDetails.vin,
        make: o.carDetails.make,
        model: o.carDetails.model,
        variant: o.carDetails.variant,
        trim: o.carDetails.trim,
        fuelType: o.carDetails.fuelType,
        gearbox: o.carDetails.gearbox,
        manufactureYear: o.carDetails.manufactureYear,
        firstReg: o.carDetails.firstReg,
        enginePower: o.carDetails.enginePower,
        engineVolume: o.carDetails.engineVolume,
        mileage: o.carDetails.mileage,
        color: o.carDetails.color,
        bodyType: o.carDetails.bodyType,
        drivingWheels: o.carDetails.drivingWheels,
        co2NEDC: o.carDetails.co2NEDC,
        co2WLTP: o.carDetails.co2WLTP,
        offerPrice: o.proposedPrices[o.proposedPrices.length - 1].value,
        equipments: this.getCarEquipments(o.carDetails.c2cEquipment, o.carDetails.c2vEquipment).join(', ')
      }));

      let rows = cars.map(c => {
        let row: string[] = [];

        mapping.forEach(m => {
          row.push(c[m.objectValue as keyof typeof c].toString());
        });

        return row;
      })

      XLSX.utils.sheet_add_aoa(ws, rows, { origin: 'A2' });

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      XLSX.writeFile(wb, 'cars-from-offers.xlsx');

      this.coreService.showProgressBar.next(false);
    });
  }

  getCarEquipments(carMasterEquipments: any, carC2VEquipments: any) {
    let equipments: string[] = [];

    if (carMasterEquipments.optionalEquipment) {
      this.utilsService.addEquipmentToArray(carMasterEquipments.optionalEquipment, equipments);
      this.utilsService.addEquipmentToArray(carMasterEquipments.standardEquipment, equipments);
      this.utilsService.addEquipmentToArray(carMasterEquipments.packEquipment, equipments);
    }

    //add car custom eq to list
    equipments = [...equipments, ...carC2VEquipments.map((c: string) => this.utilsService.splitWordByUnderline(c))];

    return equipments;
  }

  openCarModal(carId: string) {
    this.dialog.open(
      ViewCarDetailsModalComponent, {
      width: '1000px',
      maxWidth: '90vw',
      autoFocus: false,
      data: {
        carId: carId
      }
    });
  }
}
