import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buying-profile',
  templateUrl: './buying-profile.component.html',
  styleUrls: ['./buying-profile.component.scss']
})
export class BuyingProfileComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
