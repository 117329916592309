import { Component, Input, OnInit } from '@angular/core';
import { CarDetails } from 'src/app/core/models/car.model';
import { faHeart } from '@fortawesome/pro-solid-svg-icons';
import { Router } from '@angular/router';
import { ShopService } from 'src/app/core/services/shop.service';
import { UserService } from 'src/app/core/services/user.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { BusinessCase, CarStatus } from 'src/app/core/models/utils.model';
import { CoreService } from 'src/app/core/services/core.service';
import { Offer } from 'src/app/core/models/offer.model';
import { OfferClientStatuses } from 'src/app/features/my-offers/my-offers.component';
import { CartService } from 'src/app/core/services/cart.service';

export enum VAT {
  IncludedVAT = 'Incl. non-refundable VAT',
  ExVAT = 'Ex VAT'
}

export const carImgPlaceholder = './assets/photos/car-placeholder.png';
@Component({
  selector: 'app-car-shop-card',
  templateUrl: './car-shop-card.component.html',
  styleUrls: ['./car-shop-card.component.scss']
})

export class CarShopCardComponent implements OnInit {
  faHeart = faHeart;

  @Input() car: CarDetails | undefined;
  @Input() isFavoritesPage: boolean = false;
  @Input() isClosedSales: boolean = false;

  carBusinessCase: BusinessCase | undefined;

  imgUrl: string = '';
  isFav = false;

  isNew = false;

  availableFrom = new Date();
  availableTo = new Date();

  deliveredTo = this.coreService.userInfo?.country.country;

  vatStatus = VAT;

  carStatus = CarStatus;

  constructor(private router: Router,
    private shopService: ShopService,
    private userService: UserService,
    private snackbar: SnackbarService,
    private coreService: CoreService,
    private cartService: CartService) { }

  ngOnInit(): void {
    this.imgUrl = this.car?.profilePhoto && this.car?.profilePhoto != 'None' ? this.car.profilePhoto : carImgPlaceholder;

    if (this.userService.carFavorites.find(c => c === this.car?.carMainInfoId)) {
      this.isFav = true;
    }

    let date = new Date();
    date.setDate(date.getDate() - 7);

    this.isNew = (new Date(this.car!.created_at * 1000)) > date;

    this.availableFrom = new Date(this.car!.available.from);

    this.availableTo = new Date(this.car!.available.to);

    if (this.car!.offerDetails && !this.car!.offerDetails.myOffer) {
      this.car!.offerDetails.offerStatus = 'newC2COffer';
    }

    if (this.car!.offerDetails && this.car!.offerDetails.offerStatus === OfferClientStatuses.Pending && this.car!.offerDetails.offeredBy === 'C2C') {
      this.car!.offerDetails.offerStatus = 'counterOffer';
    }
  }

  goToCar() {
    this.router.navigate([`car/${this.car?.carMainInfoId}`]);
  }

  addToFav() {
    if (this.isFav) {
      this.userService.carFavorites.splice(this.userService.carFavorites.findIndex(c => c === this.car?.carMainInfoId), 1);

      this.userService.removeFavoritesCar(this.car?.carMainInfoId!).subscribe(resp => {
      })
    } else {
      this.userService.carFavorites.push(this.car?.carMainInfoId!);

      this.shopService.addCarToFavorites(this.car!.carMainInfoId!).subscribe(resp => {
      })
    }

    this.isFav = !this.isFav;
  }

  addToCart() {
    this.shopService.addCarToCart(this.car?.carMainInfoId!).subscribe({
      next: resp => {
        this.cartService.emitCartCars();

        this.snackbar.positiveSentiment('Car added to cart');
      },
      error: err => {
        this.snackbar.negativeSentiment('Car already exists in cart');
      }
    });
  }

  isNan(value: any) {
    return isNaN(value);
  }
}
