import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Order, OrderCar } from 'src/app/core/models/order.model';
import { MyOrdersService } from 'src/app/core/services/my-orders.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { ViewCarDetailsModalComponent } from 'src/app/shared/view-car-details-modal/view-car-details-modal.component';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);

  order: Order | undefined;

  carsConfirmed: OrderCar[] = [];
  carsUnset: OrderCar[] = [];

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private orderService: MyOrdersService,
    private snackbar: SnackbarService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      let orderId = params['orderId'];

      this.orderService.getOrder(orderId).subscribe(order => {
        this.order = order;

        this.order.cars.forEach(car => {
          let transport = this.order!.transportDetails.transports.find(t => t.cars.find(c => c.carMainInfoId === car.carMainInfoId));

          if (transport) {
            car.transportDetails = {
              actualDeliveryDate: transport.actualDeliveryDate,
              estimatedDeliveryDate: transport.estimatedDeliveryDate,
              status: transport.status,
              pickupDate: transport.pickupDate,
              transportNumber: this.order!.transportDetails.transports.findIndex(tr => tr.transportId === transport!.transportId) + 1
            }
          }
        });

        this.carsConfirmed = this.order.cars.filter(c => c.carAvailability === 'Confirmed');
        this.carsUnset = this.order.cars.filter(c => c.carAvailability != 'Confirmed');

        this.loading.next(false);
      })
    });
  }

  getTotalExtras() {
    return this.order!.cars.reduce((sum, car) => {
      return sum + car.photoEditing.photoEditValue;
    }, 0)
  }

  goBack() {
    this.router.navigate(['my-orders']);
  }

  downloadFile(carId: string, docId: string, docType: string) {
    let body = {
      orderId: this.order!.orderId,
      carMainInfoId: carId,
      id: docId,
      docType: docType,
    }

    this.orderService.downloadOrderCarDoc(body).subscribe({
      next: resp => {
        window.open(resp, "_blank");
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);
      }
    });
  }

  downloadOrderDoc(docId: string) {
    this.orderService.downloadOrderDoc(this.order!.orderId, docId).subscribe({
      next: resp => {
        window.open(resp, "_blank");
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);
      }
    });
  }

  openViewCarModal(carId: string) {
    this.dialog.open(
      ViewCarDetailsModalComponent, {
      width: '1000px',
      maxWidth: '90vw',
      autoFocus: false,
      data: {
        carId: carId
      }
    });
  }
}
