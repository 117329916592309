<div class="w-screen h-screen bg-white-100 flex justify-center items-center">
  <div class="relative">
    <img src="assets/background-photos/no-page.svg" alt="">
  </div>

  <div class="absolute w-screen h-screen flex flex-col justify-center text-center">
    <p>Sorry, this page is not available.</p>

    <p class="text-sm text-white-greyText mt-3 mb-12">The page you’re looking for couldn’t be found.</p>

    <div class="flex justify-center">
      <app-blue-btn class="w-fit" (clickEmitter)="goToShop()">Go back</app-blue-btn>
    </div>
  </div>
</div>
