<app-my-profile-page-layout [profilePage]="'profile'">
  <app-main-content-layout>
    <div class="flex flex-col">
      <p class="sm:text-2xl mb-12">Account details</p>
      <!-- <p class="text-sm text-blue-ctaBlue mt-3 mb-12">Change logo</p> -->

      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 2xl:grid-cols-6 gap-y-6 gap-x-4">
        <app-read-only label="Company name" [text]="userInfo.buyerCompany.buyer"></app-read-only>
        <app-read-only label="First name" [text]="userInfo.user.firstName"></app-read-only>
        <app-read-only label="Last name" [text]="userInfo.user.lastName"></app-read-only>
        <app-read-only label="Email">
          <p class="overflow-hidden" [matTooltip]="userInfo.user.email" matTooltipPosition="above">
            {{userInfo.user.email}}</p>
        </app-read-only>
        <app-read-only label="Phone" [text]="userInfo.user.phone"></app-read-only>
      </div>
    </div>
  </app-main-content-layout>

  <app-main-content-layout>
    <div class="flex gap-3 items-center mb-12">
      <fa-icon class="w-8 h-8 flex items-center justify-center bg-white-bgGrey rounded text-blue-ctaBlue"
        [icon]="['far','money-bill']"></fa-icon>
      <p class="sm:text-2xl">Billing Address</p>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 2xl:grid-cols-6 gap-y-6 gap-x-4">
      <app-read-only label="Country" [text]="userInfo.buyerCompany.country.country"></app-read-only>

      <app-read-only label="City" [text]="userInfo.buyerCompany.city"></app-read-only>

      <app-read-only label="Zip code" [text]="userInfo.buyerCompany.postalCode"></app-read-only>

      <app-read-only label="Address">
        <div class="noscrollbar">
          <p class="overflow-scroll whitespace-nowrap" [matTooltip]="userInfo.buyerCompany.address"
            matTooltipPosition="above">
            {{userInfo.buyerCompany.address}}</p>
        </div>
      </app-read-only>
    </div>
  </app-main-content-layout>

  <app-main-content-layout>
    <div class="flex gap-3 items-center mb-12">
      <fa-icon class="w-8 h-8 flex items-center justify-center bg-white-bgGrey rounded text-blue-ctaBlue"
        [icon]="['far','gift']"></fa-icon>
      <p class="sm:text-2xl">Delivery Store Address</p>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 2xl:grid-cols-6 gap-y-6 gap-x-4">
      <app-dropdown id="store" [control]="storeControl" [options]="storesDropdown"
        (emitOnChangeSelect)="changeStore($event)" placeholder="Select store" label="Store"></app-dropdown>

      <app-read-only label="Country" [text]="storeSelected.country.country"></app-read-only>
      <app-read-only label="City" [text]="storeSelected.city"></app-read-only>
      <app-read-only label="Zip code" [text]="storeSelected.postalCode"></app-read-only>


      <app-read-only label="Address">
        <div class="noscrollbar">
          <p class="overflow-scroll whitespace-nowrap" [matTooltip]="storeSelected.address" matTooltipPosition="above">
            {{storeSelected.address}}</p>
        </div>
      </app-read-only>
    </div>
  </app-main-content-layout>
</app-my-profile-page-layout>
