import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-content-layout',
  template: `
    <div class="bg-white p-6 md:px-9 rounded w-full" [ngClass]="{'h-full' : fullHeight}">
      <ng-content></ng-content>
    </div>
  `,
})

export class MainContentLayoutComponent implements OnInit {
  @Input() fullHeight = false;

  constructor() { }

  ngOnInit() { }
}
