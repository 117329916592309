import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { AdvancedSearchComponent } from './advanced-search.component';
import { AllFiltersModalComponent } from './all-filters-modal/all-filters-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    AdvancedSearchComponent,
    AllFiltersModalComponent
  ]
})

export class AdvancedSearchModule { }
