import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthService } from "../services/auth.service";
import { CoreService } from "../services/core.service";

@Injectable()
export class AuthGuardMaintenance implements CanActivate {
  private jwtHelperService = new JwtHelperService();

  constructor(private authService: AuthService,
    private router: Router,
    private coreService: CoreService) { }

  canActivate() {
    if (this.coreService.userInfo && !(this.coreService.userInfo.role === 'CLIENT' && this.coreService.userInfo.buyerCompany)) {
      this.router.navigate(['restricted']);
    }

    let token = localStorage.getItem('msalToken');

    if (token && this.jwtHelperService.isTokenExpired(token)) {
      this.authService.logout(window.location.pathname.slice(1));

      return false;
    } else if (token) {
      this.authService.token = token;

      return true;
    }
    else {
      this.authService.logout(window.location.pathname.slice(1));

      return false;
    }
  }
}
