import { CarDetails } from "./car.model"

export interface Country {
  id: string,
  name: string,
  iso: string
}

export interface TransportMatrix {
  id: string,
  countryFrom: string,
  countryTo: string,
  value: number,
  status: boolean,
  created_at: string,
  modified_at: string,
}

export interface BusinessCaseBody {
  countries: string[],
  make: string,
  model: string,
  manufactureYear: number,
  fuelType: string,
  gearbox: string,
  bodyType: string,
  variant: string,
  mileage: number,
  enginePower: number,
  firstReg: string,
  carMainInfoId: string,
  accessories: string[]
}

export interface BusinessCaseResponse {
  carMainInfoId: string,
  businessCase: BusinessCase[]
}

export interface BusinessCase {
  spotPrice: number,
  spotCountry: string,
  spotLevel: string,
  spotCurrency: string,
  carsOnline: number,
  carsSold30Days: number,
  salesFactor: number,
  error: string | null,
  errorDetails: string | null
}

export interface Constants {
  name: string,
  value: number
}

export interface ShopCacheBody {
  shopCars: CarDetails[],
  lastShopPage: number,
  nrOfResults: number,
  route: string
}

export enum ConstantsEnum {
  DHL = 'DHL',
  COC = 'cocValue',
  PhotoEdit = 'photoEdit'
}

export enum CarStatus {
  Active = 'Active',
  Reserved = 'Reserved'
}

export interface IDocument {
  id: string,
  name: string,
  doc: string,
  visible: boolean
}
