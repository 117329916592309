import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ViewOrdersComponent } from './view-orders/view-orders.component';
import { OrderComponent } from './order/order.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  declarations: [
    ViewOrdersComponent,
    OrderComponent
  ]
})

export class MyOrdersModule { }
