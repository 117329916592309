import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-toggle',
  templateUrl: './app-toggle.component.html',
  styleUrls: ['./app-toggle.component.scss']
})
export class AppToggleComponent implements OnInit {
  @Input() control: FormControl = new FormControl();
  @Input() label: string = "";
  @Input() disabled: boolean = false;
  @Input() id = 'id';

  @Output() toggleEmitter = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  emitToggle(event: MatSlideToggleChange) {
    this.toggleEmitter.emit(event.checked);
  }
}
