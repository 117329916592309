import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppDropdownPanelComponent } from '../app-dropdown-panel/app-dropdown-panel.component';

@Component({
  selector: 'app-my-profile-car-card',
  templateUrl: './my-profile-car-card.component.html',
  styleUrls: ['./my-profile-car-card.component.scss']
})

export class MyProfileCarCardComponent implements OnInit {
  @Input() profilePageSelected = '';
  @ViewChild('mobileOfferHistory') mobileOfferHistory!: AppDropdownPanelComponent;

  public expandedOfferHistoryMobile: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  toggleOfferHistoryMobile() {
    this.expandedOfferHistoryMobile = !this.expandedOfferHistoryMobile;

    this.mobileOfferHistory.togglePanel();
  }

  goToOffer() {
    this.router.navigate(['offer']);
  }
}
