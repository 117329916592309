import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { MyOffersComponent } from './my-offers.component';
import { CounterOfferModalComponent } from './counter-offer-modal/counter-offer-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    MyOffersComponent,
    CounterOfferModalComponent
  ]
})

export class MyOffersModule { }
