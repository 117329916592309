import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CarPageComponent } from './car-page.component';
import { CreateOfferModalComponent } from './create-offer-modal/create-offer-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    CarPageComponent,
    CreateOfferModalComponent
  ]
})

export class CarPageModule { }
