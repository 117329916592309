import { Component, OnInit } from '@angular/core';
import { carImgPlaceholder } from 'src/app/shared/car-shop-card/car-shop-card.component';

@Component({
  selector: 'app-maria-selection',
  templateUrl: './maria-selection.component.html',
  styleUrls: ['./maria-selection.component.scss']
})
export class MariaSelectionComponent implements OnInit {
  carImg = carImgPlaceholder;

  constructor() { }

  ngOnInit() {
  }

}
