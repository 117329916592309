import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { CarDetails, CartCar } from 'src/app/core/models/car.model';
import { CartService } from 'src/app/core/services/cart.service';
import { ShopService } from 'src/app/core/services/shop.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UserService } from 'src/app/core/services/user.service';
import { VAT, carImgPlaceholder } from 'src/app/shared/car-shop-card/car-shop-card.component';


@Component({
  selector: 'app-my-profile-favorites',
  templateUrl: './my-profile-favorites.component.html',
  styleUrls: ['./my-profile-favorites.component.scss']
})
export class MyProfileFavoritesComponent implements OnInit {
  loading = new BehaviorSubject(true);

  carImg = carImgPlaceholder;
  cars: CartCar[] = [];

  vatStatus = VAT;

  constructor(private userService: UserService,
    private router: Router,
    private shopService: ShopService,
    private snackbar: SnackbarService,
    private cartService: CartService) { }

  ngOnInit() {
    this.userService.getUserFavoritesCars().subscribe(resp => {
      this.cars = resp.cars;

      this.loading.next(false);
    });
  }

  addToCart(car: CarDetails) {
    this.shopService.addCarToCart(car.carMainInfoId!).subscribe({
      next: resp => {
        this.snackbar.positiveSentiment('Car added to cart');

        this.cartService.emitCartCars();
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);
      }
    });
  }

  removeFromFav(carId: string) {
    this.userService.removeFavoritesCar(carId).subscribe({
      next: resp => {
        this.cars.splice(this.cars.findIndex(c => c.carMainInfoId === carId), 1);
        this.userService.carFavorites.splice(this.userService.carFavorites.findIndex(c => c === carId), 1);
      },
      error: err => {
        console.log(err)
      }
    })
  }

  goToCar(carId: string) {
    this.router.navigate([`car/${carId}`])
  }
}
