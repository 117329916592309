<app-my-profile-page-layout [profilePage]="'maria'">
  <app-main-content-layout>
    <div class="flex flex-col sm:flex-row gap-6 justify-between items-center">
      <p>Here are the best deals for you.</p>

      <div class="flex flex-col xs:flex-row gap-3">
        <app-white-btn [variant]="4">Clear all cars</app-white-btn>
        <app-blue-btn>Add all cars to cart</app-blue-btn>
      </div>
    </div>
  </app-main-content-layout>

  <div class="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
    <div *ngFor="let i of [1,2,3,4,5,7,0,8]">
      <div class="flex w-full h-60 xl:h-[calc(100vw*0.1)] mr-9 relative">
        <img class="w-full object-cover rounded" [src]="carImg" alt="">

        <fa-icon
          class="absolute fa-icon-div top-1 right-1 cursor-pointer text-blue-headerBg transition duration-150 hover:scale-105 ease-in-out"
          [icon]="['fas','trash-can']"></fa-icon>
      </div>

      <div class="p-6 bg-white flex flex-col gap-6">
        <div>
          <p class="font-bold mb-3">BMW 3 series, 320d xDrive</p>

          <div class="flex flex-wrap gap-3 text-blue-darkMauve">
            <div class="flex gap-3 text-xs py-1 px-2 bg-white-bgGrey">
              <p>11.000 KM</p>
            </div>

            <div class="flex gap-3 text-xs py-1 px-2 bg-white-bgGrey">
              <p>2019</p>
            </div>

            <div class="flex gap-3 text-xs py-1 px-2 bg-white-bgGrey">
              <p>manual</p>
            </div>

            <div class="flex gap-3 text-xs py-1 px-2 bg-white-bgGrey">
              <p>diesel</p>
            </div>
          </div>
        </div>

        <div class="flex items-center gap-3">
          <p class="font-bold text-blue-ctaBlue">€25.000</p>
          <p class="text-sm text-white-greyText">ex VAT</p>
        </div>

        <p class="font-bold">Business Case</p>

        <div class="text-sm flex flex-col gap-3">
          <div class="flex justify-between">
            <p class="text-white-greyText">Similar cars sold </p>
            <p>100 </p>
          </div>
          <div class="flex justify-between">
            <p class="text-white-greyText">Similar cars sold </p>
            <p>100 </p>
          </div>
          <div class="flex justify-between">
            <p class="text-white-greyText">Similar cars for sale </p>
            <p>100 </p>
          </div>
          <div class="flex justify-between">
            <p class="text-white-greyText">Sales factor </p>
            <p>30 </p>
          </div>
          <div class="flex justify-between">
            <p class="text-white-greyText">Retail ex VAT in Germany </p>
            <p>€24.000</p>
          </div>
          <div class="flex justify-between">
            <p class="text-white-greyText">B2B Delivered </p>
            <p>€24.000</p>
          </div>
          <div class="text-green flex justify-between">
            <p>Profit </p>
            <p>€1.000</p>
          </div>
        </div>

        <app-blue-btn>Add to cart</app-blue-btn>
      </div>
    </div>

  </div>
</app-my-profile-page-layout>
