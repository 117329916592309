import { Component, Input, OnInit } from '@angular/core';
import { IconName, IconPrefix } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-no-results-placeholder',
  template: `
    <div class="grid grid-cols-1 xl:grid-cols-3 gap-6 items-center">
      <div class="hidden xl:block rounded w-full max-h-40 bg-gradient-to-l from-white-hoverGrey to-white-bgGrey aspect-video">
      </div>

      <div
        class="m-auto max-w-xs xl:max-w-none xl:m-0 rounded w-full max-h-52 h-96 bg-white-hoverGrey aspect-video text-white flex justify-center items-center">
        <fa-icon class="px-4 py-3 bg-white-disabled text-3xl rounded-[500px]"
          [icon]="[iconClass,icon]"></fa-icon>
      </div>

      <div class="hidden xl:block rounded w-full max-h-40 bg-gradient-to-r from-white-hoverGrey to-white-bgGrey aspect-video">
      </div>
    </div>
  `,
})

export class NoResultsPlaceholderComponent implements OnInit {
  @Input() iconClass: IconPrefix = "fas";
  @Input() icon: IconName = "heart";

  constructor() { }

  ngOnInit() { }
}
