import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom, forkJoin, zip } from 'rxjs';
import { CartCar } from 'src/app/core/models/car.model';
import { CreateOrderBody, UpdateCartBody } from 'src/app/core/models/order.model';
import { ConstantsEnum } from 'src/app/core/models/utils.model';
import { CoreService } from 'src/app/core/services/core.service';
import { ShopService } from 'src/app/core/services/shop.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UserService } from 'src/app/core/services/user.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { VAT } from 'src/app/shared/car-shop-card/car-shop-card.component';
import { OfferClientStatuses } from '../my-offers/my-offers.component';
import { CartService } from 'src/app/core/services/cart.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit {
  loading = new BehaviorSubject(true);
  loadingCart = this.cartService.loadingCart;

  cars: CartCar[] = [];

  userInfo = this.coreService.userInfo!;

  buyerStores: DropdownOption[] = this.userInfo.buyerStores.map(s => ({ value: s.id, viewValue: s.store }));
  selectedStore: any;
  storeControl = new FormControl(this.buyerStores[0].value, Validators.required);
  storeLocationId = '';

  cartId = '';

  company = this.userInfo.buyerCompany;

  vatStatus = VAT;

  orderOptionsForm = this.fb.group({
    separateTransportInv: [false],
    separateCarInv: [false]
  });

  termsAndCondAccepted = false;

  photoEditValue = 0;

  constructor(private shopService: ShopService,
    private coreService: CoreService,
    private fb: FormBuilder,
    private userService: UserService,
    private snackbar: SnackbarService,
    private router: Router,
    private cartService: CartService) { }

  ngOnInit() {
    forkJoin({
      constants: this.shopService.getConstants()
    }).subscribe(resp => {
      this.photoEditValue = resp.constants.find(c => c.name === ConstantsEnum.PhotoEdit)!.value;

      this.changeStore(this.storeControl.value!);

      this.loading.next(false);
    });

    this.cartService.buylistSubject$.subscribe(resp => {
      this.cartId = resp.id;
    });

    this.cartService.cartCarsSubject$.subscribe(resp => {
      this.cars = resp;
    });
  }

  removeFromCart(carId: string) {
    this.coreService.showProgressBar.next(true);

    this.shopService.removeCarFromCart(carId).subscribe(resp => {
      this.cars.splice(this.cars.findIndex(c => c.carMainInfoId === carId), 1);

      this.cartService.emitCartCarsValue(this.cars);

      this.coreService.showProgressBar.next(false);

      this.snackbar.positiveSentiment('Car removed');
    });
  }

  sendOrder() {
    if (this.storeControl.invalid) {
      this.snackbar.negativeSentiment('No store selected');
      return;
    }

    if (this.orderOptionsForm.invalid) {
      this.snackbar.negativeSentiment('Please select a date for requested delivery date and estimated payment date');
      return;
    }


    if (!this.termsAndCondAccepted) {
      this.snackbar.negativeSentiment('To place an order you need to accept terms and conditions');
      return;
    }

    let order: CreateOrderBody = {
      oneInvoicePerCar: this.orderOptionsForm.controls.separateCarInv.value!,
      separateInvoiceForTransport: this.orderOptionsForm.controls.separateTransportInv.value!,
      storeId: this.storeControl.value!,
      id: this.cartId
    };

    this.coreService.showProgressBar.next(true);

    this.shopService.placeOrder(order).subscribe({
      next: resp => {
        this.coreService.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Order created successfully');

        this.router.navigate(['my-orders'])
      },
      error: err => {
        this.coreService.showProgressBar.next(false);

        this.snackbar.negativeSentiment('Something went wrong');
      }
    });
  };

  getCarsFromOffers(loadingPage?: boolean) {
    if (loadingPage) {
      this.loading.next(true);
    } else {
      this.coreService.showProgressBar.next(true);
    }

    this.userService.getOffers().subscribe({
      next: async resp => {

        const addCarToCartPromise: Promise<any>[] = [];

        resp.forEach(offer => {
          if (!this.cars.find(carInCart => carInCart.carMainInfoId === offer.carDetails.carMainInfoId) && offer.offerStatus === OfferClientStatuses.Accepted) {
            addCarToCartPromise.push(firstValueFrom(this.shopService.addCarToCart(offer.carDetails.carMainInfoId)))
          }
        });

        if (addCarToCartPromise.length === 0) {
          this.coreService.showProgressBar.next(false);
          this.loading.next(false);
          this.snackbar.positiveSentiment('All offers already added to cart');
          return;
        }

        let addToCartResp = await firstValueFrom(zip(addCarToCartPromise));

        this.shopService.getUserBuyingList().subscribe(cart => {
          this.cars = cart.cars;

          this.coreService.showProgressBar.next(false);

          this.loading.next(false);

          this.cartService.emitCartCarsValue(cart.cars);

          this.snackbar.positiveSentiment('Cars added to cart');
        })
      },
      error: err => {
        this.coreService.showProgressBar.next(false);

        this.loading.next(false);

        this.snackbar.negativeSentiment('Something went wrong');
      }
    });
  }

  changeStore(storeId: string) {
    this.selectedStore = this.userInfo.buyerStores.find(s => s.id === storeId);

    this.storeLocationId = this.selectedStore.country.id;
  }

  getCarsTotalSum(): number {
    return this.cars.map(c => c.carPricing.offerDetails.offeredPrice ? c.carPricing.offerDetails.offeredPrice : c.carPricing.shopPrice).reduce((a, b) => a! + b!, 0)!;
  }

  getPhotoTotal(): number {
    return this.cars.reduce((sum, car) => {
      sum += (car.additionalProperties.photoEditing ? this.photoEditValue : 0);

      return sum;
    }, 0)!;
  }

  getTotalSum(): number {
    return this.getPhotoTotal() + this.getCarsTotalSum();
  }

  goToShop() {
    this.router.navigate(['search']);
  }

  goToCar(carId: string) {
    this.router.navigate([`car/${carId}`])
  }

  updateCartCarCOC(car: CartCar, event: boolean) {
    let body: UpdateCartBody = {
      carMainInfoId: car.carMainInfoId,
      cocRequired: event,
      photoEditing: car.additionalProperties.photoEditing
    }

    this.shopService.updateCartOptions(body).subscribe(resp => { console.log(resp) });
  }

  updateCartCarPhotoEdit(car: CartCar, event: boolean) {
    let body: UpdateCartBody = {
      carMainInfoId: car.carMainInfoId,
      cocRequired: car.additionalProperties.cocRequired,
      photoEditing: event
    }

    this.shopService.updateCartOptions(body).subscribe(resp => { console.log(resp) });
  }

  addCOCAllCars() {
    this.coreService.showProgressBar.next(true);

    this.shopService.addCOCAllCars(this.cartId).subscribe({
      next: resp => {
        this.cars.forEach(c => c.additionalProperties.cocRequired = true);

        this.snackbar.positiveSentiment('COC included for all cars');

        this.coreService.showProgressBar.next(false);
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.coreService.showProgressBar.next(false);
      }
    })
  }

  addPhotoEditAllCars() {
    this.coreService.showProgressBar.next(true);

    this.shopService.addPhotoEditAllCars(this.cartId).subscribe({
      next: resp => {
        this.cars.forEach(c => c.additionalProperties.photoEditing = true);

        this.snackbar.positiveSentiment('Photo editing added for all cars');

        this.coreService.showProgressBar.next(false);
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.coreService.showProgressBar.next(false);
      }
    })
  }
}
