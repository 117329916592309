import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './app-checkbox.component.html',
  styleUrls: ['./app-checkbox.component.scss']
})
export class AppCheckboxComponent implements OnInit {
  @Input() checkboxValue = false;
  @Output() checkboxValueChange = new EventEmitter<boolean>();

  @Input() label: string = '';
  @Input() componentId: string = '';
  @Input() checkboxDisabled: boolean = false;
  @Input() orangeColor: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
