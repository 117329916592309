<app-my-profile-page-layout [profilePage]="'buy-profile'">
  <app-main-content-layout>
    <div class="flex gap-3">
      <fa-icon class="fa-icon-div text-blue-ctaBlue" [icon]="['far','money-bill']"></fa-icon>
      <p class="sm:text-2xl">View buying profile</p>
    </div>

    <div class="mt-12 flex flex-col lg:flex-row justify-between gap-3">
      <div class="flex flex-col flex-wrap sm:flex-row gap-6 sm:gap-x-3">
        <app-dropdown label="Makes" placeholder="Select makes"></app-dropdown>
        <app-dropdown label="Year from" placeholder="Select year from"></app-dropdown>
        <app-dropdown label="Mileage from" placeholder="Select mileage from"></app-dropdown>
        <app-dropdown label="Mileage up to" placeholder="Select mileage up to"></app-dropdown>
      </div>

      <app-blue-btn class="max-w-[15rem]">Update profile</app-blue-btn>
    </div>
  </app-main-content-layout>
</app-my-profile-page-layout>
