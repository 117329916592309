import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'secondary-btn',
  templateUrl: './secondary-btn.component.html',
  styleUrls: ['./secondary-btn.component.scss']
})
export class SecondaryBtnComponent implements OnInit {
  @Input() btnText: string | undefined;
  @Input() id = 'id';

  @Output() clickEmitter: EventEmitter<MouseEvent> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  emitClick(event: MouseEvent) {
    this.clickEmitter.emit(event);
  }
}
