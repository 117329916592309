import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Chart, registerables, ChartConfigurationCustomTypesPerDataset } from 'chart.js';

export interface PieChartData {
  label: string,
  data: number,
  backgroundColor: string
}

@Component({
  selector: 'app-pie-chart',
  templateUrl: './app-pie-chart.component.html',
  styleUrls: ['./app-pie-chart.component.scss']
})

export class AppPieChartComponent implements OnInit {
  @Input() data: PieChartData[] = [];
  @Input() chartId: string = "";
  @Input() title: string = "";

  chart: Chart | undefined;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    Chart.register(...registerables);

    const data = {
      labels: [
        ''
      ],
      datasets: [{
        data: [0],
        backgroundColor: [
          ''
        ],
        hoverOffset: 4,
        cutout: '80%'
      }],
    };

    data.labels = [];
    data.datasets[0].data = [];
    data.datasets[0].backgroundColor = [];

    this.data.forEach(d => {
      data.labels.push(d.label);
      data.datasets[0].data.push(d.data);
      data.datasets[0].backgroundColor.push(d.backgroundColor);
    })

    this.chart = new Chart(this.chartId, {
      type: 'doughnut',
      data: data,
      options: {
        maintainAspectRatio: false,
        responsive: true,
        layout: {
          padding: {
            top: 5
          }
        },
        elements: {
          arc: {
            borderWidth: 0,
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              color: '#00304A',
              padding: 20
            },
            fullSize: true
          },
          title: {
            text: this.title,
            align: 'start',
            display: false,
            font: {
              size: 14,
              weight: 'bold',
            },
            color: '#00304A',
            padding: {
              top: 50
            }
          },
        }
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  resizeChart() {
    this.chart?.resize();
  }
}
