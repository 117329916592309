import { Component, Inject, OnInit } from '@angular/core';
import { Filter } from '../after-search.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { CarSearchBody } from 'src/app/core/models/car.model';

interface SaveSearchModalData {
  filters: Filter[],
  search: CarSearchBody
}

@Component({
  selector: 'app-save-search-modal',
  templateUrl: './save-search-modal.component.html',
  styleUrls: ['./save-search-modal.component.scss']
})
export class SaveSearchModalComponent implements OnInit {
  filters = this.data.filters;
  groupedFilters: { bodytype: any } | undefined;

  nameControl = new FormControl('', Validators.required);

  constructor(public dialogRef: MatDialogRef<SaveSearchModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SaveSearchModalData,
    private snackbar: SnackbarService) { }

  ngOnInit(): void {
    let result = this.filters.reduce(function (r, a) {
      r[a.group!] = r[a.group!] || [];
      r[a.group!].push({
        value: a.extraValue ? a.extraValue : a.value,
        groupValue: a.groupValue
      })
      return r;
    }, Object.create(null));

    this.groupedFilters = result;
  }

  cancel() {
    this.dialogRef.close();
  }

  getValues(filters: Filter[]) {
    return filters.map(f => f.value).join(', ');
  }

  saveSearch() {
    if (this.nameControl.invalid) {
      this.snackbar.negativeSentiment('No name inserted');
      return;
    }


    let saveSearchesString = localStorage.getItem('saved-searches');

    if (saveSearchesString) {
      let saveSearches = JSON.parse(saveSearchesString);

      if (saveSearches.find((s: { name: string; }) => s.name === this.nameControl.value!)) {
        this.snackbar.negativeSentiment('A search with this name already exists');
        return;
      }

      saveSearches.push({
        name: this.nameControl.value!,
        searchBody: this.data.search,
        createdAt: new Date()
      });

      localStorage.setItem('saved-searches', JSON.stringify(saveSearches));

      this.snackbar.positiveSentiment('Search saved');
      this.dialogRef.close();
    } else {
      let search = {
        name: this.nameControl.value!,
        searchBody: this.data.search,
        createdAt: new Date()
      };

      localStorage.setItem('saved-searches', JSON.stringify([search]));

      this.snackbar.positiveSentiment('Search saved');
      this.dialogRef.close();
    }
  }
}
