import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { BusinessCase, BusinessCaseBody, BusinessCaseResponse, Country, TransportMatrix } from "../models/utils.model";

@Injectable({
  providedIn: 'root'
})

export class InfoService {
  constructor(private http: HttpClient) {
  }

  public getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(`${environment.backendApiUrl}countries`);
  }

  public getTransportMatrix(): Observable<TransportMatrix[]> {
    return this.http.get<TransportMatrix[]>(`${environment.backendApiUrl}transport-matrixes`);
  }

  public getCarBusinessCar(body: BusinessCaseBody): Observable<BusinessCase[]> {
    return this.http.post<BusinessCase[]>(`${environment.backendApiUrl}business-case`, body);
  }
}
