import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-datepicker',
  templateUrl: './app-datepicker.component.html',
  styleUrls: ['./app-datepicker.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AppDatepickerComponent implements OnInit {
  @Input() control: FormControl = new FormControl();
  @Input() min: Date | null | undefined;
  @Input() max: Date | null | undefined;
  @Input() placeholder: string = 'dd/MM/yyyy';
  @Input() label: string | undefined;
  @Input() componentId = 'id';

  @Output() dateChangeEvent: EventEmitter<Date | null> = new EventEmitter<Date | null>();

  date = new FormControl(moment());

  constructor(private readonly adapter: DateAdapter<Date>) {
  }

  ngOnInit() {
  }

  dateChange(event: MatDatepickerInputEvent<Date>) {
    this.dateChangeEvent.emit(event.value)
  }
}
