import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-blue-btn',
  templateUrl: './blue-btn.component.html',
  styleUrls: ['./blue-btn.component.scss']
})
export class BlueBtnComponent implements OnInit {
  @Input() btnText: string | undefined;
  @Input() id = 'id';
  @Input() orangeBtn = false;

  @Output() clickEmitter: EventEmitter<MouseEvent> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  emitClick(event: MouseEvent) {
    this.clickEmitter.emit(event);
  }
}
