import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface ImageFullScreenData {
  photos: string[]
}

@Component({
  selector: 'app-image-full-screen',
  templateUrl: './image-full-screen.component.html',
  styleUrls: ['./image-full-screen.component.scss']
})

export class ImageFullScreenComponent implements OnInit {
  photos = this.data.photos;
  selectedPhoto = this.photos[0];

  constructor(public dialogRef: MatDialogRef<ImageFullScreenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageFullScreenData) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  next() {
    let i = this.photos.indexOf(this.selectedPhoto);

    this.selectedPhoto = (i === this.photos.length - 1) ? this.photos[0] : this.photos[++i]
  }

  previous() {
    let i = this.photos.indexOf(this.selectedPhoto);

    this.selectedPhoto = (i === 0) ? this.photos[this.photos.length - 1] : this.photos[--i];
  }

  getIndex(): number {
    return this.photos.indexOf(this.selectedPhoto) + 1;
  }
}
