import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Offer } from "../models/offer.model";
import { UserInfo, WishList } from "../models/user.model";
import { CarDetails } from "../models/car.model";

@Injectable({
  providedIn: 'root'
})

export class UserService {
  carFavorites: string[] = [];

  constructor(private http: HttpClient) { }

  ngOnInit() { }

  public getUserInfo(): Observable<UserInfo> {
    return this.http.get<UserInfo>(`${environment.backendApiUrl}user-info`);
  }

  public getUserFavoritesCars(): Observable<WishList> {
    return this.http.get<WishList>(`${environment.backendApiUrl}get-wish-list`);
  }

  public removeFavoritesCar(carId: string): Observable<any> {
    return this.http.delete<any>(`${environment.backendApiUrl}remove-from-wish-list`, { body: { carMainInfoId: carId } });
  }

  public getOffers(): Observable<Offer[]> {
    return this.http.get<Offer[]>(`${environment.backendApiUrl}get-offers`);
  }
}
