import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketingDataPropositionComponent } from './marketing-data-proposition.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MarketDataBannerComponent } from './market-data-banner/market-data-banner.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    MarketingDataPropositionComponent,
    MarketDataBannerComponent
  ]
})

export class MarketingDataPropositionModule { }
