<app-my-profile-page-layout [profilePage]="'searches'">
  <div class="flex flex-col gap-6">
    <app-main-content-layout *ngFor="let search of savedSearches">
      <div class="flex flex-col sm:flex-row gap-6 justify-between">
        <div class="flex flex-col xs:flex-row gap-4 items-center">
          <p class="font-bold">{{search.name}}</p>
          <p class="text-white-greyText text-sm">Saved on {{search.createdAt | date: 'dd-MM-yyyy'}}</p>
        </div>

        <div class="flex flex-col xs:flex-row gap-4 items-center">
          <fa-icon [id]="'remove'+ search.name +'-btn'" [attr.data-cy]="'remove'+ search.name +'-btn'"
            class="fa-icon-div cursor-pointer text-blue-headerBg hover:bg-white-hoverGrey" [icon]="['fas','trash-can']"
            (click)="deleteSearch(search)"></fa-icon>

          <app-white-btn [id]="search.name + '-redirect'" [variant]="4" (clickEmitter)="searchShop(search.searchBody)">Show
            results</app-white-btn>
        </div>
      </div>
    </app-main-content-layout>
  </div>

</app-my-profile-page-layout>
