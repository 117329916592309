import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-page-ongoing-proccesses-panel',
  templateUrl: './profile-page-ongoing-proccesses-panel.component.html',
  styleUrls: ['./profile-page-ongoing-proccesses-panel.component.scss']
})
export class ProfilePageOngoingProccessesPanelComponent implements OnInit {
  isMobile = false;

  constructor() { }

  ngOnInit(): void {
    this.isMobile = window.innerWidth < 1024;

    window.onresize = () => this.isMobile = window.innerWidth < 1024;
  }

}
