import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface TabOption {
  value: string,
  viewValue: string
}

@Component({
  selector: 'app-tabs',
  templateUrl: './app-tabs.component.html',
  styleUrls: ['./app-tabs.component.scss']
})
export class AppTabsComponent implements OnInit {
  @Input() tabs: TabOption[] = [];
  @Input() tabSelected = '';
  @Input() disabled: boolean | null = false;

  @Output() clickEmitter: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  changeTab(tab: string) {
    if (this.disabled) return;

    this.clickEmitter.emit(tab);
  }
}
