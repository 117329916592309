import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CoreService } from 'src/app/core/services/core.service';
import { ShopService } from 'src/app/core/services/shop.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { Filter, FiltersList } from '../after-search/after-search.component';
import bodytypes from 'src/assets/json-files/bodytypes.json';
import { MatDialog } from '@angular/material/dialog';
import { AllFiltersModalComponent } from './all-filters-modal/all-filters-modal.component';
import { BehaviorSubject, debounceTime, forkJoin, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);

  private filtersStringEvent: Subject<string> = new Subject();
  public filtersStringEvent$ = this.filtersStringEvent.asObservable();
  public destroyed = new Subject<void>();

  results: number = 0;

  filters: FiltersList = { fuelType: [], gearbox: [], carGroup: [] };
  filtersSelected: Filter[] = [];

  mileageFromControl = new FormControl();
  mileageToControl = new FormControl();

  enginePowFromControl = new FormControl();
  enginePowToControl = new FormControl();

  priceFromControl = new FormControl();
  priceToControl = new FormControl();

  co2FromControl = new FormControl();
  co2ToControl = new FormControl();

  makes: DropdownOption[] = [];
  models: DropdownOption[] = [];
  variants: DropdownOption[] = [];

  years: DropdownOption[] = Array.from({ length: (new Date().getFullYear() - 2000) }, (_, i) => i + 2001).reverse().map(y => ({ value: y.toString(), viewValue: y.toString() }));
  yearsFrom: DropdownOption[] = [];
  yearsTo: DropdownOption[] = [];

  manufactureYears = this.fb.group({
    from: new FormControl(),
    until: new FormControl()
  })

  modelControl = new FormControl();
  makeControl = new FormControl();

  makeQuery = '';
  modelQuery = '';
  manufactureYearQuery = '';
  mileageQuery = '';
  enginePowQuery = '';
  priceQuery = '';
  co2Query = '';

  bodytypes = bodytypes.map(b => ({ ...b, isChecked: false }));

  countries: DropdownOption[] = [];
  countryControl = new FormControl();

  constructor(private fb: FormBuilder,
    private router: Router,
    private coreService: CoreService,
    private shopService: ShopService,
    private dialog: MatDialog) { }

  async ngOnInit() {
    this.countries = (await this.coreService.getCountries()).map(c => ({ value: c.id, viewValue: c.name }));
    this.makes = await this.coreService.getMakes();

    this.yearsFrom = this.years;
    this.yearsTo = this.years;

    this.loadInfo();

    this.filtersStringEvent$.pipe(debounceTime(1000),
      tap(() => { }),
      takeUntil(this.destroyed)).subscribe(resp => {
        let query = this.buildSearchQuery();
      });

    this.enginePowFromControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      setTimeout(() => {
        this.enginePowQuery = this.setFromInputFilters(value, 'enginePower', 'Engine power', this.enginePowFromControl, this.enginePowToControl, 'hp');

        this.filtersStringEvent.next(this.enginePowQuery);
      })
    });

    this.enginePowToControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      setTimeout(() => {
        this.enginePowQuery = this.setToInputFilters(value, 'enginePower', 'Engine power', this.enginePowFromControl, this.enginePowToControl, 'hp');

        this.filtersStringEvent.next(this.enginePowQuery);
      })
    });

    this.mileageFromControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      setTimeout(() => {
        this.mileageQuery = this.setFromInputFilters(value, 'mileage', 'Mileage', this.mileageFromControl, this.mileageToControl, 'km');

        this.filtersStringEvent.next(this.mileageQuery);
      })
    });

    this.mileageToControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      setTimeout(() => {
        this.mileageQuery = this.setToInputFilters(value, 'mileage', 'Mileage', this.mileageFromControl, this.mileageToControl, 'km');

        this.filtersStringEvent.next(this.mileageQuery);
      })
    });

    this.priceFromControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      setTimeout(() => {
        this.priceQuery = this.setFromInputFilters(value, 'sellingPrice', 'Price', this.priceFromControl, this.priceToControl, '€');

        this.filtersStringEvent.next(this.priceQuery);
      })
    });

    this.priceToControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      setTimeout(() => {
        this.priceQuery = this.setToInputFilters(value, 'sellingPrice', 'Price', this.priceFromControl, this.priceToControl, '€');

        this.filtersStringEvent.next(this.priceQuery);
      })
    });

    this.co2FromControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      setTimeout(() => {
        this.co2Query = this.setFromInputFilters(value, 'co2WLTP', 'CO2', this.co2FromControl, this.co2ToControl, 'g/km');

        this.filtersStringEvent.next(this.co2Query);
      })
    });

    this.co2ToControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      setTimeout(() => {
        this.co2Query = this.setToInputFilters(value, 'co2WLTP', 'CO2', this.co2FromControl, this.co2ToControl, 'g/km');

        this.filtersStringEvent.next(this.co2Query);
      })
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  search() {
    let query = this.buildSearchQuery();

    // sessionStorage.setItem('searchQuery', query);

    this.router.navigate(['search']);
  }

  buildSearchQuery(): string {
    let query = "?";

    query = this.makeQuery.length > 0 ? `${query}${this.makeQuery}&` : query;
    query = this.modelQuery.length > 0 ? `${query}${this.modelQuery}&` : query;
    query = this.mileageQuery.length > 0 ? `${query}${this.mileageQuery}&` : query;
    query = this.enginePowQuery.length > 0 ? `${query}${this.enginePowQuery}&` : query;
    query = this.manufactureYearQuery.length > 0 ? `${query}${this.manufactureYearQuery}&` : query;
    query = this.priceQuery.length > 0 ? `${query}${this.priceQuery}&` : query;
    query = this.co2Query.length > 0 ? `${query}${this.co2Query}&` : query;

    let result = this.filtersSelected.reduce(function (r, a) {
      r[a.groupValue] = r[a.groupValue] || [];
      r[a.groupValue].push(a.value);
      return r;
    }, Object.create(null));

    Object.getOwnPropertyNames(result).forEach((val, idx, array) => {
      if (['make', 'model', 'mileage', 'manufactureYear', 'enginePower', 'sellingPrice', 'co2WLTP'].includes(val)) return;

      query = `${query}${val}=${result[val].join()}&`;
    });

    query = query.slice(0, -1);

    return query;
  }

  selectMake(make: string) {
    let index = this.filtersSelected.findIndex(f => f.groupValue === 'make');

    if (make && index >= 0) {
      this.filtersSelected[index].value = make;
    } else if (make && index < 0) {
      this.filtersSelected.push({
        groupValue: 'make',
        value: make,
        group: 'Make',
        isChecked: true
      });
    } else if (!make && index >= 0) {
      this.filtersSelected.splice(index);
    }

    this.makeQuery = make ? `make=${make}` : '';
    this.modelQuery = '';

    this.modelControl.reset();
    this.models = [];

    if (make) {
      this.loadModels(make);
    }

    this.filtersStringEvent.next(this.makeQuery);
  }

  selectModel(model: string) {
    let index = this.filtersSelected.findIndex(f => f.groupValue === 'model');

    if (model && index >= 0) {
      this.filtersSelected[index].value = model;
    } else if (model && index < 0) {
      this.filtersSelected.push({
        groupValue: 'model',
        group: 'Model',
        value: model,
        isChecked: true
      })
    } else if (!model && index >= 0) {
      this.filtersSelected.splice(index);
    }

    this.modelQuery = model ? `model=${model}` : '';

    this.filtersStringEvent.next(this.modelQuery);
  }

  selectCountry(countryId: string) {
    let filter = this.filtersSelected.find(f => f.groupValue === 'country');

    if (!countryId && filter) {
      this.filtersSelected.splice(this.filtersSelected.findIndex(f => f.groupValue === filter?.groupValue && f.value === filter.value));
    } else if (filter) {
      filter.extraValue = this.countries.find(c => c.value === countryId)?.viewValue
      filter.value = countryId;
    } else {
      this.filtersSelected.push({
        value: countryId,
        groupValue: 'country',
        group: 'Country',
        extraValue: this.countries.find(c => c.value === countryId)?.viewValue
      })
    }

    this.filtersStringEvent.next('country');
  }

  loadInfo(query?: string) {
    let searchQuery = query ? query : '';

    forkJoin({
      gearboxes: this.coreService.getGearboxes(),
      fuelTypes: this.coreService.getFuelTypes()
    }).subscribe(resp => {
      this.filters.fuelType = resp.fuelTypes.map(f => ({ groupValue: 'fuelType', group: 'Fuel Type', value: f.value, noOfCars: 0, isChecked: false }));
      this.filters.gearbox = resp.gearboxes.map(f => ({ groupValue: 'gearbox', group: 'Gearbox', value: f.value, noOfCars: 0, isChecked: false }));

      this.loading.next(false);
    })
  }

  changeFilter(filter: Filter) {
    this.filtersSelected.includes(filter) ? this.removeFilter(filter) : this.addFilter(filter);
  }

  addFilter(filter: Filter) {
    this.filtersSelected.push(filter);

    this.filtersStringEvent.next('filter');
  }

  removeFilter(filter: Filter) {
    this.filtersSelected.splice(this.filtersSelected.indexOf(filter), 1);

    if (this.filters[filter.groupValue as keyof typeof this.filters]) {
      this.filters[filter.groupValue as keyof typeof this.filters]!.find(f => f.value === filter.value)!.isChecked = false;
    }

    if (filter.groupValue === 'country') {
      this.countryControl.reset();
    }

    if (filter.groupValue === 'bodyType') {
      this.bodytypes.find(b => b.value === filter.value)!.isChecked = false;
    }

    if (filter.groupValue === 'mileage' && filter.value === 'from') {
      this.mileageFromControl.reset();
    } else if (filter.groupValue === 'mileage' && filter.value === 'until') {
      this.mileageToControl.reset();
    }

    if (filter.groupValue === 'enginePower' && filter.value === 'from') {
      this.enginePowFromControl.reset();
    } else if (filter.groupValue === 'enginePower' && filter.value === 'until') {
      this.enginePowToControl.reset();
    }

    if (filter.groupValue === 'sellingPrice' && filter.value === 'from') {
      this.priceFromControl.reset();
    } else if (filter.groupValue === 'sellingPrice' && filter.value === 'until') {
      this.priceToControl.reset();
    }

    if (filter.groupValue === 'co2WLTP' && filter.value === 'from') {
      this.co2FromControl.reset();
    } else if (filter.groupValue === 'co2WLTP' && filter.value === 'until') {
      this.co2ToControl.reset();
    }

    if (filter.groupValue === 'make') {
      this.modelControl.reset();
      this.models = [];
      this.makeControl.reset();

      this.makeQuery = '';
      this.modelQuery = '';

      this.filtersSelected.splice(this.filtersSelected.findIndex(f => f.groupValue === 'model'), 1);
    }

    if (filter.groupValue === 'model') {
      this.modelQuery = '';
      this.modelControl.reset();
    }

    this.filtersStringEvent.next('filter');
  }

  loadModels(make: string) {
    this.coreService.showProgressBar.next(true);

    this.shopService.getCarNomenclatorData(`models?make=${make}`).subscribe(resp => {
      this.models = resp.map(r => ({ value: r, viewValue: r }));

      this.coreService.showProgressBar.next(false);
    });
  }

  selectManYear(year: string, yearType: string) {
    let query = '';
    let yearFrom = this.manufactureYears.controls.from.value;
    let yearTo = this.manufactureYears.controls.until.value;

    if (!year) {
      this.filtersSelected.splice(this.filtersSelected.findIndex(f => f.group === `Manufacture Year ${yearType}`), 1);

      if (yearType === 'from' && yearTo) {
        this.yearsTo = this.years;

        this.filterYearArray('from', yearTo);
      } else if (yearType === 'until' && yearFrom) {
        this.yearsFrom = this.years;

        this.filterYearArray('until', yearFrom);
      } else {
        this.yearsTo = this.years;
        this.yearsFrom = this.years;
      }

      this.manufactureYears.controls[yearType as keyof typeof this.manufactureYears.controls].reset();
    } else {
      let filter = this.filtersSelected.find(f => f.group === `Manufacture Year ${yearType}`);

      if (filter) {
        filter.value = year;
      } else {
        this.filtersSelected.push({ groupValue: 'manufactureYear', group: `Manufacture Year ${yearType}`, value: year, isChecked: false });
      }
    }

    if (yearType === 'from' && year) {
      this.filterYearArray('until', year);

      query = `manufactureYear=${year},${yearTo ? yearTo : this.years[0].value}`;
    } else if (yearType === 'from' && yearTo) {
      query = `manufactureYear=2000,${yearTo}`;
    }

    if (yearType === 'until' && year) {
      this.filterYearArray('from', year);

      query = `manufactureYear=${yearFrom ? yearFrom : '2000'},${year}`;
    } else if (yearType === 'until' && yearFrom) {
      query = `manufactureYear=${yearFrom},${this.years[0].value}`;
    }

    this.manufactureYearQuery = query;

    this.filtersStringEvent.next(query);
  }

  filterYearArray(yearType: string, complementYear: string) {
    if (yearType === 'until') {
      this.yearsTo = this.years.slice(0, this.years.findIndex(y => y.value === complementYear) + 1);
    } else {
      this.yearsFrom = this.years.slice(this.years.findIndex(y => y.value === complementYear));
    }
  }

  clearFilters() {
    this.filtersSelected = [];

    this.filters.fuelType = this.filters.fuelType.map(f => ({ ...f, isChecked: false }));
    this.filters.gearbox = this.filters.gearbox.map(f => ({ ...f, isChecked: false }));
    this.bodytypes = this.bodytypes.map(f => ({ ...f, isChecked: false }));

    this.yearsFrom = this.years;
    this.yearsTo = this.years;

    this.countryControl.reset();

    this.makeControl.reset()
    this.modelControl.reset();
    this.models = [];
    this.makeQuery = '';
    this.modelQuery = '';

    this.manufactureYears.reset();

    this.enginePowFromControl.reset()
    this.enginePowToControl.reset()

    this.mileageFromControl.reset()
    this.mileageToControl.reset()

    this.priceFromControl.reset();
    this.priceToControl.reset();

    this.co2FromControl.reset();
    this.co2ToControl.reset();
  }

  openAllFiltersModal() {
    const dialogRef = this.dialog.open(
      AllFiltersModalComponent, {
      width: '650px',
      autoFocus: false,
      data: {
        filters: this.filtersSelected
      },
      panelClass: 'modal-bg-black',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp && resp.filters && resp.filters.length === 0) {
        this.clearFilters();
      } else if (resp) {
        this.filtersSelected = resp.filters;
        this.updateFilters(resp.filtersToDelete);
      }
    })
  }

  updateFilters(filtersToDelete: Filter[]) {
    filtersToDelete.forEach(f => {
      switch (f.groupValue) {
        case 'bodyType':
          this.bodytypes.find(b => b.value === f.value)!.isChecked = false;
          break;
        case 'fuelType':
          this.filters.fuelType.find(fl => fl.value === f.value)!.isChecked = false;
          break;
        case 'gearbox':
          this.filters.gearbox.find(g => g.value === f.value)!.isChecked = false;
          break;
        case 'mileage':
          if (f.value === 'from') {
            this.mileageFromControl.reset();
          } else {
            this.mileageToControl.reset();
          }

          break;
        case 'enginePower':
          if (f.value === 'from') {
            this.enginePowFromControl.reset();
          } else {
            this.enginePowToControl.reset();
          }

          break;
        case 'sellingPrice':
          if (f.value === 'from') {
            this.priceFromControl.reset();
          } else {
            this.priceToControl.reset();
          }

          break;
        case 'co2WLTP':
          if (f.value === 'from') {
            this.co2FromControl.reset();
          } else {
            this.co2ToControl.reset();
          }

          break;
        case 'model':
          this.modelControl.reset();
          break;
        case 'make':
          this.modelControl.reset();
          this.models = [];
          this.makeControl.reset();

          break;
        case 'manufactureYear':
          if (f.group!.match('from')) {
            this.manufactureYears.controls.from.reset();
            this.yearsFrom = this.years;
            this.yearsTo = this.years;
          } else {
            this.manufactureYears.controls.until.reset();
            this.yearsFrom = this.years;
            this.yearsTo = this.years;
          }
          break;
        case 'country':
          this.countryControl.reset();
          break;
        default:
          break;
      }
    });

    if (!this.manufactureYears.controls.from.value && !this.manufactureYears.controls.until.value) {
      this.manufactureYearQuery = '';
    } else {
      this.manufactureYearQuery = `manufactureYear=${this.manufactureYears.controls.from.value ? this.manufactureYears.controls.from.value : this.years[0].value},${this.manufactureYears.controls.until.value ? this.manufactureYears.controls.until.value : this.years[this.years.length - 1].value}`;
    }

    if (!this.makeControl.value) this.makeQuery = '';

    if (!this.modelControl.value) this.modelQuery = '';

    this.filtersStringEvent.next('filter');
  }

  checkBodytype(event: boolean, body: string) {
    if (event) {
      this.filtersSelected.push({
        groupValue: 'bodyType',
        group: 'Bodytype',
        value: body,
      })
    } else {
      this.filtersSelected.splice(this.filtersSelected.findIndex(f => f.value === body), 1);
    }

    this.filtersStringEvent.next('filter');
  }

  setToInputFilters(value: number, groupValue: string, group: string, controlFrom: FormControl, controlTo: FormControl, unit: string): string {
    let query = '';

    if (value && controlFrom.value) {
      if (value < controlFrom.value) {
        controlTo.setValue(controlFrom.value);
        controlFrom.setValue(value);
      }

      query = `${groupValue}=${controlFrom.value},${controlTo.value}`;
    } else if (value) {
      query = `${groupValue}=0,${value}`;
    } else if (controlFrom.value) {
      query = `${groupValue}=${controlFrom.value},999999`;
    } else {
      query = ``;
    }

    this.syncInputFilters(groupValue, group, controlFrom, controlTo, unit);

    return query;
  }

  setFromInputFilters(value: number, groupValue: string, group: string, controlFrom: FormControl, controlTo: FormControl, unit: string): string {
    let query = '';

    if (value && controlTo.value) {
      if (value > controlTo.value) {
        controlFrom.setValue(controlTo.value);
        controlTo.setValue(value);
      }
      query = `${groupValue}=${controlFrom.value},${controlTo.value}`;
    } else if (value) {
      query = `${groupValue}=${value},999999`;
    } else if (controlTo.value) {
      query = `${groupValue}=0,${controlTo.value}`;
    } else {
      query = ``;
    }

    this.syncInputFilters(groupValue, group, controlFrom, controlTo, unit);

    return query;
  }

  syncInputFilters(groupValue: string, group: string, controlFrom: FormControl, controlTo: FormControl, unit: string) {
    let inputFrom = this.filtersSelected.find(f => f.groupValue === groupValue && f.value === 'from');
    let inputTo = this.filtersSelected.find(f => f.groupValue === groupValue && f.value === 'until');

    if (controlFrom.value && inputFrom) {
      inputFrom.extraValue = `From ${controlFrom.value.toLocaleString()} ${unit}`;
    } else if (controlFrom.value) {
      this.filtersSelected.push({ groupValue: groupValue, group: group, value: 'from', extraValue: `From ${controlFrom.value.toLocaleString()} ${unit}` })
    } else if (inputFrom) {
      this.filtersSelected.splice(this.filtersSelected.findIndex(f => f.groupValue === groupValue && f.value === 'from'), 1);
    }

    if (controlTo.value && inputTo) {
      inputTo.extraValue = `Until ${controlTo.value.toLocaleString()} ${unit}`;
    } else if (controlTo.value) {
      this.filtersSelected.push({ groupValue: groupValue, group: group, value: 'until', extraValue: `Until ${controlTo.value.toLocaleString()} ${unit}` })
    } else if (inputTo) {
      this.filtersSelected.splice(this.filtersSelected.findIndex(f => f.groupValue === groupValue && f.value === 'until'), 1);
    }
  }
}
