import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CarDetails } from 'src/app/core/models/car.model';
import { ShopService } from 'src/app/core/services/shop.service';
import { UserService } from 'src/app/core/services/user.service';
import { VAT } from '../car-shop-card/car-shop-card.component';

@Component({
  selector: 'app-car-suggestion-card',
  templateUrl: './car-suggestion-card.component.html',
  styleUrls: ['./car-suggestion-card.component.scss']
})
export class CarSuggestionCardComponent implements OnInit {
  @Input() car: CarDetails | undefined;

  imgUrl: string = '';
  isFav = false;

  vatStatus = VAT;

  constructor(private router: Router,
    private userService: UserService,
    private shopService: ShopService) { }

  ngOnInit(): void {
    this.imgUrl = this.car?.profilePhoto && this.car?.profilePhoto != 'None' ? this.car.profilePhoto : './assets/photos/car-placeholder.png';

    if (this.userService.carFavorites.find(c => c === this.car?.carMainInfoId)) {
      this.isFav = true;
    }
  }

  goToCar() {
    this.router.navigate([`car/${this.car!.carMainInfoId}`]);
  }

  addToFav() {
    if (this.isFav) {
      this.userService.carFavorites.splice(this.userService.carFavorites.findIndex(c => c === this.car?.carMainInfoId), 1);

      this.userService.removeFavoritesCar(this.car?.carMainInfoId!).subscribe(resp => {
      })
    } else {
      this.userService.carFavorites.push(this.car?.carMainInfoId!);

      this.shopService.addCarToFavorites(this.car!.carMainInfoId!).subscribe(resp => {
      })
    }

    this.isFav = !this.isFav;
  }
}
