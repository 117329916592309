import { Component, OnInit } from '@angular/core';
import { CarDetails } from 'src/app/core/models/car.model';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { Router } from '@angular/router';
import { CoreService } from 'src/app/core/services/core.service';
import { FormControl } from '@angular/forms';
import { ShopService } from 'src/app/core/services/shop.service';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { CarService } from 'src/app/core/services/car.service';
import { UserService } from 'src/app/core/services/user.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-marketing-data-proposition',
  templateUrl: './marketing-data-proposition.component.html',
  styleUrls: ['./marketing-data-proposition.component.scss']
})
export class MarketingDataPropositionComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);
  carsLoading = new BehaviorSubject<boolean>(true);

  cars: CarDetails[] = [];

  makes: DropdownOption[] = [];
  models: DropdownOption[] = [];
  variants: DropdownOption[] = [];
  years: DropdownOption[] = Array.from({ length: (new Date().getFullYear() - 2000) }, (_, i) => i + 2001).reverse().map(y => ({ value: y.toString(), viewValue: y.toString() }));
  mileages: DropdownOption[] = [];
  countries: DropdownOption[] = [];

  variantControl = new FormControl();
  modelControl = new FormControl();
  makeControl = new FormControl();
  mileageControl = new FormControl();
  yearControl = new FormControl();
  priceControl = new FormControl();
  countryControl = new FormControl();

  constructor(private router: Router,
    private coreService: CoreService,
    private shopService: ShopService,
    private carService: CarService,
    private userService: UserService,
    private utilsService: UtilsService) {
  }

  async ngOnInit() {
    let kms = [5000, 10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 125000, 150000, 200000];

    this.mileages = kms.map(km => ({ viewValue: `${(km).toLocaleString()} km`, value: km.toString() }));

    forkJoin({
      cars: this.carService.getLatestCarAdded(),
      makes: this.coreService.getMakes(),
      countries: this.coreService.getCountries(),
      transportMatrix: this.coreService.getTransportMatrix(),
      userInfo: this.coreService.checkIfUserInfo()
    }).subscribe(resp => {
      let store = this.coreService.userInfo?.buyerStores[0];

      this.cars = this.utilsService.putTransportFee(resp.cars, store!.country.id, resp.transportMatrix);


      this.makes = resp.makes;
      this.countries = resp.countries.map(c => ({ value: c.id, viewValue: c.name }));

      this.loading.next(false)
    })
  }

  goToAdvancedSearch() {
    this.router.navigate(['advanced-search']);
  }

  selectMake(make: string) {
    this.modelControl.reset();
    this.models = [];

    if (make) {
      this.coreService.showProgressBar.next(true);

      this.shopService.getCarNomenclatorData(`models?make=${make}`).subscribe(resp => {
        this.models = resp.map(r => ({ value: r, viewValue: r }));

        this.coreService.showProgressBar.next(false);
      });
    }
  }

  selectModel(model: string) {
    this.variantControl.reset();
    this.variants = [];

    if (model) {
      this.coreService.showProgressBar.next(true);

      this.shopService.getCarNomenclatorData(`variants?make=${this.makeControl.value}&model=${model}`).subscribe(resp => {
        this.variants = resp.map(r => ({ value: r, viewValue: r }));

        this.variants.unshift({ viewValue: 'All Variants', value: 'all' });

        this.coreService.showProgressBar.next(false);
      });
    }
  }

  search() {
    let query = '?';

    query = this.makeControl.value ? `${query}make=${this.makeControl.value}&` : query;
    query = this.modelControl.value ? `${query}model=${this.modelControl.value}&` : query;
    query = this.yearControl.value ? `${query}manufactureYear=1990,${this.yearControl.value}&` : query;
    query = this.mileageControl.value ? `${query}mileage=0,${this.mileageControl.value}&` : query;
    query = this.priceControl.value ? `${query}sellingPrice=0,${this.priceControl.value}&` : query;
    query = this.countryControl.value ? `${query}country=${this.countryControl.value}&` : query;

    query = query.slice(0, -1);

    // sessionStorage.setItem('searchQuery', query);

    this.router.navigate(['search']);
  }

}
