<app-nav-bar-after-login tab="shop"></app-nav-bar-after-login>
<div class="bg-white-bgGrey">
  <div class="container py-7 min-h-[80vh]" *ngIf="!(loading | async); else placeholder">
    <app-market-data-banner></app-market-data-banner>

    <div class="grid lg:grid-cols-3 md:gap-4 xl:gap-8">
      <app-main-content-layout class="col-span-1">
        <div class="flex flex-col">
          <p class="text-2xl mb-12">Models and Variants</p>

          <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-9 lg:grid-cols-1">
            <app-dropdown id="country" label="Country" [options]="countries" placeholder="Select country"
              [control]="countryControl" [resetSelection]="true"></app-dropdown>

            <app-dropdown id="city" placeholder="Select make" label="Make" [options]="makes"
              (emitOnChangeSelect)="selectMake($event)" [control]="makeControl" [resetSelection]="true"></app-dropdown>

            <app-dropdown id="model" label="Model" placeholder="Select model" [options]="models"
              (emitOnChangeSelect)="selectModel($event)" [control]="modelControl"
              [resetSelection]="true"></app-dropdown>

            <app-dropdown id="mileage" label="Mileage up to" placeholder="Select mileage up to" [options]="mileages"
              [control]="mileageControl" [resetSelection]="true">
            </app-dropdown>

            <app-dropdown id="manufacture-year" label="Manufacture Year up to"
              placeholder="Select manufacture year up to" [options]="years" [control]="yearControl" [resetSelection]="true">
            </app-dropdown>

            <app-input id="price" [numberInput]="true" label="Price up to" placeholder="Select price up to"
              [control]="priceControl"></app-input>

            <div class="flex flex-col gap-3 sm:col-span-2 sm:flex-row lg:flex-col lg:col-span-1">
              <app-blue-btn id="search" (clickEmitter)="search()">SEARCH</app-blue-btn>
              <app-white-btn id="advanced-search" [variant]="1" (clickEmitter)="goToAdvancedSearch()">Advanced
                Search</app-white-btn>
            </div>
          </div>
        </div>
      </app-main-content-layout>

      <div class="col-span-1 lg:col-span-2 h-min flex flex-col gap-6">
        <p class="font-bold mt-6 md:mt-0">Latest Cars Added</p>

        <app-car-shop-card *ngFor="let car of cars" [car]="car"></app-car-shop-card>
      </div>
    </div>
  </div>
</div>

<ng-template #placeholder>
  <div class="container py-7 grid lg:grid-cols-3 md:gap-4 xl:gap-8">
    <app-main-content-layout class="lg:col-span-3">
      <div class="placeholder-animation w-full h-6"></div>
    </app-main-content-layout>

    <div class="bg-white p-6 md:px-9 rounded w-full h-fit flex flex-col gap-6">
      <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(10)"></div>
    </div>

    <app-main-content-layout class="col-span-1 lg:col-span-2 flex flex-col gap-6">
      <app-car-shop-card-placeholder *ngFor="let i of [].constructor(4)"></app-car-shop-card-placeholder>
    </app-main-content-layout>
  </div>
</ng-template>

<app-footer-after-login></app-footer-after-login>
