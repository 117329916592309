import { NgModule } from "@angular/core";
import { FooterComponent } from "./footer/footer.component";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppMaterialModule } from "./material.module";
import { AppInputComponent } from "./app-input/app-input.component";
import { SecondaryBtnComponent } from "./secondary-btn/secondary-btn.component";
import { PrimaryBtnComponent } from "./primary-btn/primary-btn.component";
import { MainContentLayoutComponent } from "./layouts/main-content-layout.components";
import { AppDropdownComponent } from "./app-dropdown/app-dropdown.component";
import { AppPieChartComponent } from "./app-pie-chart/app-pie-chart.component";
import { CarShopCardComponent } from "./car-shop-card/car-shop-card.component";
import { SharedFontAwesomeModule } from "./fontawesome.module";
import { AppCheckboxComponent } from "./app-checkbox/app-checkbox.component";
import { CarSuggestionCardComponent } from "./car-suggestion-card/car-suggestion-card.component";
import { AppImageSliderComponent } from "./app-image-slider/app-image-slider.component";
import { IvyCarouselModule } from "angular-responsive-carousel";
import { AppShopingCartCardComponent } from "./app-shoping-cart-card/app-shoping-cart-card.component";
import { ProfilePageNavbarComponent } from "./profile-page-navbar/profile-page-navbar.component";
import { MyProfilePageLayoutComponent } from "./layouts/my-profile-page-layout.component";
import { FaIconTextLayoutComponent } from "./layouts/fa-icon-text-layout.component";
import { MyProfileCarCardComponent } from "./my-profile-car-card/my-profile-car-card.component";
import { AppDropdownPanelComponent } from "./app-dropdown-panel/app-dropdown-panel.component";
import { BlueBtnComponent } from "./blue-btn/blue-btn.component";
import { GreenBtnComponent } from "./green-btn/green-btn.component";
import { WhiteBtnComponent } from './white-btn/white-btn.component';
import { BlackBtnComponent } from './black-btn/black-btn.component';
import { ProfilePageOngoingProccessesPanelComponent } from './profile-page-ongoing-proccesses-panel/profile-page-ongoing-proccesses-panel.component';
import { NavBarAfterLoginComponent } from './nav-bar-after-login/nav-bar-after-login.component';
import { FooterAfterLoginComponent } from './footer-after-login/footer-after-login.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { CarCardPlaceholderComponent } from "./layouts/car-card-placeholder.component";
import { ImageFullScreenComponent } from './app-image-slider/image-full-screen/image-full-screen.component';
import { CarShopCardPlaceholderComponent } from "./layouts/car-shop-card-paceholder.component";
import { AppTabsComponent } from "./app-tabs/app-tabs.component";
import { NoResultsPlaceholderComponent } from "./layouts/no-results-placeholder.component";
import { AppDatepickerComponent } from './app-datepicker/app-datepicker.component';
import { AppReadOnlyComponent } from './app-read-only/app-read-only.component';
import { EllipsisPipe } from "../core/pipelines/ellipsis.pipe";
import { AppToggleComponent } from './app-toggle/app-toggle.component';
import { AppToggleTwoWayComponent } from './app-toggle-two-way/app-toggle-two-way.component';
import { AppInputNumberComponent } from './app-input-number/app-input-number.component';
import { NumberCommaDirective } from "./app-input-number/number-comma.directive";
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ViewCarDetailsModalComponent } from "./view-car-details-modal/view-car-details-modal.component";

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    SharedFontAwesomeModule,
    IvyCarouselModule
  ],
  declarations: [
    FooterComponent,
    AppInputComponent,
    AppDropdownComponent,
    SecondaryBtnComponent,
    PrimaryBtnComponent,
    MainContentLayoutComponent,
    AppPieChartComponent,
    CarShopCardComponent,
    AppCheckboxComponent,
    CarSuggestionCardComponent,
    AppImageSliderComponent,
    AppShopingCartCardComponent,
    ProfilePageNavbarComponent,
    MyProfilePageLayoutComponent,
    FaIconTextLayoutComponent,
    MyProfileCarCardComponent,
    AppDropdownPanelComponent,
    BlueBtnComponent,
    GreenBtnComponent,
    WhiteBtnComponent,
    BlackBtnComponent,
    ProfilePageOngoingProccessesPanelComponent,
    NavBarAfterLoginComponent,
    FooterAfterLoginComponent,
    ProgressBarComponent,
    CarCardPlaceholderComponent,
    ImageFullScreenComponent,
    CarShopCardPlaceholderComponent,
    AppTabsComponent,
    NoResultsPlaceholderComponent,
    AppDatepickerComponent,
    AppReadOnlyComponent,
    EllipsisPipe,
    AppToggleComponent,
    AppToggleTwoWayComponent,
    AppInputNumberComponent,
    NumberCommaDirective,
    PageNotFoundComponent,
    ViewCarDetailsModalComponent
  ],
  exports: [
    AppMaterialModule,
    FooterComponent,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AppInputComponent,
    AppDropdownComponent,
    SecondaryBtnComponent,
    PrimaryBtnComponent,
    MainContentLayoutComponent,
    AppPieChartComponent,
    CarShopCardComponent,
    SharedFontAwesomeModule,
    AppCheckboxComponent,
    CarSuggestionCardComponent,
    AppImageSliderComponent,
    AppShopingCartCardComponent,
    ProfilePageNavbarComponent,
    MyProfilePageLayoutComponent,
    FaIconTextLayoutComponent,
    MyProfileCarCardComponent,
    AppDropdownPanelComponent,
    BlueBtnComponent,
    GreenBtnComponent,
    WhiteBtnComponent,
    BlackBtnComponent,
    ProfilePageOngoingProccessesPanelComponent,
    NavBarAfterLoginComponent,
    FooterAfterLoginComponent,
    ProgressBarComponent,
    CarCardPlaceholderComponent,
    CarShopCardPlaceholderComponent,
    AppTabsComponent,
    NoResultsPlaceholderComponent,
    AppDatepickerComponent,
    AppReadOnlyComponent,
    EllipsisPipe,
    AppToggleComponent,
    AppToggleTwoWayComponent,
    AppInputNumberComponent,
    PageNotFoundComponent
  ]
})

export class SharedModule { }
