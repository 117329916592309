import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CarSearchBody } from 'src/app/core/models/car.model';

export interface SavedSearch {
  name: string,
  searchBody: CarSearchBody,
  createdAt: Date
}
@Component({
  selector: 'app-saved-searches',
  templateUrl: './saved-searches.component.html',
  styleUrls: ['./saved-searches.component.scss']
})

export class SavedSearchesComponent implements OnInit {
  savedSearches: SavedSearch[] = [];

  constructor(private router: Router) { }

  ngOnInit() {
    let searches = localStorage.getItem('saved-searches');

    if (searches) {
      this.savedSearches = JSON.parse(searches);
    }
  }

  deleteSearch(search: SavedSearch) {
    this.savedSearches.splice(this.savedSearches.findIndex(s => s.name === search.name), 1);

    localStorage.setItem('saved-searches', JSON.stringify(this.savedSearches));
  }

  searchShop(searchBody: CarSearchBody) {
    sessionStorage.setItem('searchQuery', JSON.stringify(searchBody));

    this.router.navigate(['search']);
  }
}
