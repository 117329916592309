import { Injectable } from "@angular/core";
import { BehaviorSubject, first, firstValueFrom, zip } from "rxjs";
import { DropdownOption } from "src/app/shared/app-dropdown/app-dropdown.component";
import { BusinessCaseBody, BusinessCaseResponse, Country, TransportMatrix } from "../models/utils.model";
import { ShopService } from "./shop.service";
import { InfoService } from "./info.service";
import { UserInfo } from "../models/user.model";
import { UserService } from "./user.service";
import { CarDetails } from "../models/car.model";
import { UtilsService } from "./utils.service";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'root'
})

export class CoreService {
  private jwtHelperService = new JwtHelperService();

  showProgressBar = new BehaviorSubject<boolean>(false);

  makes: DropdownOption[] = [];
  fuelTypes: DropdownOption[] = [];
  gearboxes: DropdownOption[] = [];
  countries: Country[] = [];
  userInfo: UserInfo | undefined;
  transportMatrix: TransportMatrix[] = [];

  constructor(private shopService: ShopService,
    private userService: UserService,
    private infoService: InfoService,
    private utilsService: UtilsService) { }

  public transformObjectToArray(object: any) {
    let resultArray: { value: any; viewName: string; }[] = [];

    Object.getOwnPropertyNames(object).forEach((val, idx, array) => {
      resultArray.push({ value: object[val], viewName: val });
    });

    return resultArray;
  }

  public async getCountries() {
    if (this.countries.length > 0) {
      return this.countries;
    } else {
      let countriesResp = await firstValueFrom(this.infoService.getCountries());

      this.countries = countriesResp;

      return this.countries;
    }
  }

  public async getMakes() {
    if (this.makes.length > 0) {
      return this.makes;
    } else {
      let makeResp = await firstValueFrom(this.shopService.getCarNomenclatorData('makes'));

      this.makes = makeResp.map(m => ({ viewValue: m, value: m }));

      return this.makes;
    }
  }

  public async getFuelTypes() {
    if (this.fuelTypes.length > 0) {
      return this.fuelTypes;
    } else {
      let fuelTypesResp = await firstValueFrom(this.shopService.getCarSpecNomenclatorData('fuelTypes'));

      this.fuelTypes = fuelTypesResp.map(m => ({ viewValue: m.name, value: m.name }));

      return this.fuelTypes;
    }
  }

  public async getGearboxes() {
    if (this.gearboxes.length > 0) {
      return this.gearboxes;
    } else {
      let gearboxResp = await firstValueFrom(this.shopService.getCarSpecNomenclatorData('gearboxes'));

      this.gearboxes = gearboxResp.map(m => ({ viewValue: m.name, value: m.name }));

      return this.gearboxes;
    }
  }

  async checkIfUserInfo() {
    if (!this.userInfo) {
      let userResp = await firstValueFrom(this.userService.getUserInfo());

      this.userInfo = userResp;
    }

    return Promise.resolve(this.userInfo);
  }

  public async getUserInfo() {
    if (localStorage.getItem('msalToken') && !this.jwtHelperService.isTokenExpired(localStorage.getItem('msalToken'))) {
      let userResp = await firstValueFrom(this.userService.getUserInfo());

      this.userInfo = userResp;
    }

    return Promise.resolve();
  }

  public async getTransportMatrix() {
    if (this.transportMatrix.length > 0) {
      return this.transportMatrix;
    } else {
      this.transportMatrix = await firstValueFrom(this.infoService.getTransportMatrix());

      return this.transportMatrix;
    }
  }

  // public async getCarBusinessCase(cars: CarDetails[]) {
  //   let storeCountryCode = this.countries.find(c => this.utilsService.replaceAll(c.id, '-', '') === this.userInfo!.buyerStores[0].country.id)!.iso;

  //   const businessCasePromise: Promise<BusinessCaseResponse>[] = [];

  //   cars.forEach((c: CarDetails) => {
  //     let body: BusinessCaseBody = {
  //       countries: [storeCountryCode],
  //       make: c.make!,
  //       model: c.model!,
  //       manufactureYear: c.manufactureYear!,
  //       fuelType: c.fuelType!,
  //       gearbox: c.gearbox,
  //       bodyType: c.bodyType,
  //       variant: c.variant,
  //       mileage: c.mileage,
  //       enginePower: c.enginePower,
  //       firstReg: c.firstReg.toString() === 'None' ? "" : c.firstReg.toString(),
  //       accessories: [],
  //       carMainInfoId: c.carMainInfoId
  //     }
  //     businessCasePromise.push(firstValueFrom(this.infoService.getCarBusinessCar(body)));
  //   });

  //   let bc = await firstValueFrom(zip(businessCasePromise));

  //   cars.forEach(c => {
  //     c.businessCase = bc.find(b => b.carMainInfoId === c.carMainInfoId)!.businessCase[0];
  //   });

  //   return Promise.resolve(cars);
  // }
}
