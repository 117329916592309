<div *ngIf="!isMobile;else mobilePNavbar">
  <div class="bg-white-bgGrey py-6 px-9 mb-12">
    <p>Hi there, {{name}}!</p>
  </div>

  <div (click)="goTo('my-account')"
    class="text-blue-headerBg py-6 px-9 flex gap-3 items-center hover:bg-white-bgGrey cursor-pointer"
    [ngClass]="{'bg-white-bgGrey border-r-[3px] border-blue-headerBg' : page === 'profile'}">
    <fa-icon class="w-10 h-10 flex items-center justify-center rounded text-2xl" [icon]="['far','square-user']"
      [ngClass]="{'bg-blue-headerBg text-white':page ==='profile'}"></fa-icon>
    <p class=" font-medium">My account</p>
  </div>

  <div (click)="goTo('my-orders')"
    class="text-blue-headerBg py-6 px-9 flex gap-3 items-center hover:bg-white-bgGrey cursor-pointer"
    [ngClass]="{'bg-white-bgGrey border-r-[3px] border-blue-headerBg' : page === 'orders'}">
    <fa-icon class="w-10 h-10 flex items-center justify-center rounded text-2xl" [icon]="['far','file-lines']"
      [ngClass]="{'bg-blue-headerBg text-white':page ==='orders'}"></fa-icon>
    <p class=" font-medium">My orders</p>
  </div>

  <div (click)="goTo('my-offers')"
    class="text-blue-headerBg py-6 px-9 flex gap-3 items-center hover:bg-white-bgGrey cursor-pointer"
    [ngClass]="{'bg-white-bgGrey border-r-[3px] border-blue-headerBg' : page === 'offers'}">
    <fa-icon class="w-10 h-10 flex items-center justify-center rounded text-2xl" [icon]="['far','star']"
      [ngClass]="{'bg-blue-headerBg text-white':page ==='offers'}"></fa-icon>
    <p class=" font-medium">My offers</p>
  </div>

  <div (click)="goTo('my-favorites')"
    class="text-blue-headerBg py-6 px-9 flex gap-3 items-center hover:bg-white-bgGrey cursor-pointer"
    [ngClass]="{'bg-white-bgGrey border-r-[3px] border-blue-headerBg' : page === 'favorites'}">
    <fa-icon class="w-10 h-10 flex items-center justify-center rounded text-2xl" [icon]="['far','heart']"
      [ngClass]="{'bg-blue-headerBg text-white':page ==='favorites'}"></fa-icon>
    <p class=" font-medium">My favorites</p>
  </div>

  <!-- <div (click)="goTo('buying-profile')"
    class="text-blue-headerBg py-6 px-9 flex gap-3 items-center hover:bg-white-bgGrey cursor-pointer"
    [ngClass]="{'bg-white-bgGrey border-r-[3px] border-blue-headerBg' : page === 'buy-profile'}">
    <fa-icon class="w-10 h-10 flex items-center justify-center rounded text-2xl" [icon]="['fal','flask']"
      [ngClass]="{'bg-blue-headerBg text-white':page ==='buy-profile'}"></fa-icon>
    <p class=" font-medium">Buying profile</p>
  </div> -->

  <!-- <div (click)="goTo('maria-selection')"
    class="text-blue-headerBg py-6 px-9 flex gap-3 items-center hover:bg-white-bgGrey cursor-pointer"
    [ngClass]="{'bg-white-bgGrey border-r-[3px] border-blue-headerBg' : page === 'maria'}">
    <fa-icon class="w-10 h-10 flex items-center justify-center rounded text-2xl" [icon]="['fal','robot']"
      [ngClass]="{'bg-blue-headerBg text-white':page ==='maria'}"></fa-icon>
    <p class=" font-medium">Maria selection</p>
  </div> -->

  <div (click)="goTo('saved-searches')"
    class="text-blue-headerBg py-6 px-9 flex gap-3 items-center hover:bg-white-bgGrey cursor-pointer"
    [ngClass]="{'bg-white-bgGrey border-r-[3px] border-blue-headerBg' : page === 'searches'}">
    <fa-icon class="w-10 h-10 flex items-center justify-center rounded text-2xl" [icon]="['far','magnifying-glass']"
      [ngClass]="{'bg-blue-headerBg text-white':page ==='searches'}"></fa-icon>
    <p class=" font-medium">Saved searches</p>
  </div>

  <div (click)="logout()"
    class="text-blue-headerBg py-6 px-9 flex gap-3 items-center hover:bg-white-bgGrey cursor-pointer">
    <p class=" font-medium">Logout</p>
  </div>
</div>

<ng-template #mobilePNavbar>
  <div class="px-9 py-5 flex justify-between items-center" (click)="profileNavbarMobile.togglePanel()">
    <div class="flex gap-3 items-center font-medium text-blue-headerBg">
      <fa-icon class="w-10 h-10 flex items-center justify-center rounded text-2xl bg-blue-headerBg text-white"
        [icon]="[routeSelected!.iconPre,routeSelected!.icon]"></fa-icon>

      <p>{{routeSelected?.display}}</p>
    </div>
    <fa-icon [icon]="['far','chevron-down']" *ngIf="!profileNavbarMobile.expandedPanel;else chevronUp"></fa-icon>

    <ng-template #chevronUp>
      <fa-icon [icon]="['far','chevron-up']"></fa-icon>
    </ng-template>
  </div>

  <app-dropdown-panel #profileNavbarMobile [panelId]="'mobileProfileNavbar'">
    <div class="flex flex-col gap-9 mx-9 py-5 border-t border-white-lines">
      <div *ngFor="let tab of routes" class="flex gap-3 font-medium text-blue-headerBg items-center"
        (click)="goTo(tab.route)">
        <fa-icon [icon]="[tab.iconPre,tab.icon]"></fa-icon>

        <p>{{tab.display}}</p>
      </div>
    </div>
  </app-dropdown-panel>
</ng-template>
