import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IconName, IconPrefix } from '@fortawesome/pro-solid-svg-icons';
import { AuthService } from 'src/app/core/services/auth.service';
import { CoreService } from 'src/app/core/services/core.service';

interface MobileRoutes {
  value: string,
  iconPre: IconPrefix,
  icon: IconName,
  display: string,
  route: string
}
@Component({
  selector: 'app-profile-page-navbar',
  templateUrl: './profile-page-navbar.component.html',
  styleUrls: ['./profile-page-navbar.component.scss']
})


export class ProfilePageNavbarComponent implements OnInit {
  public navbarOpened: boolean = false;

  @Input() page: string = '';
  @Input() isMobile = false;

  routes: MobileRoutes[] = [
    {
      value: 'profile',
      icon: 'square-user',
      iconPre: 'far',
      display: 'My account',
      route: 'my-account'
    },
    {
      value: 'orders',
      icon: 'file-lines',
      iconPre: 'far',
      display: 'My orders',
      route: 'my-orders'
    },
    {
      value: 'offers',
      icon: 'star',
      iconPre: 'far',
      display: 'My offers',
      route: 'my-offers'
    },
    {
      value: 'favorites',
      icon: 'heart',
      iconPre: 'far',
      display: 'My favorites',
      route: 'my-favorites'
    },
    {
      value: 'buy-profile',
      icon: 'flask',
      iconPre: 'fal',
      display: 'Buying profile',
      route: 'buying-profile'
    },
    {
      value: 'maria',
      icon: 'robot',
      iconPre: 'fal',
      display: 'Maria selection',
      route: 'maria-selection'
    },
    {
      value: 'searches',
      icon: 'magnifying-glass',
      iconPre: 'far',
      display: 'Saved searches',
      route: 'saved-searches'
    }
  ];

  name = this.coreService.userInfo!.user.firstName;

  routeSelected: MobileRoutes | undefined;

  constructor(private router: Router,
    private coreService: CoreService,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.routeSelected = this.routes.find(r => r.value === this.page);
  }

  goTo(route: string) {
    this.router.navigate([route]);
  }

  logout() {
    this.authService.logout();
  }
}
