
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { SavedSearchesComponent } from './saved-searches.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    SavedSearchesComponent
  ]
})

export class SavedSearchesModule { }
