<app-nav-bar-after-login tab="cart"></app-nav-bar-after-login>

<div class="bg-white-bgGrey min-h-[80vh]">
  <div class="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-5 gap-6"
    *ngIf="(loading | async) || (loadingCart | async) || (cars && cars.length > 0);else cartEmpty">
    <p class="col-span-1 md:col-span-2 lg:col-span-3 font-bold">My shopping cart</p>

    <div class="col-span-1 lg:col-span-2 flex flex-col gap-12"
      *ngIf="!(loading | async) && !(loadingCart | async);else carsPlaceholder">
      <app-main-content-layout>
        <div class="flex flex-col gap-9">
          <div class="flex flex-wrap gap-6 items-center mt-3 justify-between">
            <app-dropdown [options]="buyerStores" [control]="storeControl" (emitOnChangeSelect)="changeStore($event)"
              class="min-w-[12rem]" label="Delivery store" placeholder="Select delivery store"></app-dropdown>

            <!-- <fa-icon class="bg-white-bgGrey text-white-greyText px-3 py-[0.625rem] rounded"
              matTooltip="Transportation fees will be affected by the store selection" matTooltipPosition="above"
              [icon]="['fas','circle-info']"></fa-icon> -->

            <app-white-btn [variant]="4" (clickEmitter)="getCarsFromOffers()">Bring cars from Accepted
              offers</app-white-btn>
          </div>

          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-6 text-sm">
            <div>
              <p class="font-bold mb-3">Delivery STORE Address</p>

              <p class="text-white-greyText" *ngIf="selectedStore">{{selectedStore?.country.country}},
                {{selectedStore?.city}}, {{selectedStore?.address}},
                {{selectedStore?.postalCode}}</p>
            </div>

            <div class="sm:pl-6 md:pl-0 lg:pl-6 sm:border-l md:border-0 lg:border-l border-white-lines">
              <p class="font-bold mb-3">Billing Address</p>

              <p class="text-white-greyText">{{company.country.country}}, {{company.city}}, {{company.address}},
                {{company.postalCode}}</p>
            </div>
          </div>

          <div class="flex flex-col xl:flex-row justify-between gap-6 items-center">
            <div class="flex flex-wrap gap-6">
              <app-toggle [control]="orderOptionsForm.controls.separateTransportInv" id="separate-transport-invoice"
                label="Separate invoice for transport"></app-toggle>
              <app-toggle [control]="orderOptionsForm.controls.separateCarInv" id="one-invoice-car"
                label="One invoice per car"></app-toggle>
            </div>

            <div class="flex gap-6">
              <app-white-btn [variant]="2" btnText="Include COC for all cars"
                (clickEmitter)="addCOCAllCars()"></app-white-btn>
              <app-white-btn [variant]="2" btnText="Include photo edit for all cars"
                (clickEmitter)="addPhotoEditAllCars()"></app-white-btn>
            </div>
          </div>
        </div>
      </app-main-content-layout>

      <!-- car cards -->
      <div *ngFor="let car of cars">
        <div class="bg-white p-6 md:px-9 rounded w-full flex flex-col gap-4"
          [ngClass]="{'!rounded-b-none' : car!.carPricing.offerDetails.offeredPrice}">
          <div class="flex flex-col sm:flex-row md:flex-col lg:flex-row gap-6 relative">
            <div class="flex w-full max-w-[8rem]">
              <img class="w-full object-cover rounded cursor-pointer" (click)="goToCar(car.carMainInfoId)"
                [src]="car.profilePhoto && car.profilePhoto != 'None' ? car.profilePhoto :  './assets/photos/car-placeholder.png'"
                alt="">
            </div>

            <div class="flex flex-col gap-3">
              <p class="font-bold cursor-pointer" (click)="goToCar(car.carMainInfoId)">{{car.make}} {{car.model}},
                {{car.variant}}</p>

              <p *ngIf="car.vin" class="text-xs text-white-700">VIN: {{car.vin}}</p>

              <div class="flex flex-wrap gap-2 text-xs  text-blue-darkMauve">
                <div class="flex gap-3 py-1 px-2 bg-white-bgGrey">
                  <p>{{car.mileage | number}} KM</p>
                </div>

                <div class="flex gap-3 py-1 px-2 bg-white-bgGrey">
                  <p>{{car.manufactureYear}}</p>
                </div>

                <div class="flex gap-3 py-1 px-2 bg-white-bgGrey">
                  <p>{{car.gearbox}}</p>
                </div>

                <div class="flex gap-3 py-1 px-2 bg-white-bgGrey">
                  <p>{{car.fuelType}}</p>
                </div>
              </div>

              <p class="font-bold">Car price: €{{car.carPricing.shopPrice| number}} <span
                  class="text-white-greyText text-xs font-normal">VAT: {{car.vatStatus ? vatStatus.ExVAT:
                  vatStatus.IncludedVAT}}</span></p>

              <p class="text-green text-sm">including transport ({{vatStatus.ExVAT}})</p>
            </div>

            <fa-icon (click)="removeFromCart(car.carMainInfoId!)"
              class="cursor-pointer absolute fa-icon-div top-0 right-0 text-white-greyText transition duration-150 hover:scale-105 ease-in-out"
              [icon]="['fas','trash-can']"></fa-icon>
          </div>

          <div class="flex flex-wrap gap-6">
            <app-toggle-two-way [(toggled)]="car.additionalProperties.cocRequired"
              [componentId]="'coc-'+car.carMainInfoId" (toggledChange)="updateCartCarCOC(car, $event)">
              <p>Include COC</p>
            </app-toggle-two-way>

            <app-toggle-two-way [(toggled)]="car.additionalProperties.photoEditing"
              [componentId]="'photo-'+car.carMainInfoId" (toggledChange)="updateCartCarPhotoEdit(car, $event)">
              <p class="whitespace-normal">Include photo editing (<span class="font-bold">€{{photoEditValue |
                  number}}/car</span>,
                {{vatStatus.ExVAT}})</p>
            </app-toggle-two-way>
          </div>
        </div>

        <div *ngIf="car!.carPricing.offerDetails.offeredPrice"
          class="bg-blue-cardHover px-6 py-3 flex gap-6 flex-wrap items-end rounded-b">
          <p class="px-3 py-1 text-xs font-medium rounded text-green bg-[#e4fff1]">Offer accepted</p>

          <p class="text-blue-ctaBlue">€{{car!.carPricing.offerDetails.offeredPrice | number}}</p>

          <p>Offer expiration date: {{car!.carPricing.offerDetails.offerExpirationDate | date: 'yyyy-MM-dd'}}</p>
        </div>
      </div>
    </div>

    <!-- cart -->
    <app-main-content-layout class="col-span-1 h-min sticky top-0">
      <div class="flex flex-col gap-6" *ngIf="!(loading | async) && !(loadingCart | async);else cartPlaceholder">
        <div class="mb-3">
          <p class="text-2xl">Summary</p>
          <p class="tex-sm text-white-greyText mt-1">{{cars.length}} items</p>
        </div>

        <div class="flex flex-col text-sm pb-6 border-b border-white-lines">
          <p class="text-white-greyText mb-4">Car prices</p>

          <p class="text-xl font-bold">€{{getCarsTotalSum() | number}}</p>

          <p>({{cars[0].vatStatus ? vatStatus.ExVAT : '(contains ' + vatStatus.ExVAT + ' & ' +
            vatStatus.IncludedVAT}})
          </p>

          <fa-icon *ngIf="!cars[0].vatStatus" class="text-white-greyText"
            [matTooltip]="'This expression comes from adding up prices ' + vatStatus.ExVAT + '(like cars or transport) with prices with ' + vatStatus.IncludedVAT"
            matTooltipPosition="above" [icon]="['fas','circle-info']"></fa-icon>

          <p class="text-green">including transport ({{vatStatus.ExVAT}})</p>
        </div>

        <div class="flex flex-col text-sm pb-6 border-b border-white-lines">
          <p class="text-white-greyText mb-4">Services</p>

          <p class="text-xl font-bold">€{{getPhotoTotal() | number}}</p>

          <p class="text-sm text-white-greyText">of which</p>

          <ul class="ml-4 list-inside list-disc">
            <li>€{{getPhotoTotal() | number}} Photo edit, {{vatStatus.ExVAT}}</li>

            <!-- <li>DHL included for document Delivery</li> -->

            <li>COC included</li>
          </ul>
        </div>

        <div class="flex flex-col text-sm pb-6 border-b border-white-lines">
          <p class="text-white-greyText mb-4">Total</p>

          <p class="text-xl font-bold text-blue-ctaBlue">€{{getTotalSum() | number}}</p>

          <p>({{cars[0].vatStatus ? vatStatus.ExVAT : '(contains ' + vatStatus.ExVAT + ' & ' +
            vatStatus.IncludedVAT}})

          <p class="text-green">including transport ({{vatStatus.ExVAT}})</p>
        </div>

        <div class="flex gap-3 items-baseline">
          <app-checkbox [(checkboxValue)]="termsAndCondAccepted"></app-checkbox>

          <p class="text-sm text-white-greyText">I agree with
            <a class=" text-blue-ctaBlue underline cursor-pointer" [routerLink]="'/terms-of-buying'"
              routerLinkActive="active">Terms and Conditions for orders and Legal Policies </a>
          </p>

        </div>

        <app-blue-btn (clickEmitter)="sendOrder()">Send order</app-blue-btn>
      </div>

    </app-main-content-layout>
  </div>
</div>
<app-footer-after-login></app-footer-after-login>

<ng-template #carsPlaceholder>
  <div class="col-span-1 lg:col-span-2 flex flex-col gap-12">
    <app-main-content-layout *ngFor="let i of [].constructor(5)">
      <div class="flex flex-col sm:flex-row md:flex-col lg:flex-row gap-9">
        <div>
          <img class="rounded object-contain opacity-30" src="assets/photos/car-placeholder.png" alt="">
        </div>
        <div class="flex flex-col gap-6 w-full">
          <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(4)"></div>
        </div>
      </div>
    </app-main-content-layout>
  </div>
</ng-template>

<ng-template #cartPlaceholder>
  <div class="flex flex-col gap-6 w-full">
    <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(4)"></div>
  </div>
</ng-template>

<ng-template #cartEmpty>
  <div class="container flex flex-col justify-center min-h-[inherit] gap-9">
    <div>
      <app-no-results-placeholder [iconClass]="'fas'" [icon]="'basket-shopping-simple'">
      </app-no-results-placeholder>
    </div>

    <div class="flex flex-col gap-6 text-center">
      <p class="text-2xl">Your cart is empty!</p>

      <p>Shop today’s deals.</p>

      <app-blue-btn class="w-fit self-center" (clickEmitter)="goToShop()" id="back-to-shop">Back to shop</app-blue-btn>


      <app-white-btn [variant]="1" class="w-fit self-center" (clickEmitter)="getCarsFromOffers(true)">
        Add cars from Accepted offers
      </app-white-btn>
    </div>
  </div>
</ng-template>
