import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Filter } from '../../after-search/after-search.component';

interface AllFiltersModalData {
  filters: Filter[]
}

@Component({
  selector: 'app-all-filters-modal',
  templateUrl: './all-filters-modal.component.html',
  styleUrls: ['./all-filters-modal.component.scss']
})
export class AllFiltersModalComponent implements OnInit {
  filters = this.data.filters;
  groupedFilters: { bodytype: any } | undefined;
  filtersToDelete: Filter[] = [];

  constructor(public dialogRef: MatDialogRef<AllFiltersModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AllFiltersModalData) { }

  ngOnInit(): void {
    let result = this.filters.reduce(function (r, a) {
      r[a.group!] = r[a.group!] || [];
      r[a.group!].push({
        value: a.extraValue ? a.extraValue : a.value,
        groupValue: a.groupValue
      })
      return r;
    }, Object.create(null));

    this.groupedFilters = result;
  }

  cancel() {
    this.dialogRef.close({ filters: this.filters, filtersToDelete: this.filtersToDelete });
  }

  removeFilter(value: string, groupValue: string, groupName: string) {
    this.filtersToDelete.push(this.filters.find(f => f.groupValue === groupValue && (f.value === value || f.extraValue === value))!);

    this.filters.splice(this.filters.findIndex(f => f.groupValue === groupValue && (f.value === value || f.extraValue === value)), 1);

    let fGroup: [{ value: string, groupValuea: string }] = this.groupedFilters![groupName as keyof typeof this.groupedFilters];

    fGroup.splice(fGroup.findIndex(f => f.value === value), 1);

    if (fGroup.length < 1) {
      delete this.groupedFilters![groupName as keyof typeof this.groupedFilters];
    }

    if (groupValue === 'make') {
      delete this.groupedFilters!['Model' as keyof typeof this.groupedFilters];
    }
  }

  clearAllFilters() {
    this.dialogRef.close({ filters: [], filtersToDelete: this.filtersToDelete });
  }
}
