import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { MyProfileFavoritesComponent } from './my-profile-favorites.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    MyProfileFavoritesComponent
  ]
})

export class MyProfileFavoritesModule { }
