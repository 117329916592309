import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, firstValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
import { DownloadCarDocumentBody, Order, ShortOrderInfo, ValidateOrderCarBody } from "../models/order.model";
import { OrderCarFlatView } from "../models/car.model";

@Injectable({
  providedIn: 'root'
})

export class MyOrdersService {
  constructor(private http: HttpClient) {
  }

  public getOrders(): Observable<ShortOrderInfo[]> {
    return this.http.post<ShortOrderInfo[]>(`${environment.backendApiUrl}get-orders`, {});
  }

  public getOrder(orderId: string): Observable<Order> {
    return this.http.get<Order>(`${environment.backendApiUrl}get-order/${orderId}`);
  }

  public getOrdersCarsFlatView(vin?: string): Observable<OrderCarFlatView[]> {
    return this.http.post<OrderCarFlatView[]>(`${environment.backendApiUrl}orders-client-cars-flat-view`, vin ? { vin: vin } : {});
  }

  public validateOrderCar(body: ValidateOrderCarBody): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}approve-validated-car`, body);
  }

  public rejectOrderCar(body: ValidateOrderCarBody): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}reject-validated-car`, body);
  }

  public downloadOrderCarDoc(body: DownloadCarDocumentBody): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}download-order-car-doc`, body);
  }

  public downloadOrderDoc(orderId: string, id: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}download-order-document`, { orderId: orderId, id: id });
  }
}
