import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CarAlternatives, CarC2VEquipments, CarDetails, CarMasterEquipments, CarPhoto, VINCheckDetails } from "../models/car.model";

@Injectable({
  providedIn: 'root'
})

export class CarService {
  constructor(private http: HttpClient) {
  }

  public getCarDetails(carId: string): Observable<CarDetails> {
    return this.http.get<CarDetails>(`${environment.backendApiUrl}cars/${carId}`);
  }

  public getCarPhotos(carId: string): Observable<CarPhoto[]> {
    return this.http.get<CarPhoto[]>(`${environment.backendApiUrl}photos/${carId}`);
  }

  public getCarMasterEquipments(carId: string): Observable<CarMasterEquipments> {
    return this.http.get<CarMasterEquipments>(`${environment.backendApiUrl}car-master-equipments/${carId}`);
  }

  public getCarC2VEquipments(carId: string): Observable<CarC2VEquipments> {
    return this.http.get<CarC2VEquipments>(`${environment.backendApiUrl}car-c2v-equipments/${carId}`);
  }

  public getCarAlternatives(carId: string): Observable<CarAlternatives> {
    return this.http.post<CarAlternatives>(`${environment.backendApiUrl}car-alternatives/${carId}`, {});
  }

  public getLatestCarAdded(): Observable<CarDetails[]> {
    return this.http.get<CarDetails[]>(`${environment.backendApiUrl}client-latest-cars-added`);
  }

  public getVINDetails(batchId: string): Observable<VINCheckDetails[] | string> {
    return this.http.get<VINCheckDetails[]>(`${environment.backendApiUrl}get-vin-details/${batchId}`);
  }

  public downloadCarDocument(carId: string, docId: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}download-car-doc`, { carMainInfoId: carId, id: docId });
  }
}
