<div class="w-full h-fit rounded-2xl bg-blue-500 p-6">
  <p class="text-2xl font-bold">Summary <span class="font-medium">(1 item)</span></p>

  <div class="text-xl flex flex-col xs:flex-row md:flex-col xs:justify-between gap-4 my-11">
    <div class="md:flex md:justify-between">
      <p class="font-medium">Price</p>
      <p class="font-bold">25,000 EUR</p>
    </div>

    <div class="md:flex md:justify-between">
      <p class="font-medium">VAT Type</p>
      <p class="font-bold">Included</p>
    </div>
  </div>

  <div class="border-t-2 xs:flex xs:justify-between border-white-600 py-11">
    <p class="text-xl font-medium">Total</p>
    <p class="text-4xl font-extrabold text-blue-700">25,000 EUR</p>
  </div>

  <div class="flex gap-4 mb-9">
    <app-checkbox></app-checkbox>
    <p class="font-medium">I agree with
      <span class="text-blue-700 underline cursor-pointer">terms and conditions for orders</span></p>
  </div>

  <div>
    <primary-btn>SEND ORDER</primary-btn>
  </div>
</div>