<app-main-content-layout>
  <div class="flex flex-col lg:flex-row lg:gap-6 lg:items-center">

    <div class="col-span-1 h-36 md:h-60 2xl:h-48 ">
      <img class="w-full h-full object-contain" src="./assets/photos/mercedes-white.png" alt="">
    </div>

    <div class="flex flex-col mt-6 lg:mt-0">
      <!-- <div *ngIf="profilePageSelected === ProfilePage.MariaSelection">
        <p class="text-blue-700 font-bold">In stock</p>
      </div> -->
      <div class="flex justify-between gap-3">
        <p class="2xl:w-4/5 font-bold text-xl md:text-2xl">BMW, 3 series, 2022, 50 km
          Diesel, Automatic gearbox</p>
        <!-- <fa-icon class="cursor-pointer" [icon]="['fal','trash-can']"
          *ngIf="profilePageSelected === ProfilePage.MyOffers || profilePageSelected === ProfilePage.MariaSelection">
        </fa-icon> -->
      </div>

      <app-fa-icon-text-layout class="font-bold text-blue-700 my-6 lg:text-sm 2xl:text-lg cursor-pointer"
        [iconGroup]="'fas'" [iconName]="'chevrons-right'" [text]="'VIEW EQUIPMENT'" [textFirst]="true">
      </app-fa-icon-text-layout>
    </div>
  </div>

  <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
    <div class="col-span-1 font-medium">
      <p>Price requested:</p>
      <p><span class="font-extrabold text-xl text-blue-400">25,000 EUR</span> ex VAT</p>
      <p class="text-sm">Date requested : 2022-01-01</p>
    </div>

    <div class="col-span-1 font-medium">
      <p>Last price ofered:</p>
      <p><span class="font-extrabold text-xl text-blue-700">25,000 EUR</span> ex VAT</p>
      <p class="text-sm">Date requested : 2022-01-01</p>
    </div>

    <app-fa-icon-text-layout class="text-yellow-200 font-bold col-span-1 lg:col-span-2" [iconGroup]="'fas'"
      [iconName]="'chevrons-right'" text="SEE HISTORY" [textFirst]="true"></app-fa-icon-text-layout>

    <primary-btn class="col-span-1">
      <app-fa-icon-text-layout [iconGroup]="'fal'" [iconName]="'coins'" text="BUY" [centerContent]="true">
      </app-fa-icon-text-layout>
    </primary-btn>

    <secondary-btn class="col-span-1" (clickEmitter)="goToOffer()">
      <app-fa-icon-text-layout [iconGroup]="'fal'" [iconName]="'tag'" text="MAKE OFFER" [centerContent]="true">
      </app-fa-icon-text-layout>
    </secondary-btn>
  </div>

  <div >
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <div class="col-span-1 font-medium">
        <p>Price requested:</p>
        <p><span class="font-extrabold text-xl text-blue-400">25,000 EUR</span> ex VAT</p>
        <p class="text-sm">Date requested : 2022-01-01</p>
      </div>

      <div class="col-span-1 font-medium">
        <p>Last price ofered:</p>
        <p><span class="font-extrabold text-xl text-blue-700">25,000 EUR</span> ex VAT</p>
        <p class="text-sm">Date requested : 2022-01-01</p>
      </div>

      <secondary-btn class="col-span-1 font-bold" [btnText]="'SEE HISTORY'"></secondary-btn>

      <primary-btn class="col-span-1">
        <app-fa-icon-text-layout [iconGroup]="'fal'" [iconName]="'coins'" text="ADD TO CART" [centerContent]="true">
        </app-fa-icon-text-layout>
      </primary-btn>

      <div class="col-span-1 lg:col-span-2">
        <div>
          <p class="text-lg md:text-xl font-medium">Make new offer:</p>
        </div>
      </div>
      <primary-btn class="col-span-1" [btnText]="'20,050 EUR'"></primary-btn>
      <secondary-btn class="col-span-1">
        <app-fa-icon-text-layout [iconGroup]="'fal'" [iconName]="'tag'" text="REQUEST" [centerContent]="true">
        </app-fa-icon-text-layout>
      </secondary-btn>
    </div>

    <div class="hidden md:grid grid-cols-4 gap-4 mt-6 text-lg font-bold">
      <div class="col-span-1">
        <p>Offer History:</p>
      </div>
      <div class="col-span-1">
        <p>Date:</p>
      </div>
      <div class="col-span-1">
        <p>Requested Price:</p>
      </div>
      <div class="col-span-1">
        <p>Offers Received:</p>
      </div>
    </div>

    <div class="hidden md:grid grid-cols-4 gap-4">
      <div class="col-span-1 col-start-2">
        <p>2022-01-02</p>
      </div>
      <div class="col-span-1">
        <p>24.400 EUR</p>
      </div>
      <div class="col-span-1">
        <p>25.200 EUR</p>
      </div>
    </div>

    <div class="md:hidden">
      <div class="flex justify-between py-6">
        <p class="text-lg font-bold">Offer History:</p>
        <div *ngIf="expandedOfferHistoryMobile;else chevronsDown" (click)="toggleOfferHistoryMobile()">
          <fa-icon [icon]="['fas','chevrons-up']"></fa-icon>
        </div>
        <ng-template #chevronsDown>
          <fa-icon [icon]="['fas','chevrons-down']" (click)="toggleOfferHistoryMobile()"></fa-icon>
        </ng-template>
      </div>
      <app-dropdown-panel #mobileOfferHistory>
        <div class="flex flex-col gap-5">
          <div>
            <p class="font-bold">Date:</p>
            <p>2022-01-02:</p>
            <p class="font-bold">Requested Price:</p>
            <p>24.400 EUR</p>
            <p class="font-bold">Offers Received:</p>
            <p>25.200 EUR</p>
          </div>

          <div>
            <p class="font-bold">Date:</p>
            <p>2022-01-02:</p>
            <p class="font-bold">Requested Price:</p>
            <p>24.400 EUR</p>
            <p class="font-bold">Offers Received:</p>
            <p>25.200 EUR</p>
          </div>
        </div>
      </app-dropdown-panel>
    </div>
  </div>

  <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 text-xl">
    <div class="col-span-1">
      <p class="font-medium">Retail ex VAT in</p>
      <p class="font-bold text-blue-400 mb-4">29,000 EUR</p>
    </div>
    <div class="col-span-1">
      <p class="font-medium">Sales Factor</p>
      <p class="font-bold text-blue-400">29</p>
    </div>
    <div class="col-span-1">
      <p class="font-medium">Monthly sold</p>
      <p class="font-bold text-blue-400">320</p>
    </div>
    <div class="col-span-1">
      <p class="font-medium">Profit</p>
      <p class="font-bold text-blue-400">4,000 EUR</p>
    </div>

    <div class="col-span-1 md:col-start-2 xl:col-span-2 xl:col-start-3 md:text-end">
      <p class="font-medium"><span class="text-2xl lg:text-3xl font-extrabold text-blue-700">25,000 EUR</span> ex VAT</p>
    </div>

    <primary-btn class="col-span-1 md:col-start-2 xl:col-start-4">
      <app-fa-icon-text-layout text="ADD TO CART" [iconGroup]="'fas'" [iconName]="'coins'" [centerContent]="true">
      </app-fa-icon-text-layout>
    </primary-btn>

  </div>

</app-main-content-layout>
