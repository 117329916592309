import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardMaintenance } from './core/guards/auth.guard';
import { AfterSearchComponent } from './features/after-search/after-search.component';
import { BuyingProfileComponent } from './features/buying-profile/buying-profile.component';
import { CarPageComponent } from './features/car-page/car-page.component';
import { CheckoutPageComponent } from './features/checkout-page/checkout-page.component';
import { LoginComponent } from './features/login/login.component';
import { MariaSelectionComponent } from './features/maria-selection/maria-selection.component';
import { MyAccountPageComponent } from './features/my-account-page/my-account-page.component';
import { MyOffersComponent } from './features/my-offers/my-offers.component';
import { MyProfileFavoritesComponent } from './features/my-profile-favorites/my-profile-favorites.component';
import { SavedSearchesComponent } from './features/saved-searches/saved-searches.component';
import { ShoppingCartComponent } from './features/shopping-cart/shopping-cart.component';
import { RestrictedPageComponent } from './features/restricted-page/restricted-page.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { ViewOrdersComponent } from './features/my-orders/view-orders/view-orders.component';
import { OrderComponent } from './features/my-orders/order/order.component';
import { TobComponent } from './core/tob/tob.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'restricted', component: RestrictedPageComponent },
  { path: 'not-found', component: PageNotFoundComponent },
  { path: 'terms-of-buying', component: TobComponent },
  {
    path: '', canActivate: [AuthGuardMaintenance], children: [
      // { path: 'home', component: MarketingDataPropositionComponent },
      // { path: 'advanced-search', component: AdvancedSearchComponent },
      { path: 'search', component: AfterSearchComponent },
      { path: 'closed-sales', component: AfterSearchComponent },
      { path: 'car/:carId', component: CarPageComponent },
      { path: 'shopping-cart', component: ShoppingCartComponent },
      { path: 'checkout', component: CheckoutPageComponent },
      { path: 'my-account', component: MyAccountPageComponent },
      { path: 'my-orders', component: ViewOrdersComponent },
      { path: 'order/:orderId', component: OrderComponent },
      { path: 'my-offers', component: MyOffersComponent },
      { path: 'my-favorites', component: MyProfileFavoritesComponent },
      { path: 'buying-profile', component: BuyingProfileComponent },
      { path: 'maria-selection', component: MariaSelectionComponent },
      { path: 'saved-searches', component: SavedSearchesComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
