<div class="bg-white p-6 md:px-9 rounded w-full flex flex-col gap-4 sm:flex-row sm:gap-9"
  [ngClass]="{'!rounded-b-none' : car!.offerDetails}">
  <div class="flex w-full sm:max-w-[15rem] xl:aspect-square relative">
    <img class="object-cover rounded w-full max-h-[calc(100vw*0.4)] sm:max-h-[none] cursor-pointer" [src]="imgUrl"
      alt="" (click)="goToCar()">

    <div *ngIf="isNew" class="bg-red-200 text-red rounded absolute text-xs left-4 py-1 px-2 top-2 text-center">
      <p>NEW</p>
    </div>
  </div>

  <div class="w-full flex flex-col gap-4  justify-between">
    <div class="flex flex-col xl:flex-row gap-4 justify-between cursor-pointer" (click)="goToCar()">
      <div class="flex flex-col gap-3">
        <div class="flex flex-col lg:flex-row gap-2 lg:gap-6 lg:items-center">
          <p class="font-bold">{{car?.make}} {{car?.model}}</p>
        </div>

        <p *ngIf="car!.vin" class="text-xs text-white-700">VIN: {{car!.vin}}</p>

        <div class="flex flex-col text-sm gap-2">
          <p><span class="text-white-greyText">Variant:</span> {{car?.variant}}</p>
          <p *ngIf="car && car.trim"><span class="text-white-greyText">Trim:</span> {{car.trim}}</p>
        </div>

        <div class="flex flex-wrap gap-2 text-sm">
          <ul class="list-disc flex flex-wrap gap-6">
            <li class="list-none">
              <p>{{car?.mileage | number}} KM</p>
            </li>
            <li>
              <p>{{car?.manufactureYear}}</p>
            </li>
            <li>
              <p>{{car?.gearbox}}</p>
            </li>
            <li>
              <p>{{car?.fuelType}}</p>
            </li>
          </ul>
        </div>

        <div class="flex gap-2 items-center text-sm"
          *ngIf="car!.available && car!.available.from && !car!.available.now">
          <fa-icon [icon]="['fas','square-arrow-up-right']"></fa-icon>
          <p>Available from:</p>
          <p>{{availableFrom | date : 'dd/MM/yyyy'}}</p>
        </div>

        <!-- <div class="flex gap-2 items-center text-sm" *ngIf="car!.available && car!.available.to && car!.available.now">
          <fa-icon [icon]="['fas','square-arrow-down-left']"></fa-icon>
          <p>Available to:</p>
          <p>{{availableTo | date : 'dd/MM/yyyy HH:mm'}}</p>
        </div> -->

        <!-- <div class="flex gap-3 items-center text-sm">
          <p class="text-white-greyText">Delivered to:</p>
          <p>{{deliveredTo}}</p>
        </div> -->
      </div>

      <div class="xl:text-end">
        <div class="flex gap-1 xl:flex-col">
          <p class="font-bold text-blue-ctaBlue xl:text-2xl" [ngClass]="{'text-orange-100' : isClosedSales}">
            €{{car!.sellingPrice | number}}</p>

          <p class="font-normal text-xs text-white-600 self-center xl:self-end">{{car!.vatStatus ? vatStatus.ExVAT :
            vatStatus.IncludedVAT}}</p>
        </div>

        <p class="mt-3 text-xs text-white-greyText">transport included</p>
      </div>
    </div>

    <div class="flex justify-between gap-1 items-center">
      <div class="flex gap-1 lg:gap-6 items-center">
        <app-blue-btn [orangeBtn]="isClosedSales" id="add-to-cart" (clickEmitter)="addToCart()"
          *ngIf="car!.carStatus != carStatus.Reserved;else reserved">Add to
          cart</app-blue-btn>

        <ng-template #reserved>
          <app-read-only>Reserved</app-read-only>
        </ng-template>

        <fa-icon id="fav-btn" data-cy="fav-btn" (click)="addToFav()"
          class="px-3 py-2 bg-white-bgGrey text-white-disabled rounded cursor-pointer hover:bg-white-hoverGrey"
          [icon]="['fas','heart']" [ngClass]="{'is-favorited':isFav}"></fa-icon>

        <div *ngIf="car!.available && car!.available.now" class="flex gap-1 items-center text-sm">
          <fa-icon [icon]="['fas','circle-check']" class="text-green"></fa-icon>

          <p>Available now</p>
        </div>
      </div>

      <div class="text-end" (click)="carB2CPanel.togglePanel()"
        *ngIf="car!.spotPrice && car!.spotPrice - car!.sellingPrice > 0">
        <fa-icon class="px-3 py-2 bg-white-bgGrey rounded cursor-pointer hover:bg-white-hoverGrey"
          [icon]="['far','chevron-down']" *ngIf="!carB2CPanel.expandedPanel;else chevronUp"></fa-icon>
      </div>

      <ng-template #chevronUp>
        <fa-icon class="px-3 py-2 bg-white-bgGrey rounded cursor-pointer hover:bg-white-hoverGrey"
          [icon]="['far','chevron-up']"></fa-icon>
      </ng-template>
    </div>
  </div>
</div>

<app-dropdown-panel #carB2CPanel [panelId]="'car-bc-'+car!.carMainInfoId">
  <div class="bg-white p-6 md:px-9 rounded grid grid-cols-2 gap-y-6"
    *ngIf="car!.spotPrice && car!.spotPrice - car!.sellingPrice > 0" [ngClass]="{'!rounded-b-none' : car!.offerDetails}">
    <div class="col-span-2">
      <p class="font-bold">Business Case</p>
    </div>

    <div class="flex flex-col gap-3 col-span-2 lg:col-span-1 text-sm lg:pr-9 lg:border-r border-white-lines">
      <div class="flex justify-between">
        <p class="text-white-greyText">Similar cars sold</p>
        <p>{{car!.carsSold30Days | number}}</p>
      </div>

      <div class="flex justify-between">
        <p class="text-white-greyText">Similar cars for sale</p>
        <p>{{car!.carsOnline | number}}</p>
      </div>

      <div class="flex justify-between">
        <p class="text-white-greyText">Sales factor</p>

        <p *ngIf="!isNan(car!.salesFactor);else noSalesFactor">{{car!.salesFactor | number}}
        </p>

        <ng-template #noSalesFactor>
          <p>Not enough data</p>
        </ng-template>
      </div>
    </div>

    <div class="flex flex-col gap-3 col-span-2 lg:col-span-1 text-sm lg:pl-9">
      <div class="flex justify-between">
        <p class="text-white-greyText">Retail price</p>
        <p>€{{car!.spotPrice | number}}</p>
      </div>

      <div class="flex justify-between text-green">
        <p>Estimated profit</p>
        <p class="font-bold">€{{ car!.spotPrice - car!.sellingPrice | number}}</p>
      </div>
    </div>
  </div>
</app-dropdown-panel>

<div *ngIf="car && car.offerDetails" class="bg-blue-cardHover px-6 py-3 flex gap-6 flex-wrap items-end rounded-b">
  <p class="text-blue-ctaBlue">€{{car.offerDetails.value | number}}</p>

  <p class="px-3 py-1 text-xs font-medium rounded" [ngClass]="car.offerDetails.offerStatus">{{car!.offerDetails.offerStatus ===
    'newC2COffer' ? 'New offer from Cars2click' : car!.offerDetails.offerStatus === 'counterOffer' ? 'Counter offer' :
    car!.offerDetails.offerStatus}}</p>

  <p>Offer expiration date: {{car!.offerDetails.expiresOn | date: 'dd-MM-yyyy'}}</p>
</div>
