import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-my-profile-page-layout',
  template: `
  <app-nav-bar-after-login tab="account"></app-nav-bar-after-login>

    <div class="grid grid-cols-12">
      <app-profile-page-navbar class="col-span-2" [page]="profilePage" *ngIf="!isMobile"></app-profile-page-navbar>

      <div class="col-span-12 lg:col-span-10">
        <!-- <app-profile-page-ongoing-proccesses-panel></app-profile-page-ongoing-proccesses-panel> -->

        <app-profile-page-navbar [page]="profilePage" *ngIf="isMobile" [isMobile]="true"></app-profile-page-navbar>

        <div class="p-9 flex flex-col gap-6 bg-white-bgGrey min-h-[85vh]">
          <ng-content></ng-content>
        </div>
      </div>
    </div>

  <app-footer-after-login></app-footer-after-login>
  `,
})

export class MyProfilePageLayoutComponent implements OnInit {
  @Input() profilePage: string = 'profile';
  isMobile = false;

  constructor() { }

  ngOnInit() {
    this.isMobile = window.innerWidth < 1024;

    window.onresize = () => this.isMobile = window.innerWidth < 1024;
  }
}
