<app-nav-bar-after-login tab="shop"></app-nav-bar-after-login>

<div class="bg-white-bgGrey">
  <div class="container grid grid-cols-1 lg:grid-cols-2 gap-6 py-9 min-h-[80vh]"
    *ngIf="!(loading | async);else placeholder">
    <!-- model and variants -->
    <app-main-content-layout class="col-span-1 lg:col-span-2">
      <p class="text-2xl">Models and Variants</p>

      <p class="mt-3 mb-12 text-sm text-white-greyText">Vehicle selection</p>

      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        <app-dropdown [resetSelection]="true" placeholder="Select make" label="Make" [options]="makes"
          [control]="makeControl" (emitOnChangeSelect)="selectMake($event)"></app-dropdown>

        <app-dropdown [resetSelection]="true" label="Model" placeholder="Select model" [options]="models"
          [control]="modelControl" (emitOnChangeSelect)="selectModel($event)">
        </app-dropdown>

        <!-- <app-dropdown [resetSelection]="true" label="Variant" placeholder="Select variant"></app-dropdown> -->

        <app-dropdown [resetSelection]="true" [options]="countries" [control]="countryControl"
          (emitOnChangeSelect)="selectCountry($event)" label="Country" placeholder="Select country"></app-dropdown>
      </div>
    </app-main-content-layout>

    <!-- bodytypes -->
    <app-main-content-layout class="col-span-1" [fullHeight]="true">
      <p class="text-2xl">Body types</p>
      <p class="mt-3 mb-12 text-sm text-white-greyText">Vehicle body type</p>

      <div class="flex flex-wrap lg:flex-col gap-6">
        <div *ngFor="let body of bodytypes" class="flex gap-4 items-baseline">
          <app-checkbox [label]="body.displayName" (checkboxValueChange)="checkBodytype($event, body.value)"
            [(checkboxValue)]="body.isChecked!" [componentId]="body.displayName"></app-checkbox>
        </div>
      </div>
    </app-main-content-layout>

    <!-- details -->
    <app-main-content-layout class="col-span-1" [fullHeight]="true">
      <p class="text-2xl">Details</p>
      <p class="mt-3 mb-12 text-sm text-white-greyText">Vehicle characteristics</p>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-9">
        <!-- <app-dropdown placeholder="Select sales factor from" label="Sales factor from"></app-dropdown>
        <app-dropdown placeholder="Select sales factor up to" label="Sales factor up to"></app-dropdown> -->

        <app-input [numberInput]="true" [control]="co2FromControl" placeholder="Select CO2 WLTP from"
          label="CO2 WLTP from"></app-input>
        <app-input [numberInput]="true" [control]="co2ToControl" placeholder="Select CO2 WLTP to"
          label="CO2 WLTP up to"></app-input>

        <app-dropdown [resetSelection]="true" [options]="yearsFrom" (emitOnChangeSelect)="selectManYear($event,'from')"
          placeholder="Select year from" label="Year from" [control]="manufactureYears.controls.from"></app-dropdown>
        <app-dropdown [resetSelection]="true" [options]="yearsTo" (emitOnChangeSelect)="selectManYear($event,'until')"
          placeholder="Select year up to" label="Year up to" [control]="manufactureYears.controls.until"></app-dropdown>


        <app-input [numberInput]="true" [control]="priceFromControl" placeholder="Select price from"
          label="Price from"></app-input>
        <app-input [numberInput]="true" [control]="priceToControl" placeholder="Select price up to"
          label="Price up to"></app-input>

        <app-input [numberInput]="true" placeholder="Select KM from" label="KM from"
          [control]="mileageFromControl"></app-input>
        <app-input [numberInput]="true" placeholder="Select KM up to" label="KM up to"
          [control]="mileageToControl"></app-input>

        <app-input [numberInput]="true" [control]="enginePowFromControl" placeholder="Select power (HP) from"
          label="Power (HP) from"></app-input>
        <app-input [numberInput]="true" [control]="enginePowToControl" placeholder="Select power (HP) up to"
          label="Power (HP) up to"></app-input>
      </div>
    </app-main-content-layout>


    <app-main-content-layout class="col-span-1 lg:col-span-2">
      <p class="text-2xl">Technical specs</p>

      <div class="flex flex-col gap-6 mt-8">
        <div class="pb-6 border-b border-white-lines">
          <p class="text-sm text-white-greyText mb-4">Fuel type</p>
          <div class="flex flex-wrap gap-12">
            <app-checkbox [label]="fueltype.value" *ngFor="let fueltype of filters.fuelType"
              (checkboxValueChange)="changeFilter(fueltype)" [(checkboxValue)]="fueltype.isChecked!"
              [componentId]="fueltype.value"></app-checkbox>
          </div>
        </div>

        <div>
          <p class="text-sm text-white-greyText mb-4">Transmission type</p>
          <div class="flex flex-wrap gap-12">
            <app-checkbox [label]="gearbox.value" *ngFor="let gearbox of filters.gearbox"
              (checkboxValueChange)="changeFilter(gearbox)" [(checkboxValue)]="gearbox.isChecked!"
              [componentId]="gearbox.value"></app-checkbox>
          </div>
        </div>
      </div>
    </app-main-content-layout>
  </div>

  <!-- your filters-->
  <div class="bg-black sticky bottom-0 z-50" *ngIf="filtersSelected.length > 0">
    <div class="container py-8 flex flex-col lg:flex-row justify-between gap-6">
      <div class="flex flex-wrap gap-3 text-sm">
        <div class="px-4 py-3 rounded bg-black-darkChip text-blue-ctaBlue">
          <p>{{results}} results</p>
        </div>

        <div *ngFor="let filter of filtersSelected | slice:0:8;"
          class="px-4 py-3 rounded bg-black-darkChip text-white-bgGrey gap-4 hidden md:flex">
          <p *ngIf="!filter.extraValue;else extraValueP">{{filter.value}}</p>
          <ng-template #extraValueP>
            <p>{{filter.extraValue}}</p>
          </ng-template>
          <fa-icon [icon]="['far','xmark']" class="cursor-pointer" (click)="removeFilter(filter)"></fa-icon>
        </div>

        <div class="flex items-center">
          <p class="text-white-greyText cursor-pointer hover:text-white-hoverGrey hover:underline md:hidden"
            (click)="openAllFiltersModal()">see all</p>
        </div>

        <div class="flex items-center" *ngIf="filtersSelected.length > 8">
          <p class="text-white-greyText cursor-pointer hover:text-white-hoverGrey hover:underline hidden md:block"
            (click)="openAllFiltersModal()">see all</p>
        </div>
      </div>

      <div class="flex gap-1 lg:gap-4">
        <app-black-btn (clickEmitter)="clearFilters()">Clear filters</app-black-btn>
        <app-blue-btn (clickEmitter)="search()">SEARCH</app-blue-btn>
      </div>
    </div>
  </div>

  <ng-template #placeholder>
    <div class="container py-9 min-h-[80vh]">
      <app-main-content-layout class="">
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 ">
          <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(4)"></div>
        </div>
      </app-main-content-layout>

      <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
        <app-main-content-layout>
          <div class="flex flex-col gap-6">
            <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(6)"></div>
          </div>
        </app-main-content-layout>

        <app-main-content-layout>
          <div class="flex flex-col gap-6">
            <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(6)"></div>
          </div>
        </app-main-content-layout>

        <app-main-content-layout>
          <div class="flex flex-col gap-6">
            <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(6)"></div>
          </div>
        </app-main-content-layout>

        <app-main-content-layout>
          <div class="flex flex-col gap-6">
            <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(6)"></div>
          </div>
        </app-main-content-layout>
      </div>
    </div>
  </ng-template>

  <app-footer-after-login></app-footer-after-login>
