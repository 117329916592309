import { Component, OnInit } from '@angular/core';
import { PieChartData } from 'src/app/shared/app-pie-chart/app-pie-chart.component';

@Component({
  selector: 'app-market-data-banner',
  templateUrl: './market-data-banner.component.html',
  styleUrls: ['./market-data-banner.component.scss']
})
export class MarketDataBannerComponent implements OnInit {
  bodytypesInfo = [
    {
      name: 'Cabriolet / Roadster',
      percentage: 26,
      id: 'cabrio'
    },
    {
      name: 'Estate',
      percentage: 59,
      id: 'estate'
    },
    {
      name: 'Saloon',
      percentage: 26,
      id: 'saloon'
    },
    {
      name: 'Small',
      percentage: 68,
      id: 'small'
    },
    {
      name: 'Sports Car / Coupe',
      percentage: 10,
      id: 'sport'
    },
    {
      name: 'SUV / Off-road / Pickup',
      percentage: 46,
      id: 'suv'
    },
    {
      name: 'Van / Minibus',
      percentage: 23,
      id: 'van'
    },
    {
      name: 'Other',
      percentage: 18,
      id: 'other'
    }
  ]

  gearBoxPieChartData: PieChartData[] = [];

  constructor() { }

  ngOnInit(): void {
    this.gearBoxPieChartData.push({
      data: 60,
      label: 'Car 1',
      backgroundColor: '#77EBB3'
    });

    this.gearBoxPieChartData.push({
      data: 20,
      label: 'Car 2',
      backgroundColor: '#FF518E'
    });

    this.gearBoxPieChartData.push({
      data: 50,
      label: 'Car 3',
      backgroundColor: '#0F1848'
    });

    this.gearBoxPieChartData.push({
      data: 10,
      label: 'Car 4',
      backgroundColor: '#179EFF'
    });

    this.gearBoxPieChartData.push({
      data: 25,
      label: 'Car 5',
      backgroundColor: '#FFDE31'
    });
  }

  ngAfterViewInit() {
    this.bodytypesInfo.forEach(b => {
      document.getElementById(b.id)!.style.width = `${b.percentage}%`;
    })
  }
}
