import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, catchError, forkJoin, of } from 'rxjs';
import { CarDetails } from 'src/app/core/models/car.model';
import { CarService } from 'src/app/core/services/car.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { CreateOfferModalComponent } from './create-offer-modal/create-offer-modal.component';
import { OffersService } from 'src/app/core/services/offers.service';
import { CreateOfferBody, Offer } from 'src/app/core/models/offer.model';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { FormControl } from '@angular/forms';
import { BusinessCase, BusinessCaseBody, Country, TransportMatrix } from 'src/app/core/models/utils.model';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ShopService } from 'src/app/core/services/shop.service';
import { UserService } from 'src/app/core/services/user.service';
import { VAT } from 'src/app/shared/car-shop-card/car-shop-card.component';
import { InfoService } from 'src/app/core/services/info.service';
import { CartService } from 'src/app/core/services/cart.service';
import { OfferClientStatuses } from '../my-offers/my-offers.component';
import { CounterOfferModalComponent } from '../my-offers/counter-offer-modal/counter-offer-modal.component';

@Component({
  selector: 'app-car-page',
  templateUrl: './car-page.component.html',
  styleUrls: ['./car-page.component.scss']
})
export class CarPageComponent implements OnInit {
  isLoadingPage = new BehaviorSubject<boolean>(true);

  car: CarDetails | undefined;
  images: string[] = [];
  equipments: string[] = [];
  carAlternatives: CarDetails[] = [];

  userInfo = this.coreService.userInfo!;

  countries: Country[] = [];
  transportMatrix: TransportMatrix[] = [];

  storesDropdown: DropdownOption[] = this.userInfo.buyerStores.map(s => ({ value: s.id, viewValue: s.store }));
  storeControl = new FormControl(this.storesDropdown[0].value);
  storeCountry = this.coreService.userInfo!.buyerStores[0].country.country;

  carBusinessCase: BusinessCase | undefined;

  offer: Offer | undefined;

  isFav = false;

  vat = '';

  offerStatus = OfferClientStatuses;

  constructor(private activatedRoute: ActivatedRoute,
    private carService: CarService,
    private coreService: CoreService,
    private snackbar: SnackbarService,
    private dialog: MatDialog,
    private offersService: OffersService,
    private offerService: OffersService,
    private shopService: ShopService,
    private userService: UserService,
    private infoService: InfoService,
    private cartService: CartService,
    private router: Router) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.isLoadingPage.next(true);

      if (params['carId']) {
        let carId = params['carId'];

        forkJoin({
          car: this.carService.getCarDetails(carId),
          countries: this.coreService.getCountries(),
          favsCars: this.userService.getUserFavoritesCars(),
          carPhotos: this.carService.getCarPhotos(carId),
          carMasterEquipments: this.carService.getCarMasterEquipments(carId),
          carC2VEquipments: this.carService.getCarC2VEquipments(carId),
          carAlternatives: this.carService.getCarAlternatives(carId).pipe(catchError(err => of(err))),
        }).subscribe({
          next: resp => {
            this.car = resp.car;
            this.countries = resp.countries;

            this.vat = this.car!.vatStatus ? VAT.ExVAT : VAT.IncludedVAT;

            if (resp.favsCars.id) {
              this.isFav = resp.favsCars.cars.some(c => c.carMainInfoId === carId);
            }

            let businessCaseBody: BusinessCaseBody = {
              countries: [this.countries.find(c => c.id.replace(/-/g, '') === this.userInfo.country.id)!.iso],
              make: resp.car.make,
              model: resp.car.model,
              manufactureYear: resp.car.manufactureYear,
              fuelType: resp.car.fuelType,
              gearbox: resp.car.gearbox,
              bodyType: resp.car.bodyType,
              variant: resp.car.variant,
              mileage: resp.car.mileage,
              enginePower: resp.car.enginePower,
              carMainInfoId: resp.car.carMainInfoId,
              firstReg: resp.car.firstReg.toString(),
              accessories: []
            };


            if (!resp.carAlternatives.error && resp.carAlternatives.similarTechCars) {
              this.carAlternatives = resp.carAlternatives.similarTechCars.concat(resp.carAlternatives.similarMakeCars).slice(0, 5);
            }

            this.images = resp.carPhotos.map((p: any) => p.originalPhoto!);
            this.images = this.images.length > 0 ? this.images : [resp.car.profilePhoto];

            if (resp.carMasterEquipments.optionalEquipment) {
              this.addEquipmentToArray(resp.carMasterEquipments.optionalEquipment);
              this.addEquipmentToArray(resp.carMasterEquipments.standardEquipment);
              this.addEquipmentToArray(resp.carMasterEquipments.packEquipment);
            }

            //add car custom eq to list
            this.equipments = [...this.equipments, ...resp.carC2VEquipments.customEquipment.map(c => this.splitWordByUnderline(c))];

            this.carBusinessCase = undefined;

            this.infoService.getCarBusinessCar(businessCaseBody).subscribe({
              next: bc => {
                this.carBusinessCase = bc[0];

                if (!this.carBusinessCase || !this.carBusinessCase.spotPrice || isNaN(this.carBusinessCase.spotPrice) || this.carBusinessCase.spotPrice - this.car!.sellingPrice <= 0) {
                  this.carBusinessCase = undefined;
                }

                this.isLoadingPage.next(false);
              },
              error: err => {
                this.isLoadingPage.next(false);
              }
            });
          },
          error: (err) => {
            this.isLoadingPage.next(false);

            if (err.status === 404) this.router.navigate(['not-found']);
          }
        });

        this.offersService.offerSubject$.subscribe(resp => {
          this.offer = resp.find(o => o.carDetails.carMainInfoId === carId);
        });
      }
    });
  }

  ngOnDestroy() {
    let url = this.router.routerState.snapshot.url;

    if (!url.match('/search') && !url.match('/closed-sales')) {
      this.shopService.shopCarsCache = {
        shopCars: [],
        lastShopPage: 0,
        nrOfResults: 0,
        route: ''
      };
    }
  }

  addEquipmentToArray(arrayOfEquipments: any[]) {
    let eq = arrayOfEquipments.map(eq => Object.getOwnPropertyNames(eq)).flat(1);

    eq.forEach(e => {
      this.equipments.push(this.splitWordByCamelcase(e));
    })
  }

  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  splitWordByCamelcase(string: string): string {
    string = this.capitalizeFirstLetter(string);

    return string.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  }

  splitWordByUnderline(string: string): string {
    string = this.capitalizeFirstLetter(string);

    return string.replaceAll('_', ' ');
  }

  copyAlert() {
    this.snackbar.positiveSentiment('Vehicle id copied to clipboard!');
  }

  openMakeOfferModal() {
    if (this.coreService.showProgressBar.value) return;

    const dialogRef = this.dialog.open(
      CreateOfferModalComponent, {
      width: '600px',
      maxWidth: '90vw',
      autoFocus: false,
      data: {
        car: this.car
      }
    });

    dialogRef.afterClosed().subscribe(modalResp => {
      if (modalResp) {
        this.coreService.showProgressBar.next(true);

        let createOfferBody: CreateOfferBody = {
          cars: [{
            value: parseInt(modalResp.value),
            carMainInfoId: this.car!.carMainInfoId!
          }],
          expiresOn: modalResp.expDate,
          buyerId: this.userInfo.buyerCompany.id
        }

        this.offersService.createOffer(createOfferBody).subscribe({
          next: resp => {
            this.offerService.loadOffers();

            this.coreService.showProgressBar.next(false);

            this.snackbar.positiveSentiment('Offer created');
          },
          error: err => {
            this.coreService.showProgressBar.next(false);

            this.snackbar.negativeSentiment(err.error);
          }
        })
      }
    });
  }

  addToCart() {
    this.shopService.addCarToCart(this.car!.carMainInfoId).subscribe({
      next: resp => {
        this.snackbar.positiveSentiment('Car added to cart');

        this.cartService.emitCartCars();
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  addToFavs() {
    if (this.isFav) {
      this.userService.removeFavoritesCar(this.car?.carMainInfoId!).subscribe(resp => { })
    } else {
      this.shopService.addCarToFavorites(this.car!.carMainInfoId!).subscribe(resp => { })
    }

    this.isFav = !this.isFav;
  }

  goBack() {
    this.router.navigate([`${this.shopService.shopCarsCache.route === 'closed-sales' ? 'closed-sales' : 'search'}`]);
  }

  isNaN(value: number) {
    return isNaN(value);
  }

  downloadFile(docId: string) {
    this.carService.downloadCarDocument(this.car!.carMainInfoId, docId).subscribe({
      next: resp => {
        window.open(resp, "_blank");
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);
      }
    });
  }

  acceptOffer(offer: Offer) {
    if (this.coreService.showProgressBar.value) return;

    this.coreService.showProgressBar.next(true);

    this.offersService.acceptOffer(offer.offerId).subscribe({
      next: resp => {
        this.offerService.loadOffers();

        this.coreService.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Offer accepted');
      },
      error: err => {
        this.coreService.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err);
      }
    });
  }

  declineOffer(offer: Offer) {
    if (this.coreService.showProgressBar.value) return;

    this.coreService.showProgressBar.next(true);

    this.offersService.declineOffer(offer.offerId).subscribe({
      next: resp => {
        this.offerService.loadOffers();

        this.coreService.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Offer declined');
      },
      error: err => {
        this.coreService.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err);
      }
    });
  }

  openCounterOfferModal(offer: Offer) {
    if (this.coreService.showProgressBar.value) return;

    const dialogRef = this.dialog.open(
      CounterOfferModalComponent, {
      width: '600px',
      maxWidth: '90vw',
      autoFocus: false,
      data: {
        offer: offer
      }
    });

    dialogRef.afterClosed().subscribe(counter => {
      if (counter) {
        this.coreService.showProgressBar.next(true);

        let body = {
          offerId: offer.offerId,
          value: counter.value,
          expiresOn: counter.expiresOn
        }

        this.offersService.makeCounterOffer(body).subscribe({
          next: resp => {
            this.offerService.loadOffers();

            this.coreService.showProgressBar.next(false);

            this.snackbar.positiveSentiment('Counter offer created');
          },
          error: err => {
            this.coreService.showProgressBar.next(false);

            this.snackbar.negativeSentiment(err);
          }
        })
      }
    });
  }
}
