import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { MariaSelectionComponent } from './maria-selection.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    MariaSelectionComponent
  ]
})

export class MariaSelectionModule { }
