import { Component, Input, OnInit } from '@angular/core';
import { IconName, IconPrefix } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-fa-icon-text-layout',
  template: `
    <div class="flex gap-3 items-center" [ngClass]="{'justify-center' : centerContent}">
      <fa-icon [icon]="[iconGroup,iconName]" [ngClass]="{'order-2' : textFirst}"></fa-icon>
      <p [ngClass]="{'order-1' : textFirst}">{{text}}</p>
    </div>
  `,
})

export class FaIconTextLayoutComponent implements OnInit {
  @Input() text: string = '';
  @Input() iconGroup: IconPrefix = 'fal';
  @Input() iconName: IconName = 'check';
  @Input() textFirst: boolean = false;
  @Input() centerContent: boolean = false;

  constructor() { }

  ngOnInit() { }
}
