<div class="dropdown relative">
  <p class="absolute z-10 text-white-disabled text-xs -top-5" *ngIf="label">{{label}}</p>
  <mat-select [id]="id + '-dropdown'" [attr.data-cy]="id + '-dropdown'" [formControl]="control"
    [multiple]="multipleSelect" [placeholder]="placeholder"
    class="bg-white-bgGrey hover:bg-white-hoverGrey text-sm px-4 py-3 rounded"
    [ngClass]="{'disabled':control.disabled, 'bg-white': variant === 2}">
    <mat-option *ngIf="resetSelection" (onSelectionChange)="emitChange($event)">--</mat-option>
    <mat-option *ngFor="let option of options" [value]="option.value" (onSelectionChange)="emitChange($event)">
      {{option.viewValue}}
    </mat-option>
  </mat-select>
</div>
