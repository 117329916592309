import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-read-only',
  templateUrl: './app-read-only.component.html',
  styleUrls: ['./app-read-only.component.scss']
})
export class AppReadOnlyComponent implements OnInit {
  @Input() text = '';
  @Input() label: string | undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
