import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { OrderCarFlatView } from 'src/app/core/models/car.model';
import { ShortOrderInfo } from 'src/app/core/models/order.model';
import { MyOrdersService } from 'src/app/core/services/my-orders.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { TabOption } from 'src/app/shared/app-tabs/app-tabs.component';
import { ViewCarDetailsModalComponent } from 'src/app/shared/view-car-details-modal/view-car-details-modal.component';


@Component({
  selector: 'app-view-orders',
  templateUrl: './view-orders.component.html',
  styleUrls: ['./view-orders.component.scss']
})
export class ViewOrdersComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);
  loadingTable = new BehaviorSubject<boolean>(true);

  tabs: TabOption[] = [
    {
      value: 'pending',
      viewValue: 'Pending orders'
    },
    {
      value: 'approved',
      viewValue: 'Approved orders'
    },
    {
      value: 'canceled',
      viewValue: 'Canceled orders'
    },
    {
      value: 'finished',
      viewValue: 'Past orders'
    }
  ];

  orderView = 'orders';

  allOrders: ShortOrderInfo[] = [];
  filteredOrders: ShortOrderInfo[] = [];

  tabSelected = this.tabs[0].value;

  //flat view
  dataSource = new MatTableDataSource<OrderCarFlatView>();

  vinControl = new FormControl<string>('', Validators.minLength(3));

  headersMap: any[] = [
    {
      viewValue: 'vin',
      value: 'vin'
    },
    {
      viewValue: 'order number',
      value: 'orderId'
    },
    {
      viewValue: 'order Date',
      value: 'orderDate'
    },   {
      viewValue: 'order Status',
      value: 'orderStatus'
    },
    {
      viewValue: 'car Status',
      value: 'carStatus'
    },
    {
      viewValue: 'documents',
      value: 'documents'
    },
    {
      viewValue: 'transport Status',
      value: 'transportStatus'
    },
    {
      viewValue: 'pickup Date',
      value: 'pickupDate',
      shortDate: true
    },
    {
      viewValue: 'est Delivery Date',
      value: 'estDeliveryDate',
      shortDate: true
    },
    {
      viewValue: 'actual Delivery Date',
      value: 'actualDeliveryDate',
      shortDate: true
    },
  ];

  displayedColumns = [...this.headersMap.map(h => h.value), 'details'];

  constructor(private orderService: MyOrdersService,
    private router: Router,
    private dialog: MatDialog,
    private utilsService: UtilsService) { }

  ngOnInit() {
    this.orderService.getOrders().subscribe(resp => {
      this.allOrders = resp.map(o => ({
        ...o,
        orderDate: this.utilsService.utcDateToLocal(o.orderDate)
      }));

      this.filteredOrders = this.allOrders.filter(o => o.status.toLocaleLowerCase() === this.tabSelected.toLocaleLowerCase());

      this.loading.next(false);
    });

    this.displayedColumns.splice(3, 0, 'description');
  }

  changeTab(tab: string) {
    this.tabSelected = tab;

    this.filteredOrders = this.allOrders.filter(o => o.status.toLocaleLowerCase() === tab.toLocaleLowerCase());
  }

  goToOrder(order: ShortOrderInfo) {
    this.router.navigate([`order/${order.orderId}`]);
  }

  getValue(element: any, col: any): string {
    let value = _.get(element, col.value.split('.'));

    if (col.value === 'orderDate') {
      return new Date(value).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: false, minute: '2-digit' })
    } else if (col.shortDate && value && !isNaN(new Date(value).getTime())) {
      return new Date(value).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })
    } else {
      return _.get(element, col.value.split('.'));
    }
  }

  loadTable() {
    if (this.vinControl.invalid) {
      this.vinControl.markAsTouched();
      return;
    }

    this.loadingTable.next(true);

    this.orderService.getOrdersCarsFlatView(this.vinControl.value!).subscribe(cars => {
      this.dataSource.data = cars;

      this.vinControl.markAsPristine();

      this.loadingTable.next(false);
    });
  }

  changeView(view: string) {
    if (view === 'flatview' && this.dataSource.data.length === 0) {
      this.loadTable();
    }
  }

  openViewCarModal(carId: string) {
    this.dialog.open(
      ViewCarDetailsModalComponent, {
      width: '1000px',
      maxWidth: '90vw',
      autoFocus: false,
      data: {
        carId: carId
      }
    });
  }
}
