import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-two-way',
  templateUrl: './app-toggle-two-way.component.html',
  styleUrls: ['./app-toggle-two-way.component.scss']
})
export class AppToggleTwoWayComponent implements OnInit {
  @Input() label: string = "";
  @Input() disabled: boolean = false;
  @Input() componentId = 'id';

  @Input() toggled: boolean = false;
  @Output() toggledChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  changeValue() {
    this.toggled = !(this.toggled);
    this.toggledChange.emit(this.toggled);
  }
}
